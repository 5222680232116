import { isTestEnvironment } from '../config'
import { rollbarInstance } from './hooks/useRollbar'

export function trackError(e: unknown) {
  if (isTestEnvironment) {
    throw e
  } else if (e instanceof Error && rollbarInstance) {
    rollbarInstance.error(e.message, e)
  } else {
    console.error(e)
  }
}

export const defaultNever = (val: never) => {
  trackError(new Error(`Unreachable case: ${val}`))
}
