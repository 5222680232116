import React from 'react'
import classnames from 'classnames'
import { NavLink, Link } from 'react-router-dom'
import styles from './Nav.module.scss'
import { Pages } from '../../constants'

import {
  AccountHomeIcon,
  AccountDefaultPortIcon,
  AccountSubscriptionIcon,
  AccountUserProfileIcon,
  AccountPaymentDetailsIcon,
  AccountInvoicesIcon,
  AccountLogoutIcon,
  AccountMapIcon,
  AccountApiKeyIcon,
} from '../../UI/Icons/Account'
import { useTranslation } from '../../lib/i18n'
import { useAuth0 } from '@auth0/auth0-react'
import { clientLogout } from '../../Auth/client'
import { useAccountState } from '../AccountState'
import { pipe, constUndefined } from 'fp-ts/es6/function'
import { fold } from 'fp-ts/es6/Option'
import { isShowCase } from '../../config'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'

type NavProps = { hasPaidAccount: boolean }

export const Nav = ({ hasPaidAccount }: NavProps) => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const { isEnabled: isCreateApiEnabled } = useFeatureToggler(Feature.CREATE_API_KEY)

  const [{ isSideMenuOpen }] = useAccountState()

  return (
    <nav
      className={classnames(
        styles.nav,
        pipe(
          isSideMenuOpen,
          fold(constUndefined, isOpen => (isOpen ? styles.open : styles.closed))
        )
      )}
    >
      {isShowCase ? (
        <div className={styles.navPrimary}>
          <NavLink exact to={Pages.ACCOUNT} className={styles.navItem} activeClassName={styles.navItemActive}>
            <AccountUserProfileIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.ChangeProfileTitle')}
          </NavLink>
        </div>
      ) : (
        <div className={styles.navPrimary}>
          <NavLink exact to={Pages.ACCOUNT} className={styles.navItem} activeClassName={styles.navItemActive}>
            <AccountHomeIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.AccountOverviewTitle')}
          </NavLink>
          <NavLink to={Pages.ACCOUNT_DEFAULT_PORT} className={styles.navItem} activeClassName={styles.navItemActive}>
            <AccountDefaultPortIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.DestinationPortTitle')}
          </NavLink>
          <NavLink to={Pages.ACCOUNT_SUBSCRIPTION} className={styles.navItem} activeClassName={styles.navItemActive}>
            <AccountSubscriptionIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.SubscriptionTitle')}
          </NavLink>
          <NavLink to={Pages.ACCOUNT_PROFILE} className={styles.navItem} activeClassName={styles.navItemActive}>
            <AccountUserProfileIcon className={styles.navIcon} /> {t('ShipTracker.Account.Nav.ChangeProfileTitle')}
          </NavLink>
          <NavLink
            to={Pages.ACCOUNT_API_KEY}
            className={classnames(styles.navItem, { [styles.disabled]: !isCreateApiEnabled })}
            activeClassName={styles.navItemActive}
          >
            <AccountApiKeyIcon className={styles.navIcon} />{' '}
            <span className={styles.text}>{t('ShipTracker.Account.Nav.ApiKeyTitle')}</span>
          </NavLink>
          <NavLink
            to={Pages.ACCOUNT_PAYMENT_DETAILS}
            className={classnames(styles.navItem, { [styles.disabled]: !hasPaidAccount && !isCreateApiEnabled })}
            activeClassName={styles.navItemActive}
          >
            <AccountPaymentDetailsIcon className={styles.navIcon} />{' '}
            <span className={styles.text}>{t('ShipTracker.Account.Nav.PaymentDetailsTitle')}</span>
          </NavLink>
          <NavLink
            to={Pages.ACCOUNT_INVOICES}
            className={classnames(styles.navItem, { [styles.disabled]: !hasPaidAccount && !isCreateApiEnabled })}
            activeClassName={styles.navItemActive}
          >
            <AccountInvoicesIcon className={styles.navIcon} />{' '}
            <span className={styles.text}>{t('ShipTracker.Account.Nav.InvoicesTitle')}</span>
          </NavLink>
        </div>
      )}
      <div className={styles.navSecondary}>
        <Link to={Pages.MAIN} className={styles.navItem}>
          <AccountMapIcon className={styles.navIcon} />
          {t('ShipTracker.Account.Nav.ReturnToMap')}
        </Link>
        <button onClick={() => clientLogout(logout)} className={styles.navItem}>
          <AccountLogoutIcon className={styles.navIcon} />
          {t('ShipTracker.Account.Nav.Logout')}
        </button>
      </div>
    </nav>
  )
}
