import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

import styles from './ThemeScreen.module.scss'
import { Logo } from '../Logo'

export enum ThemeScreenColor {
  'purple',
  'blue',
  'green',
  'grey',
}

type ThemeScreenProps = PropsWithChildren<{ color?: ThemeScreenColor }>

const defaultNever = (val: never) => {
  console.error(`Unreachable case: ${val}`)
}

const getColorClassName = (color: ThemeScreenColor) => {
  switch (color) {
    case ThemeScreenColor.purple: {
      return styles.purple
    }
    case ThemeScreenColor.blue: {
      return styles.blue
    }
    case ThemeScreenColor.green: {
      return styles.green
    }
    case ThemeScreenColor.grey: {
      return styles.grey
    }
    default:
      defaultNever(color)
  }
}

export const ThemeScreen = ({ color = 0, children }: ThemeScreenProps) => {
  return (
    <div className={classnames([styles.themeScreen, getColorClassName(color)])}>
      <div className={styles.accountLogo}>
        <Logo className={styles.svg} />
      </div>
      {children}
    </div>
  )
}
