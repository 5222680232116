import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { AccountPageLayout } from './AccountPage'
import { Routes, ShowCaseRoutes } from './Routes'
import { Pages } from '../constants'
import { useFromFetch } from '../lib/useFromFetch'
import { fetchSubcriptionPlans } from '../Api/Subscription/Plan/fetchPlans'
import { useAccountState } from './AccountState'
import { isPaidSubscriptionPlan } from '../Domain/Subscription/Subscription'
import { store } from '../dataStore'

type LoadedAppProps = { hasHiddenNav?: boolean }

function LoadedApp({ hasHiddenNav }: LoadedAppProps) {
  const [{ currentSubscriptionPlan, hasDefaultPort }] = useAccountState()

  return (
    <AccountPageLayout
      hasHiddenNav={hasHiddenNav}
      hasDefaultPort={hasDefaultPort}
      hasPaidAccount={isPaidSubscriptionPlan(currentSubscriptionPlan)}
    >
      <Routes redirect={Pages.MAIN} />
    </AccountPageLayout>
  )
}

export function App() {
  const [, { initializeState }] = useAccountState()
  const { data, isLoading } = useFromFetch(() =>
    Promise.all([store.currentSubscription.fetch(), fetchSubcriptionPlans(), store.metadata.fetch()])
  )

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      const [subscription, plans, metadata] = data

      initializeState({ subscription, plans, metadata })
    }
  }, [isLoading, data, initializeState])

  if (isLoading || data === undefined) {
    return <AccountPageLayout hasPaidAccount={false} />
  }

  return (
    <Switch>
      <Route path={Pages.ACCOUNT_API_ONLY}>
        {() => {
          return <LoadedApp hasHiddenNav />
        }}
      </Route>
      <Route>
        <LoadedApp />
      </Route>
    </Switch>
  )
}

export const ShowCaseApp = () => (
  <AccountPageLayout hasDefaultPort hasPaidAccount={false}>
    <ShowCaseRoutes redirect={Pages.MAIN} />
  </AccountPageLayout>
)
