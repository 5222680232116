import classnames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tab } from '../../Account/AccountTypeTabs/AccountTypeTabs'
import { Pages } from '../../constants'
import { useTranslation } from '../../lib/i18n'
import { getClickEventName } from '../../lib/mixpanel/eventNames'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { CloseButton, CloseButtonTheme } from '../../UI/CloseButton'
import { ControlStripButton, ControlStripEntry } from '../../UI/ControlStrip/ControlStrip'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import { BottomLeftDropoutMenuDropper, DropoutMenuDropperTheme } from '../../UI/DropoutMenu/DropoutMenuDropper'
import MaterialIcon from '../../UI/MaterialIcon'
import styles from './Controls.module.scss'

const closeButtonTheme: CloseButtonTheme = { button: styles.closeButton, icon: styles.closeButtonIcon }

const menuTheme: DropoutMenuDropperTheme = {
  triangleBottom: styles.triangleBottom,
}

const ETASourceMenuContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <h3 className={styles.title}>{t('ShipTracker.Controls.ETASourceTitle1')}</h3>
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: t('ShipTracker.Controls.ETASourceText1') }} />
      <h3 className={styles.title}>{t('ShipTracker.Controls.ETASourceTitle2')}</h3>
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: t('ShipTracker.Controls.ETASourceText2') }} />
    </>
  )
}

type ETASourceMenuProps = { closeMenu: () => void }

const ETASourceSignupMenu = ({ closeMenu }: ETASourceMenuProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { track } = useMixpanel()

  return (
    <BottomLeftDropoutMenuDropper hasTriangleSpace theme={menuTheme} className={styles.menu}>
      <CloseButton onClose={closeMenu} theme={closeButtonTheme} />
      <ETASourceMenuContent />
      <button
        onClick={() => {
          track(getClickEventName("Map bar - Reported ETA's", 'Sign up'))
          history.push(Pages.CREATE_ACCOUNT)
        }}
        className={styles.signupButton}
      >
        {t('ShipTracker.Controls.ETASourceSignupCta')}
      </button>
      <p
        onClick={e => {
          e.preventDefault()
          track(getClickEventName("Map bar - Reported ETA's", 'Log in'))
          history.push(Pages.LOGIN)
        }}
        dangerouslySetInnerHTML={{ __html: t('ShipTracker.Controls.LoginAndUpgradeText') }}
        className={classnames(styles.text, styles.loginText)}
      />
    </BottomLeftDropoutMenuDropper>
  )
}

const ETASourceUpgradeMenu = ({ closeMenu }: ETASourceMenuProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <BottomLeftDropoutMenuDropper hasTriangleSpace theme={menuTheme} className={styles.menu}>
      <CloseButton onClose={closeMenu} theme={closeButtonTheme} />
      <h3 className={styles.title}>{t('ShipTracker.Controls.UpgradeETASourceTitle1')}</h3>
      <p className={styles.text}>{t('ShipTracker.Controls.UpgradeETASourceText1')}</p>
      <h3 className={styles.title}>{t('ShipTracker.Controls.UpgradeETASourceTitle2')}</h3>
      <p className={styles.text}>{t('ShipTracker.Controls.UpgradeETASourceText2')}</p>
      <button onClick={() => history.push(`${Pages.ACCOUNT_SUBSCRIPTION}/${Tab.WEB}`)} className={styles.signupButton}>
        {t('ShipTracker.Controls.UpgradeCta')}
      </button>
    </BottomLeftDropoutMenuDropper>
  )
}

type ETASourceControlProps = { children: (props: { isOpen: boolean; closeMenu: () => void }) => React.ReactNode }
const ETASourceControl = ({ children }: ETASourceControlProps) => {
  const { t } = useTranslation()
  const { track } = useMixpanel()

  return (
    <ControlStripEntry>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <ControlStripButton
              onClick={
                isOpen
                  ? closeMenu
                  : () => {
                      openMenu()
                      track(getClickEventName('Map bar', "Reported ETA's"))
                    }
              }
            >
              <MaterialIcon className={styles.icon} type="query_builder" />
              <span>{t('ShipTracker.Controls.ETASource')}</span>
              <span className={classnames(styles.label, styles.active)}>{t('ShipTracker.Controls.Active')}</span>
            </ControlStripButton>
            {children({ isOpen, closeMenu })}
          </>
        )}
      </DropoutMenu>
    </ControlStripEntry>
  )
}

export const ETASourceSignupControl = () => (
  <ETASourceControl>
    {({ isOpen, closeMenu }) => isOpen && <ETASourceSignupMenu closeMenu={closeMenu} />}
  </ETASourceControl>
)

export const ETASourceUpgradeControl = () => (
  <ETASourceControl>
    {({ isOpen, closeMenu }) => isOpen && <ETASourceUpgradeMenu closeMenu={closeMenu} />}
  </ETASourceControl>
)
