import React, { PropsWithChildren, useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { STRIPE_PUBLIC_KEY } from '../../config'
import { useFromFetch } from '../../lib/useFromFetch'

export function WithStripeElements({ children }: PropsWithChildren<{}>) {
  const stripePromise = useMemo(() => loadStripe(STRIPE_PUBLIC_KEY), [])
  const { data, isLoading, error } = useFromFetch(() => stripePromise)

  if (error) {
    console.error(error)
    // show error toast
  }

  if (!isLoading && data) {
    return <Elements stripe={stripePromise}>{children}</Elements>
  }

  return null
}
