import React, { useEffect } from 'react'
import './lib/i18n'
import { BrowserRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom'
import ShipTracker from './ShipTracker'
import { MINUTE, Pages, SHIPTRACKER_GLOBAL_REDIRECT_URL } from './constants'
import { useAuth0 } from '@auth0/auth0-react'
import { initializeClient } from './Auth/client'
import { App, ShowCaseApp } from './Account/App'
import { PrivateRoute } from './PrivateRoute'
import { useHandleAuthError } from './Auth/useHandleAuthError'
import { useHandleEmailVerificationSuccess } from './Auth/useHandleEmailVerificationSuccess'
import { useTracking } from './lib/hooks/useTracking'
import { LegalTermsPage } from './Generic/LegalTermsPage'
import { SupportPage } from './Generic/SupportPage'
import { AvailablePortsPage } from './Generic/AvailablePortsPage'
import { isShowCase } from './config'
import { LegacyRedirectSupport } from './LegacyRedirectSupport'
import { ApiShipTrackerPromoPage, GlobalShipTrackerPage } from './Account/PersuasiveScreen'
import { AboutShipTrackerPage } from './Generic/AboutShipTrackerPage'
import { HandleShowCaseSignup } from './Auth/HandleShowCaseSignup'
import { HandleOnboardingRedirect } from './Auth/HandleOnboardingRedirect'
import { DeveloperPortalApp } from './DeveloperPortal/DeveloperPortalApp'
import { CreateAccountOnboarding } from './Account/CreateAccount/CreateAccountOnboarding'
import { LoginPage } from './Account/LoginPage/LoginPage'
import { PublicOnlyRoute } from './PublicOnlyRoute'
import { ApiCreditsLP } from './LandingPage/ApiCreditsLP'
import { WebSubscriptionsLP } from './LandingPage/WebSubscriptionsLP'
import { useCustomStyleSheet } from './lib/hooks/useCustomStylesheet'

const NotFound = () => <Route>404: Page not found</Route>

const SHIPTRACKER_GLOBAL_PAGE_REDIRECT_DELAY = 0.25 * MINUTE
const ShowCasePromoPagesDelayedRedirects = () => {
  const history = useHistory()

  useEffect(() => {
    const timeoutRef = setTimeout(() => history.push(Pages.SHIP_TRACKER_GLOBAL), SHIPTRACKER_GLOBAL_PAGE_REDIRECT_DELAY)
    return () => clearTimeout(timeoutRef)
  }, [history])

  return null
}

export const RedirectToShipTrackerGlobal = ({ path }: { path: string }) => {
  useEffect(() => {
    window.location.assign(SHIPTRACKER_GLOBAL_REDIRECT_URL(path))
  }, [path])

  return null
}

const RoutesWithTracking = ({ user }: { user?: { email?: string } }) => {
  useHandleEmailVerificationSuccess()
  useTracking(user?.email)

  return (
    <Switch>
      <Route exact path={Pages.MAIN}>
        <HandleOnboardingRedirect>
          {isShowCase ? (
            <LegacyRedirectSupport>
              <Redirect to={Pages.MAP} />
            </LegacyRedirectSupport>
          ) : (
            <Redirect to={Pages.MAP} />
          )}
        </HandleOnboardingRedirect>
      </Route>
      <Route path={Pages.MAP}>
        <ShipTracker />
      </Route>
      {isShowCase && (
        <Route path={Pages.SHIP_TRACKER_GLOBAL}>
          <GlobalShipTrackerPage />
        </Route>
      )}
      {isShowCase && (
        <Route path={Pages.SHIP_TRACKER_API_PROMO}>
          <ApiShipTrackerPromoPage />
        </Route>
      )}
      {!isShowCase && (
        <Route exact path={Pages.SHOW_CASE_SIGNUP}>
          <HandleShowCaseSignup />
        </Route>
      )}
      <Route exact path={Pages.API_CREDITS_STORE}>
        {isShowCase ? <RedirectToShipTrackerGlobal path={Pages.API_CREDITS_STORE} /> : <ApiCreditsLP />}
      </Route>
      <Route exact path={Pages.AVAILABLE_PORTS}>
        <AvailablePortsPage />
      </Route>
      <Route exact path={Pages.SUPPORT}>
        <SupportPage />
      </Route>
      <Route exact path={Pages.ABOUT}>
        <AboutShipTrackerPage />
      </Route>
      <Route path={Pages.CREATE_ACCOUNT}>
        <CreateAccountOnboarding />
      </Route>
      <Route exact path={Pages.LEGAL_TERMS}>
        <LegalTermsPage />
      </Route>
      <Route exact path={Pages.WEB_SUBSCRIPTIONS}>
        {isShowCase ? <RedirectToShipTrackerGlobal path={Pages.WEB_SUBSCRIPTIONS} /> : <WebSubscriptionsLP />}
      </Route>
      <PrivateRoute path={Pages.ACCOUNT}>
        <Route path={Pages.ACCOUNT}>{isShowCase ? <ShowCaseApp /> : <App />}</Route>
      </PrivateRoute>
      <PublicOnlyRoute exact path={Pages.LOGIN}>
        <LoginPage />
      </PublicOnlyRoute>
      <Route path={Pages.DEV_PORTAL}>
        <DeveloperPortalApp />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export const ShipTrackerRouter = () => {
  const { isAuthenticated, getAccessTokenSilently, error, user } = useAuth0()

  useHandleAuthError(error)

  useCustomStyleSheet(isAuthenticated)

  useEffect(() => {
    initializeClient({ isAuthenticated, getTokenSilently: getAccessTokenSilently })
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <BrowserRouter>
      <RoutesWithTracking user={user} />
      {isShowCase && !isAuthenticated && <ShowCasePromoPagesDelayedRedirects />}
    </BrowserRouter>
  )
}
