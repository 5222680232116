import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'
import { useTranslation } from '../../lib/i18n'
import { CloseButton } from '../../UI/CloseButton'
import { RightDrawer } from '../../UI/Drawer'
import { Logo } from '../../UI/Logo'
import styles from './AccountDrawer.module.scss'
import { useHistory } from 'react-router'
import { Pages } from '../../constants'
import { IconApi } from '../../UI/Icons/IconApi'
import { IconWeb } from '../../UI/Icons/IconWeb'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { getClickEventName } from '../../lib/mixpanel/eventNames'

const closeButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

const AccountDrawerOverlay = () => <div className={styles.accountDrawerOverlay} />

type AccountDrawerProps = PropsWithChildren<{
  onClose: () => void
}>
export function AccountDrawer({ onClose }: AccountDrawerProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const { track } = useMixpanel()

  return (
    <>
      <AccountDrawerOverlay />
      <RightDrawer className={styles.accountDrawer}>
        <header className={styles.header}>
          <Logo className={styles.logo} />
          <CloseButton theme={closeButtonTheme} onClose={onClose} />
        </header>
        <div className={classnames(styles.section, styles.top)}>
          <h1 className={styles.title}>{t('ShipTracker.Account.Drawer.WebTitle')}</h1>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t('ShipTracker.Account.Drawer.WebDescription') }}
          />
          <button
            onClick={() => {
              track(getClickEventName('Account drawer', 'Start trial'))
              history.push(Pages.WEB_SUBSCRIPTIONS)
            }}
            className={classnames(styles.button, styles.web)}
          >
            <span className={styles.icon}>
              <IconWeb />
            </span>
            {t('ShipTracker.Account.Drawer.WebCtaButton')}
          </button>
          <div className={styles.triangleDown} />
        </div>
        <div className={classnames(styles.section, styles.bottom)}>
          <h2 className={styles.title}>{t('ShipTracker.Account.Drawer.ApiTitle')}</h2>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t('ShipTracker.Account.Drawer.ApiDescription') }}
          />
          <button
            onClick={() => {
              track(getClickEventName('Account drawer', 'Connect to API'))
              history.push(Pages.API_CREDITS_STORE)
            }}
            className={classnames(styles.button, styles.api)}
          >
            <span className={styles.icon}>
              <IconApi />
            </span>
            {t('ShipTracker.Account.Drawer.ApiCtaButton')}
          </button>
        </div>
      </RightDrawer>
    </>
  )
}
