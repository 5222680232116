import React from 'react'
import styles from './CreditsBundle.module.scss'
import { BACKEND_URL } from '../../config'
import { useTranslation } from '../../lib/i18n'
import { useMixpanelTrackExternalLink } from '../../lib/hooks/useTracking'

export function ApiDocumentationLink({ mixpanelPosition }: { mixpanelPosition: string }) {
  const { t } = useTranslation()
  const linkRef = useMixpanelTrackExternalLink('Click "API documentation"', { position: mixpanelPosition })

  return (
    <a
      ref={linkRef}
      className={styles.apiDocsLink}
      target="_blank"
      rel="noopener noreferrer"
      href={`${BACKEND_URL}/api/docs`}
    >
      {t('ShipTracker.Account.CreditBundle.APIDocumentation.Title')}
    </a>
  )
}
