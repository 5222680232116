import { SUPPORT_EMAIL_ADDRESS } from '../constants'

const localTranslations = {
  en: {
    ShipTracker: {
      Common: {
        Port: 'Port',
        ATA: 'ATA',
        ATA_PBP: 'ATA PBP',
        ATA_PORT: 'ATA Port',
        ATA_ANCHOR_AREA: 'ATA Anchor area',
        ETA: 'ETA',
        ETA_PBP: 'ETA PBP',
        ETA_BERTH: 'ETA BERTH',
        AIS: 'AIS',
        Loading: 'Loading…',
        Processing: 'Processing…',
        PleaseWait: 'Please wait...',
        Free: 'Free',
        Account: 'Account',
        Month: 'Month',
        NotAvailable: 'N/A',
        Canceled: 'Canceled',
        Signup: 'Sign up',
        Upgrade: 'Upgrade',
        Cancel: 'Cancel',
        Delete: 'Delete',
        Calculated: 'Calculated',
        Predicted: 'Predicted',
        Reported: 'Reported',
        Buy: 'Buy',
        Back: 'Back',
        ReceivingToken: 'Receiving token',
        Success: 'Success',
      },
      Menu: {
        ShowMap: 'Show map',
        DarkMode: 'Dark mode',
        Support: 'Support',
        AvailablePorts: 'Available ports',
        AboutShipTracker: 'About Shiptracker',
        ApiCreditsStore: 'Buy API Credits',
        WebSubscriptions: 'Buy Web Subscription',
        GlobalETAs: 'ETA predictions to other ports?',
        LegalTerms: 'Legal Terms',
        Search: {
          RefineResultsNotice:
            'Please be advised that there may be more vessels matching your query. Please refine your query further if you are interested in those vessels.',
          SearchByIdentifier: 'Search for ship name, IMO, ENI, MMSI or USCG',
          SearchByVesselIdentifier: 'Vessel name, IMO, ENI, MMSI or USCG',
        },
      },
      SupportPage: {
        Title: 'Support',
        Content: `If you need product support, please email us at <a href="mailto:${SUPPORT_EMAIL_ADDRESS}">${SUPPORT_EMAIL_ADDRESS}</a>.
        \nWe typically reply within one business day.
        \n\n<strong>Currently, Shiptracker only supports  <a href='https://www.google.com/chrome/'>Chrome</a> or <a href='https://www.mozilla.org/firefox/new/'>Firefox</a> browsers. Additionally, incognito mode may cause problems and is advised to be disabled.</strong>`,
      },

      WebSubscriptionsPage: {
        Title: 'Web subscription',
        FetchError: "Sorry, couldn't fetch plans at this time.",
      },

      AboutShipTrackerPage: {
        Title: 'About Shiptracker',
        Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tristique dictum arcu sed consequat. Nullam congue viverra est, quis viverra turpis porta quis.\n\nPraesent tempus lacinia ligula, convallis sodales nulla aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tellus sapien, ullamcorper in sapien sed, lacinia ornare dui. Aliquam id ipsum et velit lobortis luctus vitae a mauris. Vestibulum nec lobortis tellus. Nullam eget vulputate odio. Praesent id tristique ante. Praesent fermentum et sem at fringilla.`,
      },

      ApiCreditsPage: {
        SelectApiCreditBundle: 'Select your API credits bundle',
      },

      AvailablePortsPage: {
        Title: 'Available ports',
        CreateMyFreeAccount: 'Create my free account',
        UpgradeToPremium: 'Upgrade to Shiptracker Premium',
        GoToGlobalShiptracker: 'Go to Shiptracker Global',
        AvailablePortsDescription:
          'We currently can predict ETAs to the ports listed below.\nFor all other ports that are not on the list, we provide only reported ETAs.',
        ShowCaseAvailablePortsDescription:
          'The port of Rotterdam is the showcase port for Shiptracker with all functionalities unlocked. If you want to see vessel arrival times to other ports, go to the global Shiptracker website. Below is the list of ports that are currently available in Shiptracker Global. We add new ports on a weekly basis',
      },
      Controls: {
        OtherDestinations: 'Other destinations',
        AddDefaultPortAndTraffic: 'See expected arrivals to a specific port',
        Signup: 'Sign up',
        Upgrade: 'Upgrade',
        TrialActiveExpires: (days: string) => `trial active (expires in ${days} days)`,
        ActiveExpires: (days: string) => `active (expires in ${days} days)`,
        Active: 'active',
        ShowCaseSignupTitle: 'Notify on arrival?',
        ShowCaseSignupText:
          'Want to stay up to date on the arrival of your chosen ship? Sign up and receive free email notifications when the vessel arrives at the port.',
        LoginText: 'or <a href="#login">log in</a> if you have an account',
        LoginAndUpgradeText: 'or <a href="#login">log in</a> if you have an account and upgrade to Shiptracker Premium',
        GlobalShipTrackerTitle: 'Want to get ETA predictions to other ports?',
        GlobalShipTrackerText:
          'The port of Rotterdam is the showcase port for Shiptracker with all functionalities unlocked. If you want to see vessel arrival times to other ports, go to the global Shiptracker website.',
        GlobalShipTrackerCTA: 'Shiptracker Global',
        ETASource: 'Reported ETAs',
        ETASourceTitle1: 'What is the Reported ETA?',
        ETASourceText1:
          'A reported ETA is manually entered by the vessel’s crew. Usually, it’s provided on departure and updated periodically during the voyage. If the captain’s estimation is wrong, reported ETAs are unreliable.',
        ETASourceTitle2: 'What are ETA predictions?',
        ETASourceText2: `ETA predictions are powered by the Machine Learning model, which combines historic voyage information, vessel characteristics, current position, and weather. As such, they are more reliable.
        \n\n<b>Upgrade to ShipTracker Premium or create your free account to get a 14-day trial.</b>`,
        UpgradeETASourceTitle1: 'What are Reported ETAs?',
        UpgradeETASourceText1: 'Reported ETAs are provided and updated manually by the vessel’s crew.',
        UpgradeETASourceTitle2: 'Unlock ETA predictions',
        UpgradeETASourceText2:
          'ETA predictions are powered by Machine Learning algorithms and are based on historic voyage information, vessel characteristics, current position, weather. They are refreshed automatically in almost real-time.',
        ETASourceSignupCta: 'Start 14-day free trial',
        UnlockPortAndTrafficTitle: 'Unlock Port call vessel list view',
        UnlockPortAndTrafficText:
          'Get the list of all the vessels expected to arrive at the port of your choice along with ETA predictions.',
        AddDefaultPortAndTrafficTitle: 'Unlock Port call vessel list view',
        AddDefaultPortAndTrafficText:
          'Get the list of all the vessels expected to arrive at the port of your choice along with ETA predictions.',
        AddDefaultPortAndTrafficCta: 'Start 14-day free trial',
        ShiptrackerApiTitle: 'Estimated Time Arrival API',
        ShiptrackerApiText: `Do you want to get ETA predictions via API? We’ve got you covered! With our fully online API onboarding flow, you can generate the API key in just a few clicks. 
        \nThe best part is that you can test the API connection for just € 4,95.`,
        ShiptrackerApiCTA: 'Buy API credits now',
        UpgradeCta: 'Upgrade your subscription',
      },
      VesselListing: {
        FilterList: 'Filter List',
        OnlyShowManualFleet: 'Only show manual fleet',
        NotificationNotAvailable: 'Notification N/A',
        SendArrivalNotification: 'Send arrival notification',
        ApplyFilters: 'Apply',
        FilterFlagState: 'Filter Flag state',
        FlagState: 'Flag state',
        ChooseDestinationPort: 'Choose destination port',
        CountriesDropdown: {
          startTyping: 'Start typing to search for flag states',
          minimumLength: (length: string) => `Too few characters. Please type ${length} characters or more.`,
          loading: 'Loading…',
        },
        ShipTypeCategory: {
          UNSPECIFIED_SHIPS: 'Unspecified Ships',
          NAVIGATION_AIDS: 'Navigation Aids',
          FISHING: 'Fishing',
          TUG_AND_SPECIAL_CRAFT: 'Tug and Special Craft',
          HIGH_SPEED_CRAFT: 'High Speed Craft',
          PASSENGER_VESSELS: 'Passenger Vessels',
          CARGO_VESSELS: 'Cargo Vessels',
          TANKERS: 'Tankers',
          PLEASURE_CRAFT: 'Pleasure Craft',
        },
      },

      Drawer: {
        Navigation: {
          VesselTrace: 'VESSEL TRACE',
          EmmisionReport: 'EMMISION REPORT',
        },
        Actions: {
          AddVessel: 'Add vessel to list',
        },
      },
      Email: {
        placeholder: 'john@doe.com',
      },
      Account: {
        yourEmailAddress: 'Your email address',
        SelectDestinationPort: 'Select destination port',
        UseDataForMarketing: "I'd like to receive marketing updates",
        ChoosePort: 'Choose port',
        FindPort: 'Find port',
        MyAccount: 'My Account',
        Login: 'Log in',
        Logout: 'Log out',
        CreateAccount: 'Start 14-day trial',
        CreateAccountAsNotifyOnArrival: 'Notify on arrival?',
        SaveAndCreateAccount: 'Save & Create account',
        LoginTitle: 'Already a customer',
        AuthProblem: 'There seems to be an authorisation problem. Logging out and in again might fix the issue',
        Drawer: {
          WebTitle: 'Get ETA predictions via web subscription',
          WebDescription: `Looking for a vessel tracker and up-to-date ship arrival information? Look no further! Get access to ETA predictions and more via our web subscription.\n\n<b>What will you get via web subscription?</b><ul><li>ETA predictions</li><li>Port call vessel list view</li><li>Arrival notifications</li></ul>`,
          WebCtaButton: 'Start your 14-day trial',
          ApiTitle: 'Consume ETA predictions via API',
          ApiDescription: `Integrate our ETA predictions into your existing infrastructure via API, fast and easy.\n\n<b>What will you get via API?</b><ul><li>ETA predictions on a vessel level</li><li>ETA predictions on a port level</li></ul>`,
          ApiCtaButton: 'Get ETA predictions via API',
        },
        DefaultPort: {
          DestinationPortTitle: 'Destination port',
          ChangeDefaultPort: 'Save',
          DefaultPortDescription: (numberOfPorts: string) =>
            `Select the port you want to get the list of vessels expected to arrive there.\n\nCurrently, the list includes only ${numberOfPorts} ports. In the next few months, we will provide global coverage.`,
          ShowPortList: 'Show port list',
        },
        Invoices: {
          InvoicesTitle: 'Invoices',
          NotFound: 'No invoices found for this account',
          Download: 'Download',
          Header: {
            Date: 'Date',
            Reference: 'Reference',
            Amount: 'Amount',
          },
        },
        Nav: {
          AccountOverviewTitle: 'Account overview',
          DestinationPortTitle: 'Destination port',
          SubscriptionTitle: 'Subscription & API',
          ChangeProfileTitle: 'Change profile',
          ApiKeyTitle: 'API-key & Documentation',
          PaymentDetailsTitle: 'Payment details',
          InvoicesTitle: 'Invoices',
          ReturnToMap: 'Return to map',
          Logout: 'Logout',
        },
        Overview: {
          AccountOverviewTitle: 'Account overview',
          ApiCreditsOverviewTitle: 'Api credits overview',
          ApiCreditBundle: 'API credit bundle',
          ApiCreditAmountLeft: 'Current API credit amount left',
          ActiveSubscription: 'Active Subscription',
          NextPayment: 'Next payment',
          NextPaymentBefore: (total: string, date: string) => `${total} before ${date}`,
          NoPaymentScheduled: 'No payment scheduled',
          PaymentDetails: 'Payment Details',
          NoPaymentDetails: "Currently we don't have any payment details in our system",
          ChooseOtherSubscription: 'Choose other subscription',
          ChangePaymentDetails: 'Change payment details',
          CancelSubscription: 'Cancel Subscription',
          TrialPeriodExpires: (days: string) => `Trial period expires in ${days} days`,
          TrialAutoChange:
            'your subscription will automatically change to <strong>Free</strong> after the trial period ends',
          SubscriptionExpires: (days: string) => `Subscription expires in ${days} days`,
          SubscriptionAutoChange:
            'your subscription will automatically change to <strong>Free</strong> after the current period ends',
          AutoCreditRefill: 'Auto credit refill',
          AutoCreditRefillDisclaimer: '(it might take up to 1 hour to see your updated credit balance)',
          YourCreditCardWillBeChargedWith: (total: string, credits: string) =>
            `Your credit card will be charged with <b>${total} (${credits} credits)</b>\nwhen your current credit runs out. `,
        },
        PaymentDetails: {
          PaymentDetailsTitle: 'Payment details',
          DefaultCreditcard: 'Default creditcard',
          CVC: 'CVC',
          UpdateSuccess: {
            Header: 'Success',
            Message: 'Your card details have been updated',
          },
        },
        LoginPage: {
          Title: 'Please login with your account',
          Text: 'Please fill in your e-mail address and a password.',
        },
        Subscription: {
          CreateAccountButton: 'Create Account',
          SubscriptionTitle: 'Web Subscription',
          ApiSubscriptionTitle: 'API credit bundles',
          WebTab: 'Web subscriptions',
          ApiTab: 'API credit bundles',
          ConfirmBuyCreditsBundleTitle: (name: string) => `Buy ${name} credits bundle`,
          ConfirmBuyCreditsBundleText: (name: string, amount: string, cardNumber: string) =>
            `Are you sure you want to purchase the ${name} credits bundle?\n\nWe'll be withdrawing ${amount}\nfrom your credit card •••• •••• •••• ${cardNumber}.`,
          Currency: {
            Euro: (symbol: string) => `${symbol} (EUR)`,
            Dollar: (symbol: string) => `${symbol} (USD)`,
          },
          SubscriptionDescription: `Upgrade your account to unlock more functionalities.
          <br />
          Have any questions about our subscriptions or need any assistance? Then email us at <a href="mailto:${SUPPORT_EMAIL_ADDRESS}">${SUPPORT_EMAIL_ADDRESS}</a>`,
          SubscriptionDescriptionPublic: `Start your 14-day free trial to unlock more functionalities.
          <br />
          Have any questions about our subscriptions or need any assistance? Then email us at <a href="mailto:${SUPPORT_EMAIL_ADDRESS}">${SUPPORT_EMAIL_ADDRESS}</a>`,
          SelectCreditBundle: 'Choose your API credit bundle:',
          Active: 'Active',
          Subscribe: 'Subscribe',
          SwitchToFree: 'Switch to free',
          Monthly: 'Monthly',
          Annually: 'Annually',
          PayAnnuallyAndSave: 'Pay annually and save',
          DeleteMyAccount: 'Delete my account',
          DeleteAccountTitle: 'Delete account',
          DeleteAccountMessage:
            'Are you sure you want to delete your account?\n\nBy deleting your account, you won’t be able to receive email notifications when the vessel arrives in the Port of Rotterdam.',
          DeleteCanceledAccountMessage: (days: string) => `Are you sure you want to close your account?
You still have ${days} days until your paid subscription expires!`,
          DeleteTrialAccountMessage: (days: string) => `Are you sure you want to close your account?
You still have ${days} days until your trial subscription expires!`,
          ComingSoon: 'Coming soon',
          FreeTrialActive: 'Free trial active',
          ETAPer4Credits: 'ETA per vessel - <b>4 credits</b>\nGet latest ETA predictions for a single vessel',
          ETAPer40Credits:
            'ETAs per port - <b>40 credits</b>\nGet latest ETA predictions for all vessels going to the port of your choice',
          Plan: {
            Free: 'Free',
            Premium: 'Premium',
            ApiOnly: 'API Only',
          },
          Discount: (amount: string) => `Save ${amount}%`,
          RestrictedCurrency: {
            USD: 'Your prefered account currency is USD, so all your future payments will be in USD.',
            EUR: 'Your prefered account currency is Euro, so all your future payments will be in Euro.',
          },
          Faq: {
            Header: 'FAQ',
            Faq1: {
              Question: 'Do I need to enter my credit card info to sign up?',
              Answer:
                "No, you don't need to enter the credit card details to sign up to Shiptracker. You only need to provide your credit card details if you decide to upgrade to Shiptracker Premium after the end of your free trial.",
            },
            Faq2: {
              Question: 'How does the 14-day free trial work?',
              Answer:
                'A free trial provides you with a full version of a Premium plan for 14 days. Your trial starts automatically when you create your free account.',
            },
            Faq3: {
              Question: 'What happens after my free trial ends?',
              Answer:
                'After the 14-day trial, your account will automatically be downgraded to a free plan. If you chose to keep your paid plan, you would need to enter your payment details.',
            },
            Faq4: {
              Question: 'Can I upgrade or switch plans at any time?',
              Answer:
                'Yes, you can upgrade or downgrade at any time. Click on “Account Overview” in your Shiptracker account to change your subscription plan.',
            },
            Faq5: {
              Question: 'What payment methods do you accept?',
              Answer: 'We accept Visa, Mastercard, and American Express credit cards.',
            },
            Faq6: {
              Question: 'What is your cancellation policy?',
              Answer:
                'Cancel any time. You will be able to use Shiptracker until the end of your monthly or annual billing cycle.',
            },
            Faq7: {
              Question: 'Will my data be private and safe?',
              Answer:
                'All personal data is stored and processed by us and our service providers within the European Economic Area (EEA) and, more specific in data centers of Amazon Web Services located in Germany and Ireland. To learn more about how we process and store your data, please check our Privacy Policy.',
            },
            Faq8: {
              Question: 'More questions?',
              Answer: `If you have more question, please don't hesitate to reach out to our Support Team at <a href="mailto:${SUPPORT_EMAIL_ADDRESS}">${SUPPORT_EMAIL_ADDRESS}</a>`,
            },
          },
        },
        CreditBundle: {
          Price: (price: string) => `Price: <b>${price}</b> / credit`,
          ETAPerVessel: 'ETA per vessel*',
          ETAsPerPort: 'ETAs per port**',
          ATAPort: 'ATA Port',
          OnlineCustomerSupport: 'Online customer support',
          AutoTopUp: 'Auto. credit refill availble',
          Buy: 'Buy',
          CTA: {
            Title:
              'Connect to the Shiptracker API totally online and\nreceive <strong>calculated</strong> ETA predictions right into your system.',
            Subtitle: 'Generate your API key in just a few minutes!',
            EasyToConnectOnline:
              'You will be impressed with how easy and fast you can get access to the Shiptracker API.',
            ApiKeyReadyToUse: 'Voilà! You can generate your API key. Safe, fast, and totally online.',
            Features: {
              Choose: 'Choose API credit bundle',
              Complete: 'Complete your profile',
              Pay: 'Pay via credit card',
            },
          },
          APIDocumentation: {
            Title: 'API Documentation',
          },
          Faq: {
            Header: 'FAQ',
            Faq1: {
              Question: 'Which data fields are included in the response?',
              Answer: (link: string) =>
                `To see all the fields included in the response, please check the <a href="${link}" target="_blank">API documentation</a>.`,
            },
            Faq2: {
              Question: 'For which ports can I get ETA predictions?',
              Answer: (link: string, email: string) =>
                `We are working on making ETA predictions available globally, but currently, we can send you data only for ports listed <a href="${link}">here</a>. If your port is not on the list, please email us at <a href="mailto:${email}">support@port-xchange.com</a>, and we will keep you informed when your port becomes available.`,
            },
            Faq3: {
              Question: 'What is the refresh rate of your data?',
              Answer:
                'Our ETA predictions are calculated on every AIS signal update and refreshed every 5 minutes. While the update rate of AIS ranges between 15 minutes to 1 hour.',
            },
            Faq4: {
              Question: 'What sources of data do you make use of?',
              Answer: 'We currently use AIS and historical data in combination with our Machine learning algorithm.',
            },
            Faq5: {
              Question: 'How often can I call a server?',
              Answer:
                'You can call the service as often as needed. Keep in mind that 1 API call equals 4 credits on a vessel level and 40 credits on a port level.',
            },
            Faq6: {
              Question: 'When does it makes sense to request data on a port level vs vessel level?',
              Answer:
                'If you have a need to follow more than 10 vessels for a specific port, it’s more financially beneficial to request data on a port level. Otherwise, request data on the vessel level.',
            },
            Faq7: {
              Question: 'Can I test your API for free?',
              Answer:
                'If you want to test the API, we recommend you get 100 credits for as low as €4,95. When you are happy with the data you receive, you can easily upgrade to another credit bundle.',
            },
            Faq8: {
              Question: 'Is there developers’ support available?',
              Answer: (link: string, email: string) =>
                `We aimed to cover all your questions in our <a href="${link}" target="_blank">API documentation</a>, but of course, if you need any assistance, our team is available to help you. Email us at <a href="mailto:${email}">support@port-xchange.com</a> or call during office hours:\n\nMon – Fri: 8:00AM – 5:00PM CET\nNetherlands:+31 10 808 0634\nUnited States: +1 346-266-2896\n\nWe typically reply within one business day.`,
            },
            Faq9: {
              Question: 'How can I find out how many credits are left in my plan?',
              Answer: (link: string) =>
                `To check how many credits remain in your plan, go to <a href="${link}">My account</a>`,
            },
            Faq10: {
              Question: 'What happens if I run out of credits?',
              Answer: (link: string) =>
                `When credits run out, one of two things will happen depending on your settings:\n\n<ul><li>We will charge and replenish your account when you use over 75% of the credits from your last purchase. For example, if you purchased 100 credits, we will renew your account with 100 more credits once you have less than 25 credits remaining.</li><li>When you run out of credits, you won't be able to make API requests.\nIf you haven’t activated auto-renewal, we will send you a series of emails to inform you when you reach 75% of credits from your last purchased bundle. Once you reach zero credits, we will stop sending any information through the API. To avoid disruption, we highly recommend activating the auto-renewal.</li></ul>`,
            },
          },
        },
        SubscriptionPlan: {
          FreeTrialForDays: '14 days free trial',
          AISBased: 'AIS BASED',
          ComingSoon: 'Coming soon',
          Expiry: {
            Trial: (days: string) => `Trial expires in ${days} days`,
            Canceled: 'Canceled',
            SubscriptionDaysLeft: (days: string) => `<strong>${days} days</strong> left to resume subscription`,
          },
          NoLastBoughtBundleFound: 'No API bundle yet',
          ApiOnlyDescription:
            'Make API calls to get the latest ETA predictions and ATA timestamps for the specific vessel or all the vessels going to your port of choice.',
          Free: {
            SatelliteData: 'Satellite AIS Data',
            TerrestrialData: 'Terrestrial AIS Data',
            VesselInformation: 'Vessel information & photos',
            ETAReported: 'ETA Port reported',
          },
          Paid: {
            SatelliteData: 'Satellite AIS Data',
            TerrestrialData: 'Terrestrial AIS Data',
            VesselInformation: 'Vessel information & photos',
            ExtensiveVesselInformation: 'Extensive vessel information',
            OneLicense: '1 license',
            ETAPredictions: 'ETA Port predictions',
            ETAReported: 'ETA Port reported',
            EmailNotification: 'E-mail notification ATA Port',
            ListView: 'Portcall vessel list view',
            EmmissionReport: 'Emission report',
            CreateFleet: 'Create your own fleet',
            CustomerSupport: 'Online support',
            VesselsApi: 'Add vessel list via API',
            EmbededMap: 'Embedded map module',
          },
          Credits: 'credits',
        },
        UpdateSubscription: {
          UpdateSubscriptionTitle: 'Change Subscription',
          IWantToChangeTo: 'I want to change my subcription to',
          ChooseOther: 'Choose other subscription',
          RetrievingPaymentDetails: 'Retrieving payment details',
          CreatingSubscription: 'Creating subscription',
          AddingApiPlan: 'Adding selected API plan',
          UpdatingSystem: 'Updating system',
        },
        UserProfile: {
          UserProfileTitle: 'Change profile',
          Email: 'E-mail',
          Password: 'Password',
          FirstName: 'First name',
          LastName: 'Last name',
          PrivateUser: 'Private user',
          BusinessUser: 'Business user',
          CompanyName: 'Company name',
          SaveChanges: 'Save changes',
          CompanyType: 'Company type',
          CompanyTypePlaceholder: 'My type of company',
        },
        Signup: {
          title: 'Are you a new customer?',
          createAccount: 'Create account',
        },
        CompleteAccount: {
          Steps: {
            AUTHENTICATION: 'Create account',
            EMAIL_VERIFICATION: 'Verify email',
            LEGAL_TERMS: 'Accept legal terms',
            PROFILE: 'Complete profile',
          },
          Profile: {
            Title: 'You are almost done! So what brought you to Shiptracker? Tell us more about yourself. ',
            Description: 'Please complete your user profile to continue',
          },
          MenuTitle: 'Set up your account',
          Authentication: {
            Title: 'Hey, good to see you! So you want to create your Shiptracker account? Great idea! Let’s begin.',
          },
        },
        CompleteProfile: {
          Continue: 'Continue',
          companyName: 'Company name',
          companyNamePlaceholder: 'My Company',
          title: 'Welcome to your Shiptracker account',
          subTitle: "You're almost done…",
          menuTitle: 'Shiptracker account created successfully!',
          submitProfile: 'Start using shiptracker',
          accountTypeUsage: 'I want to use Shiptracker as a',
          defaultPortDescription: (numberOfPorts: string) =>
            `Please select the destination port, and we will create the list of vessels arriving there. You can change it anytime.\n\nCurrently, the list includes only ${numberOfPorts}  ports. In the next few months, we will provide global coverage.`,
        },
        LegalTerms: {
          Title: 'Accept legal terms & conditions',
          AcceptTermsAndConditions: 'I accept the terms and conditions',
          MustAcceptTerms: 'Please accept our legal terms and conditions',
          Continue: 'Continue',
          ContinueToMap: 'Continue to map',
        },

        PortDropdown: {
          startTyping: 'Start typing to search for ports',
          minimumLength: (length: string) => `Too few characters. Please type ${length} characters or more.`,
          loading: 'Loading…',
        },

        CreditCardForm: {
          NoCreditCardFound: 'No credit card found for this account',
          YourDefaultCreditCard: 'Your default credit card',
          ExpirationDate: 'Expiration date',
          CVC: 'CVC',
          WhatIsCVC: 'What is CVC',
          EncryptionInfo: 'Your credit card information is encrypted',
          Submit: 'Save changes',
          CvcModal: {
            Title: 'What is CVC code and where can I find it on my card?',
            Text:
              'The CVC code (Card Verification Code) is located on the back of your credit/debit card on the right side of the white signature strip; it is always the last 3 digits in case of VISA and MasterCard.',
          },
        },
        VesselLegend: {
          Portcall: {
            Title: 'Port related vessels',
            Description: 'Green vessels are related to your port of choice.',
          },

          Traffic: {
            Title: 'Non-port related vessels',
            Description: 'Orange vessels are not related to your port of choice.',
          },
        },
        SignupPage: {
          SignupPageTitle: 'Create your free account',
          FreeTrialPeriod: '14 day free trial',
          CreateFreeAccount: `Create your Free account and experience the benefits of Shiptracker Premium free of charge for 14 days.\n\n
          At the end of your 14-day trial period, your account automatically will be downgraded to a Free subscription.\n\n
          <b>No credit card is required.</b>`,
          CreateFreeShowCaseAccount: `Receive ATA notifications

Create your free account to receive free email notifications when the vessel arrives at the Port of Rotterdam.`,
          Signup: 'Yes! I want to create a FREE account',
          ShowCaseSignup: 'Yes, I want to receive ATA notifications',
        },
        ApiKey: {
          ApiKeyTitle: 'API-key & Documentation',
          ApiKeyDescription: 'Here you will find your generated API-key.',
          Modal: {
            RegenerateTitle: 'Do you realy want to regenerate your API-key?',
            RegenerateText: 'By regenerating your API-key, your current key will expire.',
            RegenerateCta: 'Regenerate',
          },
        },
        PersuasiveScreen: {
          CreateAccount1: {
            Title: 'Did you know...',
            SubTitle: 'that we can create a list with all vessels visiting your port?',
            Description:
              'Suspendisse id vehicula dolor. Phasellus vel est interdum, mollis neque nec, dapibus neque. Nunc at sem',
            LegendTitle: 'What do I see on the map with a FREE account?',
            SignUp: 'Yes! I want to create a FREE account',
            SignUpQuick: 'Create a free account within minutes!',
          },
          CreateAccount2: {
            Title: 'Did you know...',
            SubTitle: 'that we can send you a notification e-mail when a vessel arrived at your port?',
            Description:
              'The only thing you need is a FREE Shiptracker account. And you can choose every vessel that is scheduled to visit your port to receive a notification e-mail of its Actual Time Arrival (ATA)',
            SignUp: 'Yes! I want to create a FREE account',
            SignUpQuick: 'Create a free account within minutes!',
          },
          GlobalShipTracker: {
            Title: 'Want to get calculated ETA predictions to other ports?',
            Description:
              'The port of Rotterdam is the showcase port for Shiptracker with all functionalities unlocked. If you want to see vessel arrival times to other ports, go to the global Shiptracker website.',
            CTA: 'Go to the Global Shiptracker',
          },
          ApiPromo: {
            Title: 'Shiptracker API',
            Description: `Do you want to consume ETA predictions via API? We've got you covered!
              With our fully online API onboarding flow, you can generate the API key in just a few clicks.\n
              <strong>The best part is that you can test the API connection for just € 4,95</strong>`,
            CTA: 'Buy API credits now!',
          },
        },
        ApiOnly: {
          AccountApiOnlyLegalTitle: 'Accept legal terms & conditions',
          AccountApiOnlyPaymentTitle: 'API Subscription payment',
        },
      },
      CommonStep: {
        Authentication: {
          Title: 'Hi, so you want to connect to Shiptracker API? Smart move! Let’s begin with setting up your account.',
          SignInTitle: 'Good that you already have an account!\nThen we can skip a few steps.',
          ResetPasswordTitle: 'Forgot your password? No problem, we’ve got you covered',
          Description: 'Please fill in your e-mail adress and a password, so we can create your account',
          SignInDescription: 'Now just log in.',
          ResetPasswordDescription: 'Enter your email address, and we’ll send you a link to reset your password.',
          AlreadyHaveAnAccount: 'Already have a account?',
          LogIn: 'Log in',
          ResetPasswordConfirmation:
            'The link to change your password has been sent to your email. After the change return here to log in.',
          SwitchState: {
            AlreadyHaveAnAccount: {
              Question: 'Already have a account?',
              Link: 'Log in',
            },
            DontHaveAnAccount: {
              Question: `Don't have an account?`,
              Link: `Sign up`,
            },
            ForgotPassword: {
              Question: `Forgot password?`,
              Link: `Reset password`,
            },
            RememberThePassword: {
              Question: `Remember the password?`,
              Link: `Log in`,
            },
          },
          UnsupportedBrowserWarning:
            "The browser you're currently using unfortunately is not supported by us. Please try using <a href='https://www.google.com/chrome/'>Chrome</a> or <a href='https://www.mozilla.org/firefox/new/'>Firefox</a>.",
        },

        LegalTerms: {
          Title: 'Legal terms & conditions',
          Description:
            'We know it’s a lot of text, but to make our legal team happy, spend a few minutes reading our Terms and Conditions before clicking “accept”.',
        },

        VerifyEmail: {
          AccountCreated: {
            Title: 'Good start! Your account has been created.\nWe just need to verify your email address.',
            Description: 'Good start! Your account has been created.\nWe just need to verify your email address.',
            Warning: 'Go to your inbox and click on the “verify account” link.',
            SpamNote:
              'Sometimes by mistake, our email ends up in the spam folder. So check it there as well, and add our email address to your contacts not to miss important updates from us ever again.',
          },
          EmailVerified: {
            Title: 'Your e-mail adress verification was successful\nYou are doing great! Keep it up!',
            Description: 'To continue, please login with your new account',
          },
        },
      },
      Map: {
        Marker: {
          DestinationPort: 'Dest. Port',
          NextBerth: 'Next Berth',
          TerminalOrBerth: 'Terminal/Berth',
          ETAPBPReported: 'ETA PBP (Reported)',
          ETAPortReported: 'ETA Port (Reported)',
          ETAPBPCalculated: 'ETA PBP (Calculated)',
          ETAPortPredicted: 'ETA Port (Predicted)',
          ETAPBP: 'ETAPBP',
          NEXT_BERTH: 'Next Berth',
          NEXT_PORT: 'Next Port',
          ETA_BERTH: 'ETA Berth',
          ETA_PORT: 'ETA Port',
          NoDetails: 'No details',
          NotAvailable: 'Not available',
          Speed: 'Speed',
          Type: 'Vessel Type',
          Subtype: 'Subtype',
          Unknown: 'Unknown',
          CallSign: 'Call sign',
          Draught: 'Draught',
          FlagState: 'Flag state',
          BuiltYear: 'Built Year',
        },
        Legend: {
          PortcallVessel: (port: string) => `To ${/^port.+/i.test(port) ? 'the ' : ''}${port}`,
          OtherDestinations: 'Other destinations',
        },
        VesselDetails: {
          Details: 'Details',
          PastTrackAndRouteForecast: 'Past Track & Route Forecast',
        },
      },
      TrafficFilter: {
        Panel: {
          ApplyFilter: 'Apply Filter',
          ShowPortcallsLabel: 'Show Portcalls',
          ShowTrafficLabel: 'Show Traffic',
          PortOfRotterdam: 'PORT OF ROTTERDAM',
          Explanation: `For this Port we can filter out all\ntraffic so you will remain with only\nthe relevant Portcalls to this Port.\n\nPlease make a selection below:`,
        },
      },

      DeveloperPortal: {
        ConnectToApi: {
          Steps: {
            AUTHENTICATION: 'Create account',
            EMAIL_VERIFICATION: 'Verify email',
            LEGAL_TERMS: 'Accept legal terms',
            PROFILE: 'Complete profile',
            PLAN_SELECTION: 'Select credits bundle',
            PAYMENT_DETAILS: 'Provide payment details',
            GENERATE_API: 'Generate API key',
          },
          Profile: {
            Title: 'It’s time to get to know you better.\nTell us who you are and what you do.',
            Description: 'Please complete your user profile to continue',
            SelectCreditsBundle: 'Select your API credits bundle',
          },
          PaymentDetails: {
            Title: 'You are just one step away from generating the Shiptracker API key. Are you excited?',
            Description:
              'We just need to sort out some formalities. Fill in your payment details. We promise your data is safe with us',
            SelectedBundle: 'Selected bundle',
          },
          PlanSelection: {
            Title: (name: string) => `Nice to meet you ${name}!`,
            Description:
              'Now you can select your API credit bundle. You can go all-in and buy 5000 credits, or if you want to take it easy, start with 100 or 1000 credits.',
            SelectBundle: 'Select bundle',
            GoToPayment: 'Go to payment options',
          },

          GenerateApiKey: {
            Title:
              'We told that it’s incredibly fast. Now you can finally get your API key and send ETA predictions right into your own system.',
            Description:
              'Your are all setup to generate your API-key, you can re-generate this API-key in your My account section under “API Key” when needed Generate API key',
            ApiKeyDescription: 'Nice work!\n\nNow you can really get started!',
            GenerateKey: 'Generate API-key',
          },
        },
      },

      Toast: {
        NotSignedIn: {
          Header: 'Not signed in',
          Message: 'you need to be signed in to view this page',
        },
        UpdateCreditCardError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to update your card details, please try again or contact support',
        },
        DisabledSubscription: {
          Message: `ATA Notification not possible, because at this time we don't know/support the destination of the vessel`,
        },
        CanceledSubscription: {
          Header: 'Success',
          Message: 'Your subscription has been canceled!',
        },

        CancelSubscriptionError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to cancel your subscription, please try again or contact support',
        },

        FindLocation: {
          Header: 'Location',
          Message: 'Finding your location',
        },

        LocationTimeout: {
          Header: 'Timeout',
          Message: 'We failed to determine you location',
        },
        CreateSubscriptionError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to create a subscription, please try again or contact support',
        },

        SubscriptionTokenError: {
          Header: 'Oops, something went wrong',
          Message: (time: string) =>
            `We failed to update the system, the browser will automatically refresh in ${time}`,
        },

        CreatedSubscription: {
          Header: 'Success',
          Message: 'Your subscription has been created!',
        },
        EmailVerificationError: {
          Header: 'Email verification',
          Message: (email: string) =>
            `A verification mail has been mailed to: ${email}. Please verify your email before logging in.`,
        },
        EmailVerified: {
          Header: 'Success',
          Message: 'Your email was verified. You can continue using the application.',
        },
        EmailAlreadyVerified: {
          Header: 'This email is already verified',
          Message: `The verification link has already been used. You can now log in. If you think it's a mistake please don't hesistate to contact us: ${SUPPORT_EMAIL_ADDRESS}`,
        },
        SubscriptionAdded: {
          Message: 'You will receive email notification when this vessel arrives',
        },
        FavoritesAdded: {
          Message: 'This vessel has been added to your favorite list',
        },
        VesselNotFound: {
          Header: (mmsi: string) => `Vessel (${mmsi}) not found`,
          Message: `The location of the vessel you've searched for can't be determined`,
        },
        VesselHidden: {
          Header: (mmsi: string) => `Vessel (${mmsi}) is hidden`,
          Message: `The Vessel you've searched for is hidden because of your filter settings, please adjust your filters`,
        },

        UpdatedCredits: {
          Header: `Successs`,
          Message: `Your payment is processed, your total credits will be updated withing an hour`,
        },

        UpdatedCreditsError: {
          Header: 'Oops, something went wrong',
          Message: () => `We failed to process your request, please try again or contact support`,
        },

        PaymentSucceeded: {
          Header: `Great news!`,
          Message: `Your payment was successful`,
        },

        PayementError: {
          Header: 'Oops, something went wrong',
          Message: 'Your payment failed, please try again or contact support',
        },
      },
      Modal: {
        Default: {
          Confirmation: {
            Cancel: 'Cancel',
            Confirm: 'Confirm',
          },
        },
        DeleteHandPicked: {
          Title: 'Remove vessel from the list?',
          Text: 'By removing this vessel from the list, you’ll no longer be able to use it in the filters.',
          Confirm: 'Remove',
        },
        Signup: {
          Title: 'Notify on arrival?',
          Text:
            'Want to stay up to date on the arrival of your chosen ship? Sign up and receive free email notifications when the vessel arrives at the port.',
          Confirm: 'Sign up',
        },
        RedirectToApiFlow: {
          Title: 'Great, you have an account already!',
          Text:
            'Before you can generate the API key, we will need to get some additional information from you. Since you already have an account, you will land in step 3 of the API onboarding flow.',
          Confirm: 'Continue',
        },
      },
    },
  },
}

type Template = (...vars: string[]) => string
type Translations = { [key: string]: Translations | string | Template }

function translate(translations: Translations, path: string): string | Template {
  let entry: Translations | string | Template = translations

  for (let p of path.split('.')) {
    if (typeof entry !== 'string' && typeof entry !== 'function' && p in entry) {
      entry = entry[p]
    } else {
      break
    }
  }

  return typeof entry === 'string' || typeof entry === 'function' ? entry : path
}

export const useTranslation = () => ({
  t: (entry: string, ...args: (string | number)[]) => {
    if (process.env.NODE_ENV === 'test') {
      return entry
    }
    const res = translate(localTranslations.en, entry)

    if (typeof res === 'function') {
      return res.apply(
        null,
        args.map(a => a.toString())
      )
    }

    return res
  },
})
