import { BACKEND_URL } from '../../config'
import { authRequest } from '../auth'
import { Subscription } from '../../Domain/Subscription/Subscription'
import { some, none, Option } from 'fp-ts/es6/Option'
import { handle404 } from '../../lib/utils'

export const fetchCurrentSubscription = () =>
  authRequest
    .get(`${BACKEND_URL}/api/subscriptions/current`)
    .json<Subscription>()
    .then(some)
    .catch(handle404<Option<Subscription>>(none))
