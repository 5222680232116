import React from 'react'
import classnames from 'classnames'
import { GetInputPropsOptions } from 'downshift'
import { useTranslation } from '../../lib/i18n'
import { Unlocode, Port } from '../../Domain/Port'
import { InlineLoader } from '../../UI/Loader/InlineLoader'
import { useFromFetch } from '../../lib/useFromFetch'
import { AccountForm, AccountFormLabel } from '../AccountPage'
import { Input, Menu, Item } from '../../UI/Select'
import styles from './PortsDropdown.module.scss'
import { store } from '../../dataStore'
import { SelectPort } from '../../UI/Select/SelectPort'

const MINIMUM_TERM_LENGTH = 3

type PortsDropdownProps = {
  port?: string
  handleSelect: (port: Unlocode | null) => void
  hasErrors: boolean
  className?: string
}

type PortsDropdownContentProps = PortsDropdownProps & {
  ports: Port[]
  selectedItem?: Port
}

function PortsDropdownContent({ className, ports, port, handleSelect, hasErrors }: PortsDropdownContentProps) {
  const { t } = useTranslation()

  return (
    <SelectPort port={port} ports={ports} handleSelect={handleSelect} minimumQueryLength={MINIMUM_TERM_LENGTH}>
      {({
        isOpen,
        openMenu,
        closeMenu,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        selectedItem,
        getItemProps,
        onQueryChange,
        searchState,
      }) => (
        <div className={classnames(className, styles.selectPort)}>
          <AccountForm>
            <label {...getLabelProps()}>
              <AccountFormLabel>{t('ShipTracker.Account.SelectDestinationPort')}</AccountFormLabel>
              <Input
                className={styles.input}
                icon={selectedItem ? 'arrow' : 'search'}
                hasError={hasErrors}
                getProps={() =>
                  getInputProps<GetInputPropsOptions>({
                    placeholder: selectedItem ? t('ShipTracker.Account.ChoosePort') : t('ShipTracker.Account.FindPort'),
                    onInput: e => onQueryChange(e.currentTarget.value),
                    onClick: () => {
                      isOpen ? closeMenu() : openMenu()
                    },
                  })
                }
              />
            </label>
            <Menu isOpen={isOpen} getProps={getMenuProps}>
              {isOpen && searchState.kind === 'INITIAL_SEARCH' && (
                <Item>{t('ShipTracker.Account.PortDropdown.startTyping')}</Item>
              )}
              {isOpen && searchState.kind === 'COMPOSING_QUERY' && (
                <Item>{t('ShipTracker.Account.PortDropdown.minimumLength', MINIMUM_TERM_LENGTH)}</Item>
              )}
              {isOpen && searchState.kind === 'LOADING' && (
                <Item>
                  <InlineLoader /> {t('ShipTracker.Account.PortDropdown.loading')}
                </Item>
              )}
              {isOpen &&
                searchState.kind === 'LOADED' &&
                searchState.results.map(({ item }, index) => (
                  <Item
                    key={item.port}
                    isHighlighted={highlightedIndex === index || selectedItem === item}
                    getProps={() => getItemProps({ item, index })}
                  >
                    {item.name}, {item.port.substr(0, 2)}
                    <span className={styles.unlocode}>UNLOCODE {item.port}</span>
                  </Item>
                ))}
            </Menu>
          </AccountForm>
        </div>
      )}
    </SelectPort>
  )
}

export function PortsDropdown(props: PortsDropdownProps) {
  const { t } = useTranslation()
  const { isLoading, data, error } = useFromFetch(store.ports.fetch)

  if (!isLoading && error === undefined && data) {
    return <PortsDropdownContent {...props} ports={data} />
  }

  return (
    <div className={props.className}>
      <AccountForm>
        <label>
          <AccountFormLabel>{t('ShipTracker.Account.SelectDestinationPort')}</AccountFormLabel>
          <Input
            icon={props.port ? 'arrow' : 'search'}
            hasError={props.hasErrors}
            getProps={() => ({ placeholder: 'loading', readOnly: true })}
          />
        </label>
      </AccountForm>
    </div>
  )
}
