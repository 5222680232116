import { parseVesselDetails, VesselMasterDataJson } from './../../Domain/Vessel'
import { BACKEND_URL } from '../../config'
import { MMSI, VesselMasterDataWithPictureUrl } from '../../Domain/Vessel'
import { none, some, Option } from 'fp-ts/es6/Option'
import ky, { ResponsePromise } from 'ky'
import { UUID } from '../../Domain/UUID'
import { handle404 } from '../../lib/utils'
import { authRequest } from '../auth'

const PRELOAD_TIMEOUT = 3000

const loadPictureUrl = (src: string): Promise<Option<string>> =>
  new Promise(resolve => {
    let img: HTMLImageElement | null = new Image()

    const cleanup = () => {
      img = null
    }

    const fail = () => {
      resolve(none)
      cleanup()
    }

    img.onload = () => {
      resolve(some(src))
      cleanup()
    }

    img.onerror = fail
    setTimeout(fail, PRELOAD_TIMEOUT)

    img.src = src
  })

const fetchDetails = (id: string, request: () => ResponsePromise): Promise<VesselMasterDataWithPictureUrl> =>
  request()
    .json<VesselMasterDataJson>()
    .then(json => {
      const details = parseVesselDetails(json)
      if (json.photoUrl === undefined) {
        return { ...details, pictureUrl: none }
      }
      return loadPictureUrl(json.photoUrl).then(pictureUrl => ({ ...details, pictureUrl }))
    })
    .catch(
      handle404({
        id: id as UUID,
        mmsi: id.replace('MMSI', '') as MMSI,
        aisPositionAllowed: true,
        outOfService: false,
        invalid: false,
        pictureUrl: none,
      })
    )

export const fetchVesselDetails = (id: string) =>
  fetchDetails(id, () => authRequest.get(`${BACKEND_URL}/api/vessels/${id}`))

export const fetchPublicVesselDetails = (id: string) =>
  fetchDetails(id, () => ky.get(`${BACKEND_URL}/api/vessels/${id}/public`))
