import React, { Fragment } from 'react'
import classnames from 'classnames'
import {
  SHIP_TRACKER_THEME_CSS,
  SHIP_TRACKER_THEME_DARK_CSS,
  Pages,
  SHIPTRACKER_GLOBAL_REDIRECT_URL,
} from '../constants'
import Header from '../Header/Header'
import { useDarkMode } from '../lib/hooks/useDarkMode'
import styles from './GenericPage.module.scss'
import { useTranslation } from '../lib/i18n'
import { useFromFetch } from '../lib/useFromFetch'
import { Port, Unlocode } from '../Domain/Port'
import { SubmitButton } from '../Account/AccountPage'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useFeatureToggler, Feature } from '../lib/hooks/useFeatureToggler'
import { pipe } from 'fp-ts/es6/function'
import { findFirstMap } from 'fp-ts/es6/Array'
import { countries } from '../Domain/Countries'
import { getOrElse, some, none } from 'fp-ts/es6/Option'
import { store } from '../dataStore'
import { isShowCase } from '../config'
import { useMixpanel } from '../lib/mixpanel/MixpanelContext'
import { getClickEventName } from '../lib/mixpanel/eventNames'
import { Tab } from '../Account/AccountTypeTabs/AccountTypeTabs'
import classNames from 'classnames'

type PortsIndexProps = { ports: Port[] }

function PortsIndex({ ports }: PortsIndexProps) {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const groupedPorts = ports.reduce((acc: { [key: string]: Port[] }, port: Port) => {
    const index = port.port.substr(0, 2)

    return { ...acc, [index]: [...(index in acc ? acc[index] : []), port] }
  }, {})

  const unlocodes = Object.keys(groupedPorts)
    .map(key => ({
      key,
      name: pipe(
        countries,
        findFirstMap(({ alpha2, name }) => (key.toLowerCase() === alpha2 ? some(name) : none)),
        getOrElse(() => key)
      ),
    }))
    .sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))

  const handleSelectPort = async (port: Unlocode) => {
    await store.metadata.update({ defaultPort: port })
    history.push(Pages.MAIN)
  }

  return (
    <>
      {unlocodes.map(({ key, name }) => (
        <div className={styles.section} key={key}>
          <div className={styles.country}>
            <img className={styles.flag} src={`/assets/flags/24x24/${key.toLowerCase()}.png`} alt={key} />
            <span className={styles.name}>{name}</span>
          </div>
          {groupedPorts[key].map(({ name, port }) => (
            <Fragment key={port}>
              <button
                type="button"
                className={classnames(styles.port, { [styles.portDisabled]: !isAuthenticated })}
                onClick={() => handleSelectPort(port)}
              >
                {name}
              </button>
              <br />
            </Fragment>
          ))}
        </div>
      ))}
    </>
  )
}

export const AvailablePortsPage: React.FC = () => {
  const { track } = useMixpanel()
  const { isAuthenticated } = useAuth0()
  const { isEnabled: isFeatureEtaCalculated, isFeatureLoading } = useFeatureToggler(Feature.PORTCALLS)

  const { isDarkMode } = useDarkMode()
  const { t } = useTranslation()

  const { isLoading, data } = useFromFetch(store.ports.fetch)

  const history = useHistory()

  return (
    <main
      className={classnames(styles.genericPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <Header />
      <OverlayScrollbarsComponent className={styles.scrollable}>
        <div className={classNames(styles.content, styles.formatTags)}>
          <h2>
            {t('ShipTracker.AvailablePortsPage.Title')} {data !== undefined ? `(${data.length})` : ''}
          </h2>
          {isShowCase ? (
            <div className={styles.createAccount}>
              <p>{t('ShipTracker.AvailablePortsPage.ShowCaseAvailablePortsDescription')}</p>
              <div className={styles.cta}>
                <SubmitButton
                  handleSubmit={() => {
                    track('ShipTracker Global')
                    window.location.assign(SHIPTRACKER_GLOBAL_REDIRECT_URL())
                  }}
                >
                  {t('ShipTracker.AvailablePortsPage.GoToGlobalShiptracker')}
                </SubmitButton>
              </div>
            </div>
          ) : (
            <p>{t('ShipTracker.AvailablePortsPage.AvailablePortsDescription')}</p>
          )}
          <div className={styles.portsOverview}>{!isLoading && data && <PortsIndex ports={data} />}</div>
          {!isShowCase && !isAuthenticated && (
            <div className={styles.createAccount}>
              <h3>{t('ShipTracker.Controls.AddDefaultPortAndTrafficTitle')}</h3>
              <p>{t('ShipTracker.Controls.AddDefaultPortAndTrafficText')}</p>
              <div className={styles.cta}>
                <SubmitButton
                  handleSubmit={() => {
                    track(getClickEventName('Available ports', 'Sign up'), {})
                    history.push(Pages.CREATE_ACCOUNT)
                  }}
                >
                  {t('ShipTracker.AvailablePortsPage.CreateMyFreeAccount')}
                </SubmitButton>
              </div>
            </div>
          )}
          {!isShowCase && isAuthenticated && !isFeatureEtaCalculated && !isFeatureLoading && (
            <div className={styles.createAccount}>
              <h3>{t('ShipTracker.Controls.ETASourceTitle2')}</h3>
              <p dangerouslySetInnerHTML={{ __html: t('ShipTracker.Controls.ETASourceText2') }} />
              <div className={styles.cta}>
                <SubmitButton handleSubmit={() => history.push(`${Pages.ACCOUNT_SUBSCRIPTION}/${Tab.WEB}`)}>
                  {t('ShipTracker.AvailablePortsPage.UpgradeToPremium')}
                </SubmitButton>
              </div>
            </div>
          )}
        </div>
      </OverlayScrollbarsComponent>
    </main>
  )
}
