import { useAuth0 } from '@auth0/auth0-react'
import { useMixpanel } from '../mixpanel/MixpanelContext'
import { isAfter } from 'date-fns'
import md5 from 'md5'
import { nanoid } from 'nanoid'
import { RefObject, useEffect, useRef } from 'react'
import { defer } from 'rxjs'
import { useAccountState } from '../../Account/AccountState'
import { fetchSubcriptionPlans } from '../../Api/Subscription/Plan/fetchPlans'
import { isShowCase } from '../../config'
import { Pages } from '../../constants'
import { store } from '../../dataStore'
import { PlanType, TRIAL_PLAN_TYPE } from '../../Domain/Subscription/Plan'
import { getMetadataCache, saveMetadataCache } from '../metadataCacheStorage'
import { useFromFetch } from '../useFromFetch'
import { useLocation } from 'react-router-dom'
import { clearVerifyEmailCache } from '../verifyEmailStorage'
import { useState } from 'react'
import { Metadata } from '../../Domain/User'
import { Dict } from 'mixpanel-browser'
import { usePrevious } from './usePrevious'

const DISABLED_PAGE_VIEWS: string[] = [Pages.DEV_PORTAL_CONNECT_API, Pages.CREATE_ACCOUNT]

type MetadataTrackingProperties = {
  purpose: Metadata['accountType']
  registrationDate?: string
  isTrial: boolean
  planType?: PlanType
  port?: string
  companyName?: string
  products?: string[]
  onboardingGuiStatus?: Metadata['onboardingGuiStatus']
  onboardingApiStatus?: Metadata['onboardingApiStatus']
}

const getMetadataTrackingProperties = async (): Promise<MetadataTrackingProperties> => {
  const metadata = await store.metadata.fetch()

  return {
    purpose: metadata.accountType,
    registrationDate: metadata.createdAt ? new Date(metadata.createdAt).toISOString() : undefined,
    ...(metadata.isInTrialPeriod
      ? { isTrial: true, planType: TRIAL_PLAN_TYPE }
      : {
          isTrial: false,
          ...(metadata.subscriptionExpirationDate && isAfter(new Date(), new Date(metadata.subscriptionExpirationDate))
            ? { planType: PlanType.FREE }
            : {}),
        }),
    ...(!isShowCase ? { port: metadata.defaultPort } : {}),
    ...(metadata.company?.name ? { companyName: metadata.company.name } : {}),
    ...(metadata.onboardingApiStatus ? { onboardingApiStatus: metadata.onboardingApiStatus } : {}),
    ...(metadata.onboardingGuiStatus ? { onboardingGuiStatus: metadata.onboardingGuiStatus } : {}),
    ...(metadata.products ? { products: metadata.products } : {}),
    ...(metadata.measurementUnit ? { measurementUnit: metadata.measurementUnit } : {}),
  }
}

export const useTracking = (email?: string) => {
  const { clearQueue, setupUser, track, setUserProperties, register, identify } = useMixpanel()
  const { user, isAuthenticated } = useAuth0()
  const [metadataProperties, setMetaProperties] = useState<MetadataTrackingProperties>()
  const { isLoading, data } = useFromFetch(getMetadataTrackingProperties)
  const [{ isLoaded: isSubscriptionLoaded, currentSubscriptionPlan }, { initializeState }] = useAccountState()
  const location = useLocation()
  const prevRoute = usePrevious(location.pathname)

  useEffect(() => {
    if (isShowCase || !isAuthenticated) {
      clearQueue()
    }
  }, [clearQueue, isAuthenticated])

  useEffect(() => setMetaProperties(data), [data])
  useEffect(() => {
    if (isAuthenticated) {
      getMetadataTrackingProperties().then(setMetaProperties)
      register({ environment: 'account' })
    } else {
      register({ environment: 'open' })
    }
  }, [isAuthenticated, register])

  useEffect(() => {
    register(getMetadataCache())
    setUserProperties(getMetadataCache())
  }, [register, setUserProperties])

  useEffect(() => {
    if (isSubscriptionLoaded) {
      register({ planType: currentSubscriptionPlan.kind })
      setUserProperties({ planType: currentSubscriptionPlan.kind })
      saveMetadataCache(cache => ({ ...cache, planType: currentSubscriptionPlan.kind }))
      return
    }
    if (!isLoading && !(metadataProperties && 'planType' in metadataProperties)) {
      const subscription = defer(() =>
        Promise.all([store.currentSubscription.fetch(), fetchSubcriptionPlans(), store.metadata.fetch()])
      ).subscribe(([subscription, plans, metadata]) => {
        if (subscription && plans && metadata) {
          initializeState({ subscription, plans, metadata })
        }
      })

      return subscription.unsubscribe.bind(subscription)
    }
  }, [
    register,
    setUserProperties,
    currentSubscriptionPlan,
    isSubscriptionLoaded,
    isLoading,
    initializeState,
    metadataProperties,
  ])

  useEffect(() => {
    if (email && !isLoading && metadataProperties !== undefined) {
      setupUser(md5(email), metadataProperties, ({ track }) => {
        clearQueue()
        saveMetadataCache(cache => ({ ...cache, ...metadataProperties }))
        clearVerifyEmailCache()
        track('Start session')
      })
    }
  }, [email, clearQueue, setupUser, track, isLoading, metadataProperties, user, register, setUserProperties, identify])

  useEffect(() => {
    const currentRoute = location.pathname
    if (currentRoute !== prevRoute && !DISABLED_PAGE_VIEWS.includes(currentRoute)) {
      track('View Page', { pageName: currentRoute })
    }
  }, [track, prevRoute, location])
}

export const useMixpanelTrackExternalLink = <T extends HTMLAnchorElement>(
  eventName: string,
  properties?: Dict
): RefObject<T> => {
  const { trackLink } = useMixpanel()
  const ref = useRef<T>(null)
  const [refId, setRefId] = useState<string>()
  const prevRefId = usePrevious(refId)

  useEffect(() => {
    if (ref.current) {
      const id = nanoid()
      ref.current.id = id
      setRefId(id)
    }
  }, [ref])

  useEffect(() => {
    if (refId && prevRefId !== refId) {
      trackLink(`#${refId}`, eventName, properties)
    }
  }, [eventName, prevRefId, properties, refId, trackLink])

  return ref
}
