import { Option, fold, chain, fromEither, none } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/function'
import { findCurrencyByPriceId } from '../../Domain/Subscription/Plan'
import { fetchAllPlans } from './Plan/fetchPlans'
import { Subscription } from '../../Domain/Subscription/Subscription'
import { Usage } from '../../Domain/ApiKey'
import { findFirstMap, head } from 'fp-ts/es6/Array'

export async function fetchPlansAndCurrency(
  fetchCurrentSubscription: () => Promise<Option<Subscription>>,
  fetchUsage: () => Promise<Option<Usage>>
) {
  const [plans, subscription, usage] = await Promise.all([fetchAllPlans(), fetchCurrentSubscription(), fetchUsage()])
  const currency = pipe(
    usage,
    fold(
      () =>
        pipe(
          subscription,
          chain(({ priceId }) =>
            pipe(
              plans.web,
              head,
              chain(plan =>
                pipe(
                  plan.prices,
                  findFirstMap(({ id, currency }) => (id === priceId ? fromEither(currency) : none))
                )
              )
            )
          )
        ),
      ({ mostRecentPurchase }) => findCurrencyByPriceId(plans.api, mostRecentPurchase)
    )
  )

  return { plans, currency }
}
