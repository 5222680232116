import React, { useContext, useState } from 'react'
import Rollbar from 'rollbar'
import { DEPLOYMENT_ENVIRONMENT, isTestEnvironment, ROLLBAR_TOKEN } from '../../config'

export const rollbarInstance = isTestEnvironment
  ? null
  : new Rollbar({
      accessToken: ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: DEPLOYMENT_ENVIRONMENT,
      },
    })

const RollbarContext = React.createContext<Rollbar | null>({} as Rollbar)

export const useRollbar = () => useContext(RollbarContext)

export const RollbarProvider: React.FC = ({ children }) => {
  const [rollbar] = useState(rollbarInstance)

  return <RollbarContext.Provider value={rollbar}>{children}</RollbarContext.Provider>
}
