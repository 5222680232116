import React, { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { generateApiKey } from '../../Api/ApiKey/generateApiKey'
import { getApiKey } from '../../Api/ApiKey/getApiKey'
import { Pages } from '../../constants'
import { ApiKey } from '../../Domain/ApiKey'
import { StepHeader, StepText, StepTitle } from '../../Flow/GenericStepElements'
import { trackError } from '../../lib/trackError'
import { useFromFetch } from '../../lib/useFromFetch'
import { CopyPasteField } from '../../UI/CopyPasteField/CopyPasteField'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { ConnectToApiStepProps } from './ConnectToApiOnboarding.d'
import { ApiDocumentationLink } from '../../Account/CreditsBundle/ApiDocumentationLink'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../lib/i18n'
import styles from './ConnectToApiOnboarding.module.scss'

export const StepGenerateApi: React.FC<ConnectToApiStepProps> = ({ onCompletedFlow }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [apiKey, setApiKey] = useState<ApiKey>()
  const { isLoading, data } = useFromFetch(getApiKey)

  useEffect(() => {
    if (!isLoading && data) {
      setApiKey(data)
    }
  }, [data, isLoading])

  const handleGenerateApiKey = useCallback(async () => {
    try {
      const response = await generateApiKey()
      setApiKey(response)
      onCompletedFlow()
    } catch (err) {
      trackError(err)
    }
  }, [onCompletedFlow])

  return (
    <>
      <StepHeader onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t('ShipTracker.DeveloperPortal.ConnectToApi.GenerateApiKey.Title')}</StepTitle>
      <StepText>
        {t(`ShipTracker.DeveloperPortal.ConnectToApi.GenerateApiKey.${apiKey ? 'ApiKeyDescription' : 'Description'}`)}
      </StepText>
      <StepText>
        <ApiDocumentationLink mixpanelPosition="api_flow" />
      </StepText>
      <div className={styles.generateKeyContent}>
        <CopyPasteField
          label="Your API-key"
          placeholder="No API-key generated yet"
          copyButtonName="Copy API-key"
          copyButtonClassName={styles.copyButton}
          value={apiKey?.keyId}
          isLoading={isLoading}
        >
          <CtaButton
            onClick={handleGenerateApiKey}
            className={styles.copyButton}
            isDisabled={isLoading || !!data || !!apiKey}
          >
            {t('ShipTracker.DeveloperPortal.ConnectToApi.GenerateApiKey.GenerateKey')}
          </CtaButton>
        </CopyPasteField>
        {apiKey && <StepText>Remaining credits: {apiKey.credits}</StepText>}
      </div>
    </>
  )
}
