import React, { useState } from 'react'
import { Option, none, some, fold, isSome } from 'fp-ts/es6/Option'
import { StripeCreditCardForm } from '../../Account/CreditCardForm'
import { ConfirmCardPayment, CreatePaymentMethod } from '../../Account/CreditCardForm/useStripe'
import styles from './ConnectToApiOnboarding.module.scss'
import { PaymentError, DURATION, PaymentSucceeded } from '../../UI/Toast'
import { toast } from 'react-toastify'
import { useTranslation } from '../../lib/i18n'
import { toError } from 'fp-ts/es6/Either'
import { trackError } from '../../lib/trackError'
import { constNull, pipe } from 'fp-ts/es6/function'
import { useEffect } from 'react'
import { InlineLoader, InlineLoaderTheme } from '../../UI/Loader/InlineLoader'
import { waitForToken } from '../../Account/UpdateSubscription/UpdateSubscription'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'
import { useAuth0 } from '@auth0/auth0-react'
import { createApiCheckout } from '../../Api/ApiKey/createApiCheckout'
import { SelectedApiPlan } from '../../Domain/Subscription/ApiPlan'
import { STRIPE_CONFIRMATION_STATUS } from '../../Domain/Stripe'
import { StepHeader, StepSubHeader, StepTitle, StepText } from '../../Flow/GenericStepElements'
import { ConnectToApiStepProps } from './ConnectToApiOnboarding.d'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'
import { formattedTotal } from '../../Account/helpers'

const inlineLoaderTheme: InlineLoaderTheme = {
  spinner: styles.lightBackgroundSpinner,
  loaderInline: styles.loaderInline,
}

type PaymentFormProps = {
  onPaymentSucceeded: () => void
  apiPlan: SelectedApiPlan
}
const PaymentForm: React.FC<PaymentFormProps> = ({ onPaymentSucceeded, apiPlan }) => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const [processing, setProcessing] = useState<Option<string>>(none)

  async function handleCreatePaymentMethod(
    createPaymentMethod: CreatePaymentMethod,
    confirmCardPayment: ConfirmCardPayment
  ) {
    try {
      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.RetrievingPaymentDetails')))

      const paymentMethod = await createPaymentMethod()

      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.AddingApiPlan')))

      const { clientSecret, status } = await createApiCheckout(apiPlan.price.id, paymentMethod.id)
      if (status === STRIPE_CONFIRMATION_STATUS) {
        const { status, last_payment_error } = await confirmCardPayment(clientSecret)
        if (status !== 'succeeded') {
          throw new Error(last_payment_error?.message ?? 'Payment has not succeeded')
        }
      }

      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.UpdatingSystem')))
      // refresh token
      await waitForToken(
        () => getAccessTokenSilently({ ignoreCache: true }),
        scope => scope.includes(Feature.CREATE_API_KEY)
      )

      toast.success(<PaymentSucceeded />, { autoClose: DURATION.MEDIUM })
      setProcessing(none)
      onPaymentSucceeded()
    } catch (e) {
      setProcessing(none)
      trackError(toError(e))
      toast.error(<PaymentError />, { autoClose: DURATION.EXTRA_LONG })
    }
  }

  return (
    <div className={styles.form}>
      <StripeCreditCardForm
        handleCreatePaymentMethod={handleCreatePaymentMethod}
        submitText="Go to API-key"
        isSubmitting={isSome(processing)}
      />
      {pipe(
        processing,
        fold(constNull, message => (
          <div className={styles.blocker}>
            <InlineLoader theme={inlineLoaderTheme} />
            <span>{message}</span>
          </div>
        ))
      )}
    </div>
  )
}

export const StepPaymentDetails: React.FC<ConnectToApiStepProps> = ({ onNextStep, onPrevStep, selectedPlan }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isEnabled: isCreateApiEnabled } = useFeatureToggler(Feature.CREATE_API_KEY)

  useEffect(() => {
    if (!selectedPlan) {
      onPrevStep()
    } else if (isCreateApiEnabled) {
      onNextStep()
    }
  }, [isCreateApiEnabled, selectedPlan, onPrevStep, onNextStep])

  return (
    <>
      <StepHeader onBackButton={onPrevStep} onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t('ShipTracker.DeveloperPortal.ConnectToApi.PaymentDetails.Title')}</StepTitle>
      <StepText>{t('ShipTracker.DeveloperPortal.ConnectToApi.PaymentDetails.Description')}</StepText>
      <StepSubHeader className={styles.selectedBundle}>
        {t('ShipTracker.DeveloperPortal.ConnectToApi.PaymentDetails.SelectedBundle')}:{' '}
        {selectedPlan && (
          <span className={styles.paymentDetailsTotal}>
            {selectedPlan.name} / {formattedTotal(selectedPlan.price.currency, selectedPlan.price.cents)}
          </span>
        )}
      </StepSubHeader>
      {selectedPlan && <PaymentForm onPaymentSucceeded={onNextStep} apiPlan={selectedPlan} />}
    </>
  )
}
