import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Pages } from '../../constants'
import { dataToFormValues, StepProfile, validationSchema } from './StepProfile'
import { StepGenerateApi } from './StepGenerateApi'
import { StepPaymentDetails } from './StepPaymentDetails'
import { useAuth0 } from '@auth0/auth0-react'
import { VerificationFlow } from '../../lib/verifyEmailStorage'
import { useFromFetch } from '../../lib/useFromFetch'
import { store } from '../../dataStore'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'
import { useState } from 'react'
import { StepPlanSelection } from './StepPlanSelection'
import { isApiPlanType, ApiPlanType, SelectedApiPlan } from '../../Domain/Subscription/ApiPlan'
import { Currency, isKnownCurrency } from '../../Domain/Subscription/Plan'
import { useTranslation } from '../../lib/i18n'
import { Flow } from '../../Flow/Flow'
import { useFlow } from '../../Flow/useFlow'
import { GenericStepProps, StepsProps } from '../../Flow/GenericStepElements.d'
import { CommonStepAuthentication } from '../../Flow/CommonSteps/CommonStepAuthentication'
import { CommonStepEmailVerification } from '../../Flow/CommonSteps/CommonStepEmailVerification'
import { CombinedStepLegalTerms } from '../../Flow/CommonSteps/CommonStepLegalTerms'
import { InitialPlanSelection, SelectedPlanProps, StepName } from './ConnectToApiOnboarding.d'
import { useHandleFlowStart } from '../../Flow/useHandleFlowStart'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { useRedirectToPath } from '../../lib/hooks/useRedirectToPath'
import { ValueOf } from '../../lib/ValueOf'
import { AuthFlowProvider, useAuthFlowContext, VerificationStatus } from '../../Auth/AuthFlowContext'
import { addMetadataProduct } from '../../Domain/User'

const stepNameTranslationKeys: { [key in StepName]: string } = {
  [StepName.AUTHENTICATION]: 'AUTHENTICATION',
  [StepName.EMAIL_VERIFICATION]: 'EMAIL_VERIFICATION',
  [StepName.LEGAL_TERMS]: 'LEGAL_TERMS',
  [StepName.PROFILE]: 'PROFILE',
  [StepName.PLAN_SELECTION]: 'PLAN_SELECTION',
  [StepName.PAYMENT_DETAILS]: 'PAYMENT_DETAILS',
  [StepName.GENERATE_API]: 'GENERATE_API',
}

const stepNamesArray: StepName[] = Object.values(StepName)

const stepsToData = (label: (step: string) => string, current: StepName) =>
  stepNamesArray.map(step => ({
    key: step,
    label: label(stepNameTranslationKeys[step]),
    isActive: step === current,
  }))

const AuthenticatedFlow: React.FC<{
  setStep: (step: StepName) => void
  setInitialStep: (step: StepName) => void
}> = ({ setStep, setInitialStep, children }) => {
  const { user } = useAuth0()
  const [isAuthenticatedFlowLoaded, setIsAuthenticatedFlowLoaded] = useState(false)
  const { isLoading, data: metadata } = useFromFetch(store.metadata.fetch)
  const { isEnabled: isCreateApiEnabled } = useFeatureToggler(Feature.CREATE_API_KEY)

  useEffect(() => {
    if (!isAuthenticatedFlowLoaded && !isLoading && metadata !== undefined) {
      const stepConditions: [StepName, () => boolean][] = [
        [StepName.LEGAL_TERMS, () => !metadata?.acceptedApiTerms],
        [
          StepName.PROFILE,
          () => !!user?.email && !validationSchema.isValidSync(dataToFormValues(user.email, metadata)),
        ],
        [StepName.PLAN_SELECTION, () => !isCreateApiEnabled],
        [StepName.PAYMENT_DETAILS, () => !isCreateApiEnabled],
        [StepName.GENERATE_API, () => true],
      ]

      for (const [name, condition] of stepConditions) {
        if (condition()) {
          setStep(name)
          setInitialStep(name)
          setIsAuthenticatedFlowLoaded(true)
          break
        }
      }
    }
  }, [isLoading, metadata, isCreateApiEnabled, user, setStep, setInitialStep, isAuthenticatedFlowLoaded])

  return isLoading || metadata === undefined ? null : <>{children}</>
}

const UnAuthenticatedFlow: React.FC<{
  setStep: (step: StepName) => void
  setInitialStep: (step: StepName) => void
  current: StepName
}> = ({ setStep, setInitialStep, current, children }) => {
  const { status } = useAuthFlowContext()

  useEffect(() => {
    if (
      current !== StepName.EMAIL_VERIFICATION &&
      [VerificationStatus.Unverified, VerificationStatus.Verified].includes(status)
    ) {
      setStep(StepName.EMAIL_VERIFICATION)
    } else {
      setInitialStep(current)
    }
  }, [setStep, status, current, setInitialStep])

  return <>{children}</>
}

type FlowContentProps = StepsProps<StepName> & {
  selectedPlanProps: SelectedPlanProps
  authenticationProps: {
    loginRedirectPage: ValueOf<Pick<typeof Pages, 'CREATE_ACCOUNT' | 'DEV_PORTAL_CONNECT_API'>>
    verificationFlow: VerificationFlow
  }
}

export const FlowContent = ({
  current,
  onNextStep,
  onPrevStep,
  onSetStep,
  onCompletedFlow,
  selectedPlanProps,
  authenticationProps,
}: FlowContentProps) => {
  const genericProps: Omit<GenericStepProps<StepName>, 'index'> = {
    onNextStep,
    onPrevStep,
    onSetStep,
    onCompletedFlow,
  }

  switch (current) {
    case StepName.AUTHENTICATION:
      return <CommonStepAuthentication {...authenticationProps} {...genericProps} />
    case StepName.EMAIL_VERIFICATION:
      return <CommonStepEmailVerification {...authenticationProps} {...genericProps} />
    case StepName.LEGAL_TERMS:
      return <CombinedStepLegalTerms {...genericProps} />
    case StepName.PROFILE:
      return <StepProfile {...genericProps} {...selectedPlanProps} />
    case StepName.PLAN_SELECTION:
      return <StepPlanSelection {...genericProps} {...selectedPlanProps} />
    case StepName.PAYMENT_DETAILS:
      return <StepPaymentDetails {...genericProps} {...selectedPlanProps} />
    case StepName.GENERATE_API:
      return <StepGenerateApi {...genericProps} {...selectedPlanProps} />
  }
}

export const ConnectToApiOnboarding = () => {
  const { isAuthenticated } = useAuth0()
  const { t } = useTranslation()
  const { current, setStep, nextStep, prevStep } = useFlow(stepNamesArray)
  const location = useLocation()
  const { setUserProperties, register, track, getSuperProperty } = useMixpanel()
  const [initialStep, setInitialStep] = useState<StepName>()
  const handleFlowStart = useHandleFlowStart('onboardingApiStatus')

  const [selectedPlan, setSelectedPlan] = useState<SelectedApiPlan>()
  const [initialPlanSelection, setInitialPlanSelection] = useState<InitialPlanSelection>({
    type: ApiPlanType.API_1000,
    currency: Currency.USD,
  })

  const isLoadingStep = initialStep === undefined

  useRedirectToPath(!isLoadingStep, `${Pages.DEV_PORTAL_CONNECT_API}/${current}`, `Onboarding - API - Step ${current}`)
  useEffect(() => {
    if (!isLoadingStep) {
      handleFlowStart()
    }
  }, [handleFlowStart, isLoadingStep])

  const stepsData = useMemo(
    () => stepsToData(key => t(`ShipTracker.DeveloperPortal.ConnectToApi.Steps.${key}`), current),
    [current, t]
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const planType = params.get('planType')
    const planCurrency = new URLSearchParams(location.search).get('currency')

    if (planType !== null && isApiPlanType(planType)) {
      setInitialPlanSelection(selection => ({ ...selection, type: planType }))
    }

    if (planCurrency !== null && isKnownCurrency(planCurrency)) {
      setInitialPlanSelection(selection => ({ ...selection, currency: planCurrency }))
    }
  }, [location])

  const selectedPlanProps: SelectedPlanProps = {
    selectedPlan,
    initialPlanSelection,
    setSelectedPlan,
  }

  const content = (
    <FlowContent
      current={current}
      onSetStep={setStep}
      onNextStep={nextStep}
      onPrevStep={prevStep}
      selectedPlanProps={selectedPlanProps}
      onCompletedFlow={async () => {
        const products = await addMetadataProduct('Shiptracker API')
        await store.metadata.update({ onboardingApiStatus: 'completed', products })

        if (getSuperProperty('onboardingApiStatus') !== 'completed') {
          const trackingProperties = { onboardingApiStatus: 'completed', products }
          setUserProperties(trackingProperties)
          register(trackingProperties)
          track('Onboarding ends - API')
        }
      }}
      authenticationProps={{ verificationFlow: VerificationFlow.API, loginRedirectPage: Pages.DEV_PORTAL_CONNECT_API }}
    />
  )

  return (
    <Flow title="Buy credits &amp; connect to ETA API" isLoading={isLoadingStep} stepsData={stepsData}>
      {isAuthenticated ? (
        <AuthenticatedFlow setStep={setStep} setInitialStep={setInitialStep}>
          {!isLoadingStep && content}
        </AuthenticatedFlow>
      ) : (
        <AuthFlowProvider verificationFlow={VerificationFlow.API}>
          <UnAuthenticatedFlow current={current} setStep={setStep} setInitialStep={setInitialStep}>
            {!isLoadingStep && content}
          </UnAuthenticatedFlow>
        </AuthFlowProvider>
      )}
    </Flow>
  )
}
