export type Zipper<A> = Readonly<{
  before: A[]
  current: A
  after: A[]
}>

export function create<A>(current: A, after: A[], before: A[] = []): Zipper<A> {
  return {
    before,
    current,
    after,
  }
}

export function next<A>({ before, current, after }: Zipper<A>): Zipper<A> | null {
  if (after.length) {
    const [head, ...tail] = after
    return { before: [current, ...before], current: head, after: tail }
  }
  return null
}

export function prev<A>({ before, current, after }: Zipper<A>): Zipper<A> | null {
  if (before.length) {
    const [head, ...tail] = before
    return { before: tail, current: head, after: [current, ...after] }
  }
  return null
}

export const isLast = <A>({ after }: Zipper<A>) => after.length === 0
export const isFirst = <A>({ before }: Zipper<A>) => before.length === 0

export const update = <A>(fn: (a: A) => A, zipper: Zipper<A>) => ({ ...zipper, current: fn(zipper.current) })
