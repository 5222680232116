import { createStore, createHook, Action } from 'react-sweet-state'
import { chain, getOrElse, Option, none, some } from 'fp-ts/es6/Option'
import { pipe, constant } from 'fp-ts/es6/function'
import {
  Subscription,
  isPaidSubscriptionPlan,
  findCurrentSubscriptionPlan,
  freeSubscriptionPlan,
  SubscriptionPlan,
  findTrialPlan,
  findCurrentSubscriptionPlanWithDefault,
} from '../Domain/Subscription/Subscription'
import { Plan, Price } from '../Domain/Subscription/Plan'
import { PaymentMethod } from '@stripe/stripe-js'
import { Metadata } from '../Domain/User'
import { isPast } from 'date-fns'
import { store } from '../dataStore'
import { useEffect } from 'react'
import { fetchSubcriptionPlans } from '../Api/Subscription/Plan/fetchPlans'
import { defer } from 'rxjs'

type State = {
  isLoaded: boolean
  currentSubscriptionPlan: SubscriptionPlan
  plans: Plan[]
  hasDefaultPort?: boolean
  isSideMenuOpen: Option<boolean>
}

const initialState: State = {
  isLoaded: false,
  currentSubscriptionPlan: freeSubscriptionPlan,
  plans: [],
  isSideMenuOpen: none,
}

const actions = {
  initializeState: ({
    subscription,
    plans,
    metadata,
  }: {
    subscription: Option<Subscription>
    plans: Plan[]
    metadata: Metadata
  }): Action<State> => ({ setState }) => {
    const currentSubscriptionPlan = pipe(
      subscription,
      chain(sub => findCurrentSubscriptionPlan(plans, sub)),
      getOrElse(() => {
        if (!metadata.isInTrialPeriod || metadata.subscriptionExpirationDate === undefined) {
          return freeSubscriptionPlan
        }

        const trialExpiryDate = new Date(metadata.subscriptionExpirationDate)
        // are we not in a trial
        if (isPast(trialExpiryDate)) {
          return freeSubscriptionPlan
        }

        return pipe(findTrialPlan(plans, trialExpiryDate), getOrElse(constant(freeSubscriptionPlan)))
      })
    )

    setState({
      isLoaded: true,
      plans,
      currentSubscriptionPlan,
      hasDefaultPort: metadata.defaultPort !== undefined,
    })
  },

  createSubscription: (price: Price, paymentMethod: PaymentMethod): Action<State> => async ({ setState, getState }) => {
    const subscription = await store.currentSubscription.create({
      priceId: price.id,
      paymentMethodId: paymentMethod.id,
    })

    const { plans } = getState()

    const currentSubscriptionPlan = findCurrentSubscriptionPlanWithDefault(plans, subscription, freeSubscriptionPlan)

    setState({ ...getState(), currentSubscriptionPlan })
  },

  cancelSubscription: (): Action<State> => async ({ getState, setState }) => {
    const { currentSubscriptionPlan, plans } = getState()

    if (isPaidSubscriptionPlan(currentSubscriptionPlan)) {
      const subscription = await store.currentSubscription.delete(currentSubscriptionPlan.subscription.id)

      const updatedSubscriptionPlan = findCurrentSubscriptionPlanWithDefault(plans, subscription, freeSubscriptionPlan)

      setState({ ...getState(), currentSubscriptionPlan: updatedSubscriptionPlan })
    }
  },

  resumeSubscription: (): Action<State> => async ({ getState, setState }) => {
    const { currentSubscriptionPlan, plans } = getState()

    if (isPaidSubscriptionPlan(currentSubscriptionPlan)) {
      const subscription = await store.currentSubscription.update({
        id: currentSubscriptionPlan.subscription.id,
        priceId: currentSubscriptionPlan.price.id,
      })

      const updatedSubscriptionPlan = findCurrentSubscriptionPlanWithDefault(plans, subscription, freeSubscriptionPlan)

      setState({ ...getState(), currentSubscriptionPlan: updatedSubscriptionPlan })
    }
  },

  closeSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: some(false) })
  },

  openSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: some(true) })
  },

  resetSideMenu: (): Action<State> => ({ getState, setState }) => {
    setState({ ...getState(), isSideMenuOpen: none })
  },
}

type Actions = typeof actions

const Store = createStore<State, Actions>({ initialState, actions })

export const useAccountState = createHook(Store)

const accountStateFetcher = defer(() =>
  Promise.all([store.currentSubscription.fetch(), fetchSubcriptionPlans(), store.metadata.fetch()])
)

export const useInitializedAccountState = () => {
  const state = useAccountState()
  const [{ isLoaded }, { initializeState }] = state

  useEffect(() => {
    if (isLoaded) {
      return
    }

    const subscription = accountStateFetcher.subscribe(([subscription, plans, metadata]) => {
      initializeState({ subscription, plans, metadata })
    })

    return () => subscription.unsubscribe()
  }, [isLoaded, initializeState])

  return state
}
