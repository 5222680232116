import React from 'react'

import { Switch, RouteComponentProps, Route } from 'react-router-dom'
import { Pages } from '../constants'
import { Overview } from './Overview/Overview'
import { DefaultPort } from './DefaultPort/DefaultPort'
import { Subscription } from './Subscription/Subscription'
import { UpdateSubscription } from './UpdateSubscription/UpdateSubscription'
import { UserProfile } from './UserProfile/UserProfile'
import { ShowCaseUserProfile } from './UserProfile/ShowCaseUserProfile'
import { PaymentDetails } from './PaymentDetails/PaymentDetails'
import { Invoices } from './Invoices/Invoices'
import { AccountPage } from './AccountPage'
import { useTranslation } from '../lib/i18n'
import { Currency, PaidPlan, Period } from '../Domain/Subscription/Plan'
import { LegalTermsPage } from './ApiOnly/LegalTermsPage'
import { apiOnlySubscriptionRoute, ApiOnlyStep } from './accountRoutes'
import { PaymentPage } from './ApiOnly/PaymentPage'
import { ApiKeyPage } from './ApiKey/ApiKey'

type RouteProps = { redirect: string }

export const Routes = ({ redirect }: RouteProps) => {
  const { t } = useTranslation()

  return (
    <Switch>
      <Route exact path={Pages.ACCOUNT}>
        <AccountPage>
          <Overview />
        </AccountPage>
      </Route>
      <Route exact path={Pages.ACCOUNT_DEFAULT_PORT}>
        <AccountPage sectionTitle={t('ShipTracker.Account.DefaultPort.DestinationPortTitle')}>
          <DefaultPort />
        </AccountPage>
      </Route>
      <Route path={Pages.ACCOUNT_SUBSCRIPTION}>
        <AccountPage>
          <Subscription />
        </AccountPage>
      </Route>
      <Route exact path={`${Pages.ACCOUNT_UPDATE_SUBSCRIPTION}/:plan/:period/:currency`}>
        {({
          match: {
            params: { plan, period, currency },
          },
        }: RouteComponentProps<{ plan: PaidPlan; period: Period; currency: Currency }>) => (
          <AccountPage sectionTitle={t('ShipTracker.Account.UpdateSubscription.UpdateSubscriptionTitle')}>
            <UpdateSubscription plan={plan} period={period} currency={currency} />
          </AccountPage>
        )}
      </Route>
      <Route exact path={Pages.ACCOUNT_PROFILE}>
        <AccountPage sectionTitle={t('ShipTracker.Account.UserProfile.UserProfileTitle')}>
          <UserProfile />
        </AccountPage>
      </Route>
      <Route exact path={Pages.ACCOUNT_PAYMENT_DETAILS}>
        <AccountPage sectionTitle={t('ShipTracker.Account.PaymentDetails.PaymentDetailsTitle')}>
          <PaymentDetails />
        </AccountPage>
      </Route>
      <Route exact path={Pages.ACCOUNT_INVOICES}>
        <AccountPage sectionTitle={t('ShipTracker.Account.Invoices.InvoicesTitle')}>
          <Invoices />
        </AccountPage>
      </Route>
      <Route exact path={Pages.ACCOUNT_API_KEY}>
        <AccountPage sectionTitle={t('ShipTracker.Account.ApiKey.ApiKeyTitle')}>
          <ApiKeyPage />
        </AccountPage>
      </Route>
      <Route path={Pages.ACCOUNT_API_ONLY}>
        <Switch>
          <Route exact path={apiOnlySubscriptionRoute(ApiOnlyStep.Payment)}>
            <AccountPage sectionTitle={t('ShipTracker.Account.ApiOnly.AccountApiOnlyPaymentTitle')}>
              <PaymentPage />
            </AccountPage>
          </Route>
          <Route>
            <AccountPage sectionTitle={t('ShipTracker.Account.ApiOnly.AccountApiOnlyLegalTitle')}>
              <LegalTermsPage />
            </AccountPage>
          </Route>
        </Switch>
      </Route>
    </Switch>
  )
}

export function ShowCaseRoutes({ redirect }: RouteProps) {
  const { t } = useTranslation()

  return (
    <Switch>
      <Route exact path={Pages.ACCOUNT} redirect={redirect}>
        <AccountPage sectionTitle={t('ShipTracker.Account.UserProfile.UserProfileTitle')}>
          <ShowCaseUserProfile />
        </AccountPage>
      </Route>
    </Switch>
  )
}
