import React from 'react'
import classnames from 'classnames'
import { useTranslation } from '../../lib/i18n'
import { SubmitButton } from '../AccountPage'
import { useHistory } from 'react-router-dom'
import { apiOnlySubscriptionRoute, ApiOnlyStep } from '../accountRoutes'
import { object as YupObject, bool } from 'yup'
import { Formik } from 'formik'
import { InputError } from '../InputError'
import { Checkbox } from '../../UI/Checkbox/Checkbox'
import { store } from '../../dataStore'
import { CURRENT_VERSION_LEGAL_TERMS } from '../../constants'
import { createZoneDateTime } from '../../Domain/DateTime'
import { formatISO } from 'date-fns'
import styles from './ApiOnlyAccountPage.module.scss'
import { ApiLegalTerms } from '../../Generic/ApiLegalTermsPage'

type FormSchema = Readonly<{
  acceptedTerms: boolean
}>

const validationSchema = YupObject<FormSchema>().shape(
  {
    acceptedTerms: bool().oneOf([true], 'Required'),
  },
  []
)

const initialValues: FormSchema = {
  acceptedTerms: false,
}

export function LegalTermsPage() {
  const { t } = useTranslation()
  const history = useHistory()

  const onSubmit = async () => {
    await store.metadata.update({
      acceptedTerms: createZoneDateTime(formatISO(new Date(CURRENT_VERSION_LEGAL_TERMS))),
    })

    history.push(apiOnlySubscriptionRoute(ApiOnlyStep.Payment))
  }

  return (
    <div className={styles.legalTerms}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, setFieldValue, handleSubmit, isValid, dirty, errors }) => {
          const handleAcceptedTermsChange = setFieldValue.bind(setFieldValue, 'acceptedTerms')

          const isDisabled = !dirty || !isValid

          return (
            <>
              <div className={classnames(styles.section, styles.description)}>
                <ApiLegalTerms />
              </div>
              <div className={styles.section}>
                <div className={styles.subSection}>
                  <Checkbox
                    onChange={handleAcceptedTermsChange}
                    checked={values.acceptedTerms}
                    className={styles.checkbox}
                  >
                    {t('ShipTracker.Account.LegalTerms.AcceptTermsAndConditions')}
                  </Checkbox>
                  {'acceptedTerms' in errors && (
                    <InputError>{t('ShipTracker.Account.LegalTerms.MustAcceptTerms')}</InputError>
                  )}
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.continue}>
                  <SubmitButton disabled={isDisabled} handleSubmit={handleSubmit}>
                    {t('ShipTracker.Account.LegalTerms.Continue')}
                  </SubmitButton>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}
