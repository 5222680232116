import { useCallback } from 'react'
import { useSearchState } from './useSearchState'
import { Port } from '../../Domain/Port'
import { fuzzyPortSearch, SearchResult } from '../../Account/SelectPort/fuzzyPortSearch'

export function useSelectPort(id: string | undefined, data: Port[], minimumQueryLength: number) {
  const searchCallback = useCallback((term: string) => fuzzyPortSearch(data)(term), [data])
  const selectedItem = data && id ? data.find(p => p.port === id) : undefined
  const { searchState, onQueryChange } = useSearchState<SearchResult[]>(searchCallback, {
    debounceTime: 700,
    minimumQueryLength,
    initialTerm: selectedItem?.port,
  })

  return {
    selectedItem,
    searchState,
    onQueryChange,
  }
}
