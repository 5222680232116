import { useState, useEffect, useCallback } from 'react'
import { pipe } from 'fp-ts/es6/function'
import { Option, map, chain, some, none, fold } from 'fp-ts/es6/Option'
import { createApiCheckout } from '../../Api/ApiKey/createApiCheckout'

import { stripe$, STRIPE_CONFIRMATION_STATUS } from '../../Domain/Stripe'
import { fetchDefaultCreditCards } from '../../Api/Subscription/CreditCard/fetchCreditCards'
import { fetchUsage } from '../../Api/ApiKey/fetchUsage'
import { useConfirmationModal } from '../../UI/Modal/ConfirmationModal'
import { formattedTotal } from '../helpers'
import { useTranslation } from '../../lib/i18n'
import { Currency, findPriceByCurrency, Price } from '../../Domain/Subscription/Plan'
import { defer, noop } from 'rxjs'
import { ApiPlan } from '../../Domain/Subscription/ApiPlan'
import { Usage } from '../../Domain/ApiKey'
import { CreditCard } from '../../Domain/Subscription/CreditCard'
import { useAuth0 } from '@auth0/auth0-react'
import { PaymentIntent } from '@stripe/stripe-js'
import { toPromise } from '../../lib/utils'
import { shareReplay } from 'rxjs/operators'

const data$ = defer(() => Promise.all([fetchUsage(), fetchDefaultCreditCards()])).pipe(shareReplay(1))

const withUsage = <A>(usage: Option<Usage>) =>
  chain((option: A) =>
    pipe(
      usage,
      map(() => option)
    )
  )

const composeModalContent = (
  t: (...s: string[]) => string,
  name: string,
  { currency, cents }: Price,
  { last4 }: CreditCard
) => ({
  title: t('ShipTracker.Account.Subscription.ConfirmBuyCreditsBundleTitle', name),
  text: t('ShipTracker.Account.Subscription.ConfirmBuyCreditsBundleText', name, formattedTotal(currency, cents), last4),
})

async function buyCreditsBundle(price: Price, creditCard: CreditCard) {
  const { clientSecret, status } = await createApiCheckout(price.id, creditCard.id)

  if (status !== STRIPE_CONFIRMATION_STATUS) {
    throw new Error(status)
  }

  const stripe = await toPromise(stripe$)

  if (stripe === null) {
    throw new Error('Payment provider not available')
  }

  const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret)

  if (error) {
    throw error
  }

  if (paymentIntent) {
    if (paymentIntent.status !== 'succeeded') {
      throw new Error('Payment has not succeeded')
    }
    return paymentIntent
  }

  throw new Error('Received unexpected result')
}

export function useBuyCredits() {
  const { isAuthenticated } = useAuth0()
  const { t } = useTranslation()

  const [modalContent, setModalContent] = useState({ title: '', text: '' })
  const [ModalContainer, askForConfirmation] = useConfirmationModal({
    ...modalContent,
    confirmText: t('ShipTracker.Common.Buy'),
  })

  useEffect(() => {
    if (!isAuthenticated) {
      return noop
    }
    // preload data
    const subscription = data$.subscribe()

    return subscription.unsubscribe.bind(subscription)
  }, [isAuthenticated])

  const buyCredits = useCallback(
    (plan: ApiPlan, currency: Currency) =>
      new Promise<Option<PaymentIntent>>(resolve => {
        if (!isAuthenticated) {
          return resolve(none)
        }

        data$.subscribe(([usage, [creditCard]]) => {
          pipe(
            findPriceByCurrency(plan.prices, currency),
            withUsage(usage),
            fold(
              () => {
                resolve(none)
              },
              price => {
                plan.name && setModalContent(composeModalContent(t, plan.name, price, creditCard))

                askForConfirmation(() => buyCreditsBundle(price, creditCard).then(res => resolve(some(res))))
              }
            )
          )
        })
      }),
    [isAuthenticated, askForConfirmation, t]
  )

  return { buyCredits, ModalContainer }
}
