import React from 'react'
import styles from './PersuasiveScreen.module.scss'
import { ThemeScreen, ThemeScreenColor } from '../../UI/ThemeScreen/ThemeScreen'
import { useTranslation } from '../../lib/i18n'
import { PortcallVesselLegend, TrafficVesselLegend } from '../VesselLegend/VesselLegend'
import MaterialIcon from '../../UI/MaterialIcon'
import { useHistory } from 'react-router-dom'
import { Pages, SHIPTRACKER_GLOBAL_REDIRECT_URL } from '../../constants'
import { CloseButton, CloseButtonTheme } from '../../UI/CloseButton'
import classnames from 'classnames'
import disconnectedIconSrc from './icons/disconnected.svg'
import { useMixpanelTrackExternalLink } from '../../lib/hooks/useTracking'

const closeButtonTheme: CloseButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

export const PersuasiveScreen1 = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <ThemeScreen color={ThemeScreenColor['blue']}>
      <CloseButton theme={closeButtonTheme} onClose={() => history.push(Pages.MAIN)} />
      <div className={styles.content}>
        <h1 className={styles.title}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.Title')}</h1>
        <span className={styles.subTitle}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.SubTitle')}</span>
        <p className={styles.description}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.Description')}</p>
        <div className={styles.completeAccountContent}>
          <div className={styles.formActions}>
            <button className={styles.submitButton} onClick={() => history.push(Pages.CREATE_ACCOUNT)}>
              {t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.SignUp')}
            </button>
            <span className={styles.submitButtonSubText}>
              {t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.SignUpQuick')}
            </span>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <h4 className={styles.vesselLegendTitle}>
            {t('ShipTracker.Account.PersuasiveScreen.CreateAccount1.LegendTitle')}
          </h4>
          <div className={styles.vesselLegendEntry}>
            <PortcallVesselLegend />
          </div>
          <div className={styles.vesselLegendEntry}>
            <TrafficVesselLegend />
          </div>
        </div>
      </div>
    </ThemeScreen>
  )
}

export const PersuasiveScreen2 = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <ThemeScreen color={ThemeScreenColor['green']}>
      <CloseButton theme={closeButtonTheme} onClose={() => history.push(Pages.MAIN)} />
      <div className={styles.content}>
        <h1 className={styles.title}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount2.Title')}</h1>
        <span className={styles.subTitle}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount2.SubTitle')}</span>
        <p className={styles.description}>{t('ShipTracker.Account.PersuasiveScreen.CreateAccount2.Description')}</p>
        <div className={styles.completeAccountContent}>
          <div className={styles.formActions}>
            <button className={styles.submitButton} onClick={() => history.push(Pages.CREATE_ACCOUNT)}>
              {t('ShipTracker.Account.PersuasiveScreen.CreateAccount2.SignUp')}
            </button>
            <span className={styles.submitButtonSubText}>
              {t('ShipTracker.Account.PersuasiveScreen.CreateAccount2.SignUpQuick')}
            </span>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <MaterialIcon type="email" className={styles.bottomIcon} />
        </div>
      </div>
    </ThemeScreen>
  )
}

export const GlobalShipTrackerPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const linkRef = useMixpanelTrackExternalLink('ShipTracker Global')

  return (
    <ThemeScreen color={ThemeScreenColor['green']}>
      <CloseButton theme={closeButtonTheme} onClose={() => history.goBack()} />
      <div className={styles.content}>
        <h1 className={classnames(styles.title, styles.titleWide, styles.titleSpaceBottom)}>
          {t('ShipTracker.Account.PersuasiveScreen.GlobalShipTracker.Title')}
        </h1>
        <p className={styles.description}>{t('ShipTracker.Account.PersuasiveScreen.GlobalShipTracker.Description')}</p>
        <div className={styles.completeAccountContent}>
          <div className={styles.formActions}>
            <a ref={linkRef} className={styles.submitButton} href={SHIPTRACKER_GLOBAL_REDIRECT_URL()}>
              {t('ShipTracker.Account.PersuasiveScreen.GlobalShipTracker.CTA')}
            </a>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <MaterialIcon type="language" className={styles.bottomIcon} />
        </div>
      </div>
    </ThemeScreen>
  )
}

export const ApiShipTrackerPromoPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const linkRef = useMixpanelTrackExternalLink('Click "Buy API credits"', { position: 'takeover' })

  return (
    <ThemeScreen color={ThemeScreenColor['purple']}>
      <CloseButton theme={closeButtonTheme} onClose={() => history.push(Pages.MAIN)} />
      <div className={styles.content}>
        <h1 className={classnames(styles.title, styles.titleWide, styles.titleSpaceBottom)}>
          {t('ShipTracker.Account.PersuasiveScreen.ApiPromo.Title')}
        </h1>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: t('ShipTracker.Account.PersuasiveScreen.ApiPromo.Description') }}
        ></p>
        <div className={styles.completeAccountContent}>
          <div className={styles.formActions}>
            <a
              ref={linkRef}
              className={styles.submitButton}
              target="_blank"
              rel="noopener noreferrer"
              href={Pages.API_CREDITS_STORE}
            >
              {t('ShipTracker.Account.PersuasiveScreen.ApiPromo.CTA')}
            </a>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <img src={disconnectedIconSrc} className={styles.bottomIcon} alt="Disconnected icon" />
        </div>
      </div>
    </ThemeScreen>
  )
}
