import classnames from 'classnames'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { LivechatComponent } from '../../lib/hooks/LivechatComponent'
import { ControlStripButton, ControlStripEntry } from '../../UI/ControlStrip/ControlStrip'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import styles from './Controls.module.scss'

declare global {
  interface Window {
    HubSpotConversations: {
      on: <T>(event: string, callback: (payload: T) => void) => void
      off: <T>(event: string, callback: (payload: T) => void) => void
    }
  }
}

export const ChatControl: React.FC = () => {
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    const handleUnreadCountChange = (payload: { unreadCount: number }) => {
      setUnreadCount(payload.unreadCount)
    }
    if (window.HubSpotConversations) {
      window.HubSpotConversations.on('unreadConversationCountChanged', handleUnreadCountChange)

      return () => window.HubSpotConversations.off('unreadConversationCountChanged', handleUnreadCountChange)
    }
  }, [])

  return (
    <ControlStripEntry>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => {
          return (
            <>
              <div className={styles.chatWrapper}>
                <ControlStripButton onClick={isOpen ? closeMenu : () => openMenu(() => setUnreadCount(0))}>
                  Start chat
                </ControlStripButton>
                {unreadCount > 0 && <div className={styles.chatUnreadBadge}>{unreadCount}</div>}
              </div>
              <LivechatComponent className={classnames(styles.chatWindow, { [styles.chatWindowOpen]: isOpen })} />
            </>
          )
        }}
      </DropoutMenu>
    </ControlStripEntry>
  )
}
