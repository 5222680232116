import { some, none, fromEither } from 'fp-ts/es6/Option'
import { findFirst, findFirstMap } from 'fp-ts/es6/Array'
import { pipe } from 'fp-ts/es6/function'
import { PREMIUM_PLAN_ID, API_PLAN_ID } from '../../config'
import { Either, right, isRight } from 'fp-ts/es6/Either'

export enum Currency {
  USD = 'usd',
  EUR = 'eur',
}

export const defaultCurrency = right(Currency.USD)

export const isKnownCurrency = (currency: string): currency is Currency => currency.toUpperCase() in Currency

export const currencySymbol: { [key in Currency]: string } = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
}

export const currencyFromString = (currency: string) => (currency === Currency.USD ? Currency.USD : Currency.EUR)

const periods = ['month', 'year'] as const

export type Period = typeof periods[number]

export type Price = {
  cents: number
  currency: Either<string, Currency>
  id: string
  period?: Period
}

export type PriceJson = Omit<Price, 'currency'> & { currency: string; priceType: 'recurring' | 'oneTime' }

export type PlanJson = Omit<Plan, 'prices'> & {
  credits?: number
  prices: PriceJson[]
}

export type Plan = {
  id: string
  name?: string
  description?: string
  order: number
  prices: Price[]
}

export enum PlanType {
  FREE = 'Free',
  PREMIUM = 'Premium',
  API_ONLY = 'API-Only',
}

export type PaidPlan = PlanType.PREMIUM | PlanType.API_ONLY
export type FreePlan = PlanType.FREE

export function planFromId(id: string) {
  switch (id) {
    case PREMIUM_PLAN_ID:
      return some(PlanType.PREMIUM)
    case API_PLAN_ID:
      return some(PlanType.API_ONLY)
    default:
      return none
  }
}

export const TRIAL_PLAN_TYPE = PlanType.PREMIUM

export const isPricePeriod = (period: Period): period is Period => periods.includes(period)

export const isPaidPlan = (plan: PlanType): plan is PaidPlan => [PlanType.PREMIUM, PlanType.API_ONLY].includes(plan)

export const planToID: Omit<{ [key in PlanType]: string }, PlanType.FREE> = {
  [PlanType.PREMIUM]: PREMIUM_PLAN_ID,
  [PlanType.API_ONLY]: API_PLAN_ID,
}

export const findPaidPlanPrice = (paidPlan: PaidPlan, predicate: (p: Price) => boolean) =>
  findFirstMap((plan: Plan) => (plan.id === planToID[paidPlan] ? pipe(plan.prices, findFirst(predicate)) : none))

export const findPriceByCurrency = (prices: Price[], selectedCurrency: Currency) =>
  pipe(
    prices,
    findFirst(price => isRight(price.currency) && price.currency.right === selectedCurrency)
  )

export const findCurrencyByPriceId = (plans: Plan[], priceId: string) =>
  pipe(
    plans,
    findFirstMap(pl =>
      pipe(
        pl.prices,
        findFirstMap(pr => (pr.id === priceId ? fromEither(pr.currency) : none))
      )
    )
  )
