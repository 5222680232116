import React from 'react'
import { useHistory } from 'react-router-dom'
import { shipIdentifiers } from '../../Domain/Ship'
import {
  VesselMasterDataWithPictureUrl,
  VesselProperties,
  PortVesselProperties,
  VesselSource,
  countryByMMSI,
  VesselJson,
  formatArrivalTime,
} from '../../Domain/Vessel'
import { useFromFetch } from '../../lib/useFromFetch'
import { InlineLoader, lightBackgroundInlineLoader } from '../../UI/Loader/InlineLoader'
import { CloseButton } from '../../UI/CloseButton'
import { Ticker } from '../../UI/Ticker/Ticker'
import { useTranslation } from '../../lib/i18n'
import styles from './VesselDetails.module.scss'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'
import { actions } from '../Traffic/localVesselStore'
import MaterialIcon from '../../UI/MaterialIcon'
import classnames from 'classnames'
import { useAuth0 } from '@auth0/auth0-react'
import { Pages, SHIPTRACKER_GLOBAL_REDIRECT_URL } from '../../constants'
import { fromNullable, Option, fold, map, none, some } from 'fp-ts/es6/Option'
import { pipe, constant } from 'fp-ts/es6/function'
import { isShowCase } from '../../config'
import { Country } from '../../Domain/Countries'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { getClickEventName } from '../../lib/mixpanel/eventNames'
import { Tab } from '../../Account/AccountTypeTabs/AccountTypeTabs'
import { LockIcon } from '../../UI/Icons/Lock'
import { mostlyPureYellow } from '../../styles/variables'
import { fetchMeasurementUnit, SystemOfMeasurement } from '../../Domain/User'
import { addUnit, convertToUnit, createMeasurementSystemConverter, metersToFeet } from '../../lib/convert-units'
import { RenderQueryResult } from '../../lib/RenderQueryResult'
import { selectedVesselDetailsPath } from '../helpers/paths'
import { useVesselProperties } from './useVesselProperties'
import { Port } from '../../Domain/Port'
import { useVesselDetails } from './useVesselDetails'
import { VesselDetailsPicture } from './VesselDetailsPicture'
import { VesselDetailsTitle } from './VesselDetailsTitle'
import { useTrackAddHandPicked } from './useTrackAddHandPicked'
import { useTrackSearchedVessel } from './useTrackSearchedVessel'

const loaderTheme = {
  ...lightBackgroundInlineLoader,
  loaderInline: styles.loaderInline,
  spinner: styles.lightBackgroundSpinner,
}
const closeButtonTheme = { button: styles.vesselDetailsCloseButton, icon: styles.vesselDetailsCloseButtonIcon }

const VesselDetailsDisabledAddButton: React.FC = () => {
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const { isAuthenticated } = useAuth0()
  const history = useHistory()

  return (
    <button className={classnames(styles.addButton, styles.addButtonDisabled)}>
      <MaterialIcon type="add" className={styles.icon} />
      {t('ShipTracker.Drawer.Actions.AddVessel')}
      <span
        className={styles.lockIcon}
        onClick={e => {
          e.stopPropagation()
          track(getClickEventName('Vessel view', 'Add vessel to list'))
          if (isAuthenticated) {
            history.push(`${Pages.ACCOUNT_SUBSCRIPTION}/${Tab.WEB}`)
          } else {
            history.push(Pages.CREATE_ACCOUNT)
          }
        }}
      >
        <LockIcon />
      </span>
    </button>
  )
}

const MoreVesselDetailsButton: React.FC<{ mmsi: number }> = ({ mmsi }) => {
  const history = useHistory()
  const { track } = useMixpanel()
  const { isAuthenticated } = useAuth0()
  const { isEnabled: isPortcalls } = useFeatureToggler(Feature.PORTCALLS)

  return (
    <button
      className={classnames(styles.portcallButton, { [styles.portcallButtonDisabled]: !isPortcalls })}
      onClick={() => {
        track(getClickEventName('Vessel view', 'More vessel info'))

        if (isPortcalls) {
          history.push(selectedVesselDetailsPath(mmsi))
        } else if (isAuthenticated) {
          history.push(`${Pages.ACCOUNT_SUBSCRIPTION}/${Tab.WEB}`)
        } else {
          history.push(Pages.CREATE_ACCOUNT)
        }
      }}
    >
      More vessel information
      {!isPortcalls && (
        <span className={styles.lockIcon}>
          <LockIcon color={mostlyPureYellow} />
        </span>
      )}
    </button>
  )
}

type VesselDetailsAddButtonProps = { onClick: () => void }
const VesselDetailsAddButton: React.FC<VesselDetailsAddButtonProps> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <button className={classnames(styles.addButton)} onClick={onClick}>
      <MaterialIcon type="add" className={styles.icon} />
      {t('ShipTracker.Drawer.Actions.AddVessel')}
    </button>
  )
}

const Spinner = () => (
  <div className={styles.spinner}>
    <InlineLoader theme={loaderTheme} />
    <span className={styles.spinnerText}>Loading</span>
  </div>
)

type DetailEntryProps = { label: string; value?: string | number }

const DetailEntry = ({ label, value }: DetailEntryProps) => (
  <>
    <span>{label}</span>
    <span>:</span>
    <span>
      <Ticker>{value || '-'}</Ticker>
    </span>
  </>
)

type DetailsProps = Readonly<
  {
    details: VesselMasterDataWithPictureUrl
  } & { country: Option<Country> }
>
const detailsIdentifiers = shipIdentifiers.filter(id => ['mmsi', 'imo'].includes(id))

function Details({ details, country }: DetailsProps) {
  const { t } = useTranslation()

  const { spireVesselType, spireSubtype, callSign, builtYear } = details

  return (
    <div className={styles.vesselDetailsContent}>
      <div className={styles.arrivalDetails}>
        <div className={classnames(styles.details)}>
          <DetailEntry
            label={t('ShipTracker.Map.Marker.Type')}
            value={spireVesselType || t('ShipTracker.Common.NotAvailable')}
          />
          <DetailEntry
            label={t('ShipTracker.Map.Marker.Subtype')}
            value={spireSubtype || t('ShipTracker.Common.NotAvailable')}
          />
          {detailsIdentifiers.map(identifier => (
            <DetailEntry key={identifier} label={identifier.toUpperCase()} value={details[identifier] || '-'} />
          ))}
          <DetailEntry label={t('ShipTracker.Map.Marker.CallSign')} value={callSign} />
          {pipe(
            country,
            fold(
              () => <DetailEntry label="Country" value={t('ShipTracker.Common.NotAvailable')} />,
              ({ name }) => <DetailEntry value={name} label={t('ShipTracker.Map.Marker.FlagState')} />
            )
          )}
          <DetailEntry label={t('ShipTracker.Map.Marker.BuiltYear')} value={builtYear} />
        </div>
      </div>
    </div>
  )
}

const NotAvailable = () => <span className={styles.notAvailable}>n/a</span>

const ArrivalDetailsEntry: React.FC<{ label: string; value: Option<string | React.ReactNode>; testId?: string }> = ({
  label,
  value,
  testId,
}) => (
  <>
    <span data-testid={testId ? `${testId}-label` : undefined}>{label}</span>
    <span>:</span>
    <span data-testid={testId ? `${testId}-value` : undefined}>
      <Ticker>
        {pipe(
          value,
          fold(constant(<NotAvailable />), value => <span className={styles.value}>{value}</span>)
        )}
      </Ticker>
    </span>
  </>
)

const UpgradeEntry: React.FC<{ label: string; onClick: () => void }> = ({ label, onClick }) => (
  <>
    <span>{label}</span>
    <span>:</span>
    <span className={styles.lockIcon} onClick={onClick}>
      <LockIcon />
    </span>
  </>
)

type PortcallDetailsProps = Pick<
  VesselProperties | PortVesselProperties,
  | 'destinationPort'
  | 'destination'
  | 'draught'
  | 'speedOverGround'
  | 'status'
  | 'reportedArrivalTime'
  | 'predictedArrivalTime'
  | 'actualArrivalTime'
>

const convertDraught = createMeasurementSystemConverter({
  [SystemOfMeasurement.Metric]: addUnit('m'),
  [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
})

function PortcallDetails({
  reportedArrivalTime,
  actualArrivalTime,
  predictedArrivalTime,
  destinationPort,
  destination,
  draught,
  speedOverGround,
  status,
}: PortcallDetailsProps) {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const { isEnabled: hasCalculatedETA } = useFeatureToggler(Feature.PORTCALLS)
  const history = useHistory()
  const { track } = useMixpanel()

  const reportedArrivalTimeOffset = reportedArrivalTime?.offset
  const predictedArrivalTimeOffset = predictedArrivalTime?.offset
  const actualArrivalTimeOffset = actualArrivalTime?.offset
  const measurementUnitQuery = useFromFetch(() => fetchMeasurementUnit(isAuthenticated))

  return (
    <RenderQueryResult
      query={measurementUnitQuery}
      LoadingComponent={<InlineLoader />}
      ErrorComponent={<div>Something went wrong, please try again later.</div>}
    >
      {measurementUnit => (
        <div className={styles.arrivalDetails}>
          <div className={styles.title}>Voyage information</div>
          <div className={classnames(styles.details, styles.shiptracker)}>
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.DestinationPort')}
              value={fromNullable(destinationPort)}
            />
            {isShowCase && (
              <ArrivalDetailsEntry
                label={t('ShipTracker.Map.Marker.TerminalOrBerth')}
                value={fromNullable(destination)}
              />
            )}
            <ArrivalDetailsEntry
              label={t(isShowCase ? 'ShipTracker.Map.Marker.ETAPBPReported' : 'ShipTracker.Map.Marker.ETAPortReported')}
              value={pipe(
                fromNullable(reportedArrivalTime?.value),
                map(time => formatArrivalTime(time, reportedArrivalTimeOffset))
              )}
            />
            {actualArrivalTime ? (
              <ArrivalDetailsEntry
                label={t(actualArrivalTime.labelKey)}
                value={some(formatArrivalTime(actualArrivalTime.value, actualArrivalTimeOffset))}
              />
            ) : hasCalculatedETA ? (
              <ArrivalDetailsEntry
                label={t(
                  isShowCase ? 'ShipTracker.Map.Marker.ETAPBPCalculated' : 'ShipTracker.Map.Marker.ETAPortPredicted'
                )}
                value={pipe(
                  fromNullable(predictedArrivalTime?.value),
                  map(time => formatArrivalTime(time, predictedArrivalTimeOffset))
                )}
                testId="ETAPBPCalculated"
              />
            ) : (
              <UpgradeEntry
                label={t(
                  isShowCase ? 'ShipTracker.Map.Marker.ETAPBPCalculated' : 'ShipTracker.Map.Marker.ETAPortPredicted'
                )}
                onClick={() => {
                  track(getClickEventName('Vessel view', 'Calculated ETAs'))
                  isShowCase
                    ? window.location.assign(SHIPTRACKER_GLOBAL_REDIRECT_URL(Pages.WEB_SUBSCRIPTIONS))
                    : history.push(Pages.WEB_SUBSCRIPTIONS)
                }}
              />
            )}
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.Draught')}
              value={pipe(
                fromNullable(draught),
                map(draught => convertDraught(draught, measurementUnit))
              )}
            />
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.Speed')}
              value={pipe(
                fromNullable(speedOverGround),
                map(speedOverGround => `${speedOverGround} kts`)
              )}
            />
            <ArrivalDetailsEntry label="Vessel Status" value={fromNullable(status)} testId="PortcallDetailsStatus" />
          </div>
          <div className={styles.title}>Vessel information</div>
        </div>
      )}
    </RenderQueryResult>
  )
}

type TrafficDetailsProps = Pick<
  VesselProperties,
  'destinationPort' | 'destination' | 'draught' | 'speedOverGround' | 'status' | 'reportedArrivalTime'
>
function TrafficDetails({
  reportedArrivalTime,
  destinationPort,
  destination,
  draught,
  speedOverGround,
  status,
}: TrafficDetailsProps) {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()

  const measurementUnitQuery = useFromFetch(() => fetchMeasurementUnit(isAuthenticated))

  const reportedArrivalTimeOffset = reportedArrivalTime?.offset

  return (
    <RenderQueryResult
      query={measurementUnitQuery}
      LoadingComponent={<InlineLoader />}
      ErrorComponent={<div>Something went wrong, please try again later.</div>}
    >
      {measurementUnit => (
        <div className={styles.arrivalDetails}>
          <div className={styles.title}>Voyage information</div>
          <div className={classnames(styles.details, styles.shiptracker)}>
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.DestinationPort')}
              value={fromNullable(destinationPort)}
            />
            {isShowCase && (
              <ArrivalDetailsEntry
                label={t('ShipTracker.Map.Marker.TerminalOrBerth')}
                value={fromNullable(destination)}
              />
            )}
            <ArrivalDetailsEntry
              label={t(isShowCase ? 'ShipTracker.Map.Marker.ETAPBPReported' : 'ShipTracker.Map.Marker.ETAPortReported')}
              value={pipe(
                fromNullable(reportedArrivalTime?.value),
                map(time => formatArrivalTime(time, reportedArrivalTimeOffset))
              )}
            />
            <ArrivalDetailsEntry
              label={t(
                isShowCase ? 'ShipTracker.Map.Marker.ETAPBPCalculated' : 'ShipTracker.Map.Marker.ETAPortPredicted'
              )}
              value={none}
              testId="ETAPBPCalculated"
            />
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.Draught')}
              value={pipe(
                fromNullable(draught),
                map(draught => convertDraught(draught, measurementUnit))
              )}
            />
            <ArrivalDetailsEntry
              label={t('ShipTracker.Map.Marker.Speed')}
              value={pipe(
                fromNullable(speedOverGround),
                map(speedOverGround => `${speedOverGround} kts`)
              )}
            />
            <ArrivalDetailsEntry label="Vessel Status" value={fromNullable(status)} testId="TrafficDetailsStatus" />
          </div>
          <div className={styles.title}>Vessel information</div>
        </div>
      )}
    </RenderQueryResult>
  )
}

export function VesselDetails({ vesselData, port }: { vesselData: VesselJson; port?: Port }) {
  const { isEnabled: canAddVessels } = useFeatureToggler(Feature.HANDPICKED_LIST)
  const { mmsi } = vesselData
  const properties = useVesselProperties(vesselData, port)
  const details = useVesselDetails(mmsi)
  const history = useHistory()

  useTrackAddHandPicked(properties)
  useTrackSearchedVessel(properties)

  if (details === undefined || properties === undefined) {
    return <Spinner />
  }

  const {
    shipName,
    destination,
    destinationPortName,
    draught,
    speedOverGround,
    status,
    source,
    reportedArrivalTime,
    predictedArrivalTime,
    actualArrivalTime,
  } = properties

  const country = 'country' in properties ? properties.country : countryByMMSI(mmsi)

  const destinationPort = destinationPortName ?? properties.destinationPort

  return (
    <>
      <VesselDetailsPicture pictureUrl={details.pictureUrl} />
      <VesselDetailsTitle title={shipName} country={country} />
      <div className={styles.vesselDetailsContent}>
        <div className={styles.aisDetails}>
          {source === VesselSource.AIS ? (
            <TrafficDetails
              destinationPort={destinationPort}
              destination={destination}
              draught={draught}
              speedOverGround={speedOverGround}
              status={status}
              reportedArrivalTime={reportedArrivalTime}
            />
          ) : (
            <PortcallDetails
              reportedArrivalTime={reportedArrivalTime}
              predictedArrivalTime={predictedArrivalTime}
              actualArrivalTime={actualArrivalTime}
              destinationPort={destinationPort}
              destination={destination}
              draught={draught}
              speedOverGround={speedOverGround}
              status={status}
            />
          )}
        </div>
      </div>
      <CloseButton onClose={() => history.push(Pages.MAP)} theme={closeButtonTheme} />
      <Details country={country} details={details} />
      <div className={styles.footer}>
        {!isShowCase && <MoreVesselDetailsButton mmsi={mmsi} />}
        {source === VesselSource.AIS &&
          (canAddVessels ? (
            <VesselDetailsAddButton onClick={() => actions.addHandPicked(mmsi)} />
          ) : (
            <VesselDetailsDisabledAddButton />
          ))}
      </div>
    </>
  )
}
