import React from 'react'
import classnames from 'classnames'
import styles from './CreditsBundle.module.scss'
import { useTranslation } from '../../lib/i18n'

type FootNotesProps = { className?: string }
export function FootNotes({ className }: FootNotesProps) {
  const { t } = useTranslation()

  return (
    <ul className={classnames(styles.notes, className)}>
      <li className={styles.note}>
        <span className={styles.bullet}>(*) </span>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ShipTracker.Account.Subscription.ETAPer4Credits'),
          }}
        />
      </li>
      <li className={styles.note}>
        <span className={styles.bullet}>(**) </span>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ShipTracker.Account.Subscription.ETAPer40Credits'),
          }}
        />
      </li>
    </ul>
  )
}
