import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { ConnectToApiStepProps } from './ConnectToApiOnboarding.d'
import styles from './ConnectToApiOnboarding.module.scss'
import { object as YupObject, string as YupString } from 'yup'
import { InputError } from '../../Account/InputError'
import { useFromFetch } from '../../lib/useFromFetch'
import { store } from '../../dataStore'
import { addMetadataProduct, Metadata } from '../../Domain/User'
import { useAuth0 } from '@auth0/auth0-react'
import { FormikCompanyTypeDropDown } from '../../Account/SelectCompanyType/CompanyTypeDropDown'
import { useCallback } from 'react'
import { CompanyType } from '../../Domain/Company'
import { useEffect } from 'react'
import { useState } from 'react'
import { createUnlocode } from '../../Domain/Port'
import { PortsDropdown } from '../../Account/SelectPort/PortsDropdown'
import { FormikInputField } from '../../UI/FormikInputField/FormikInputField'
import { useTranslation } from '../../lib/i18n'
import { StepHeader, StepText, StepTitle } from '../../Flow/GenericStepElements'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'

type FormValues = {
  companyType: CompanyType | ''
  companyName: string
  email: string
  givenName: string
  familyName: string
  phoneNumber: string
  port: string
}

export const validationSchema = YupObject().shape({
  companyType: YupString()
    .oneOf(['port', 'terminal', 'freightforwarder', 'serviceprovider', 'operator', 'other'])
    .required('Required'),
  companyName: YupString().required('Required'),
  email: YupString().email().required('Required'),
  givenName: YupString().required('Required'),
  familyName: YupString().required('Required'),
  phoneNumber: YupString().required('Required'),
  port: YupString().required('Required'),
})

export const dataToFormValues = (email: string, metadata: Metadata | undefined): FormValues => ({
  email,
  companyType: metadata?.company?.companyType ?? '',
  companyName: metadata?.company?.name ?? '',
  givenName: metadata?.givenName ?? '',
  familyName: metadata?.familyName ?? '',
  phoneNumber: metadata?.phoneNumber ?? '',
  port: metadata?.defaultPort ?? '',
})

export const StepProfile: React.FC<ConnectToApiStepProps> = ({ onNextStep, onPrevStep }) => {
  const { t } = useTranslation()
  const { setUserProperties, register } = useMixpanel()
  const { isLoading: isMetadataLoading, data: metadata } = useFromFetch(store.metadata.fetch)
  const { isLoading: isAuth0Loading, user } = useAuth0()
  const [initialValues, setInitialValues] = useState<FormValues>()
  const history = useHistory()

  useEffect(() => {
    if (!isMetadataLoading && !isAuth0Loading && user?.email) {
      setInitialValues(dataToFormValues(user.email, metadata))
    }
  }, [isMetadataLoading, isAuth0Loading, user, metadata])

  const handleOnSubmit = useCallback(
    async (values: FormValues) => {
      const products = await addMetadataProduct('Shiptracker')
      await store.metadata.update({
        accountType: 'business',
        company:
          values.companyType !== ''
            ? {
                name: values.companyName,
                companyType: values.companyType,
              }
            : undefined,
        givenName: values.givenName,
        familyName: values.familyName,
        name: `${values.givenName} ${values.familyName}`,
        phoneNumber: values.phoneNumber,
        defaultPort: createUnlocode(values.port),
        onboardingGuiStatus: 'completed',
        products,
      })
      const trackingProperties = {
        purpose: 'business',
        companyName: values.companyName,
        port: values.port,
        products,
      }
      setUserProperties(trackingProperties)
      register(trackingProperties)
      onNextStep()
    },
    [onNextStep, register, setUserProperties]
  )

  return (
    <>
      <StepHeader onBackButton={onPrevStep} onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>{t('ShipTracker.DeveloperPortal.ConnectToApi.Profile.Title')}</StepTitle>
      <StepText>{t('ShipTracker.DeveloperPortal.ConnectToApi.Profile.Description')}</StepText>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleOnSubmit}
        >
          {({ isValid, isSubmitting, errors, touched, handleSubmit, handleChange }) => {
            return (
              <div className={styles.form}>
                <div className={styles.inputField}>
                  <FormikInputField type="text" name="givenName" label="First name" />
                </div>
                <div className={styles.inputField}>
                  <FormikInputField type="text" name="familyName" label="Last name" />
                </div>
                <div className={styles.inputField}>
                  <FormikInputField type="email" name="email" label="E-mail address" disabled readOnly />
                </div>
                <div className={styles.inputField}>
                  <FormikInputField type="tel" name="phoneNumber" label="Phone number" />
                </div>
                <div className={styles.inputField}>
                  <FormikInputField type="text" name="companyName" label="Company name" />
                </div>
                <div className={styles.inputField}>
                  <FormikCompanyTypeDropDown name="companyType" placeHolder="Company type">
                    <div className={styles.label}>Company type</div>
                  </FormikCompanyTypeDropDown>
                </div>
                <div className={styles.inputField}>
                  <PortsDropdown
                    port={metadata?.defaultPort ?? ''}
                    className={styles.portsDropdown}
                    hasErrors={'port' in errors && 'port' in touched}
                    handleSelect={port => {
                      handleChange('port')(port ?? '')
                    }}
                  />
                  <ErrorMessage name="port" component={InputError} />
                </div>
                <CtaButton
                  className={styles.submitButton}
                  onClick={() => handleSubmit()}
                  isDisabled={!isValid || isSubmitting}
                >
                  {t('ShipTracker.DeveloperPortal.ConnectToApi.Profile.SelectCreditsBundle')}
                </CtaButton>
              </div>
            )
          }}
        </Formik>
      )}
    </>
  )
}
