import { some, none, Option } from 'fp-ts/es6/Option'
import { API_1000_PLAN_ID, API_100_PLAN_ID, API_5000_PLAN_ID } from './../../config'
import { Plan, PlanJson, Price } from './Plan'

export type ApiPlan = Plan & {
  credits: number
}

export type SelectedApiPlan = Pick<ApiPlan, 'name' | 'id'> & { price: Price }

export enum ApiPlanType {
  API_100 = 'API-100',
  API_1000 = 'API-1000',
  API_5000 = 'API-5000',
}

export const isApiPlan = (plan: PlanJson) => plan.prices.length && plan.prices[0].priceType === 'oneTime'

const apiPlanTypes = Object.values(ApiPlanType).map(String)

export const isApiPlanType = (planType: string): planType is ApiPlanType => apiPlanTypes.includes(planType)

export const apiPlanFromId = (id: string): Option<ApiPlanType> => {
  switch (id) {
    case API_100_PLAN_ID:
      return some(ApiPlanType.API_100)
    case API_1000_PLAN_ID:
      return some(ApiPlanType.API_1000)
    case API_5000_PLAN_ID:
      return some(ApiPlanType.API_5000)
    default:
      return none
  }
}
