import { useMixpanel } from './../mixpanel/MixpanelContext'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useRedirectToPath = (shouldRedirect: boolean, path: string, trackedEvent?: string) => {
  const history = useHistory()
  const location = useLocation()
  const { track } = useMixpanel()

  useEffect(() => {
    if (shouldRedirect && location.pathname !== path) {
      history.replace(`${path}`)

      if (trackedEvent) {
        track(trackedEvent)
      }
    }
  })
}
