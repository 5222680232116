import { useState } from 'react'
import { useElements, useStripe, CardNumberElement } from '@stripe/react-stripe-js'
import {
  Stripe,
  StripeElements,
  StripeElementChangeEvent,
  StripeElementType,
  StripeError,
  PaymentMethod,
  PaymentIntent,
} from '@stripe/stripe-js'

import { sequenceT } from 'fp-ts/es6/Apply'
import { fromNullable, option, fold } from 'fp-ts/es6/Option'
import { pipe, identity } from 'fp-ts/es6/function'

export function useStripeHooks() {
  const bothSome = sequenceT(option)

  return bothSome(fromNullable(useStripe()), fromNullable(useElements()))
}

const inputTypes = ['cardNumber', 'cardCvc', 'cardExpiry'] as const

const isInputType = (type: StripeElementType): type is InputType => inputTypes.includes(type as any)

type InputType = typeof inputTypes[number]

type FormErrors = Partial<Record<InputType, StripeError>>

type FormComplete = Record<InputType, boolean>

type FormState = {
  errors: FormErrors
  completed: FormComplete
}

const initialFormState: FormState = {
  errors: {},
  completed: {
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  },
}

export type CreatePaymentMethod = () => Promise<PaymentMethod>
export type ConfirmCardPayment = (clientSecret: string) => Promise<PaymentIntent>

export type HandleCreatePaymentMethod = (
  createPaymentMethod: CreatePaymentMethod,
  confirmPaymentMethod: ConfirmCardPayment
) => void

type UseStripeFormArgs = {
  stripe: Stripe
  elements: StripeElements
  handleCreatePaymentMethod: HandleCreatePaymentMethod
}

export function useStripeForm({ stripe, elements, handleCreatePaymentMethod }: UseStripeFormArgs) {
  const [formState, setFormState] = useState<FormState>(initialFormState)

  const { errors, completed } = formState

  const isValid = Object.values(errors).every(v => v === undefined) && Object.values(completed).every(identity)

  function handleChange({ elementType, error, empty, complete }: StripeElementChangeEvent) {
    if (isInputType(elementType)) {
      if (error) {
        setFormState({
          completed: { ...completed, [elementType]: false },
          errors: { ...errors, [elementType]: error },
        })
      } else {
        setFormState({
          completed: { ...completed, [elementType]: !empty && complete },
          errors: { ...errors, [elementType]: undefined },
        })
      }
    }
  }

  function handleSubmit() {
    pipe(
      fromNullable(elements.getElement(CardNumberElement)),
      fold(
        () => {
          // show error Toast
        },
        async cardNumberElement => {
          handleCreatePaymentMethod(
            async () => {
              const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
              })

              if (error) {
                throw error
              }

              /*
               * stripe.createPaymentMethod(paymentMethodData) returns a Promise which resolves with a result object.
               * This object has either:
               *   result.paymentMethod: a PaymentMethod was created successfully.
               *   result.error: there was an error. This includes client-side validation errors.
               */
              if (!paymentMethod) {
                throw new Error('Received unexpected result')
              }

              return paymentMethod
            },
            async clientSecret => {
              const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret)

              if (error) {
                throw error
              }

              if (!paymentIntent) {
                throw new Error('Received unexpected result')
              }

              return paymentIntent
            }
          )
        }
      )
    )
  }

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
  }
}
