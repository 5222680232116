import React from 'react'
import classnames from 'classnames'
import { useTranslation } from '../../lib/i18n'
import styles from './AccountTypeTabs.module.scss'

export enum Tab {
  API = 'api',
  WEB = 'web',
}

export const isApiTab = (tab: Tab) => tab === Tab.API

type AccountTypeTabsProps = { tab: Tab; handleSelectTab: (tab: Tab) => void }

export function AccountTypeTabs({ tab, handleSelectTab }: AccountTypeTabsProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.accountTypeTabs}>
      <button
        className={classnames(styles.accountTypeTabsButton, { [styles.isSelected]: tab === Tab.API })}
        onClick={() => handleSelectTab(Tab.API)}
      >
        {t('ShipTracker.Account.Subscription.ApiTab')}
      </button>
      <button
        className={classnames(styles.accountTypeTabsButton, { [styles.isSelected]: tab === Tab.WEB })}
        onClick={() => handleSelectTab(Tab.WEB)}
      >
        {t('ShipTracker.Account.Subscription.WebTab')}
      </button>
    </div>
  )
}
