import { useEffect, useState } from 'react'
import { from } from 'rxjs'
import { fetchPublicVesselDetails, fetchVesselDetails } from '../../Api/Vessel/fetchDetails'
import { isShowCase } from '../../config'
import { VesselMasterDataWithPictureUrl } from '../../Domain/Vessel'
import { useFeatureToggler, Feature } from '../../lib/hooks/useFeatureToggler'

export function useVesselDetails(mmsi: number) {
  const { isEnabled: hasDetailedAccess } = useFeatureToggler(Feature.PORTCALLS)
  const [details, setDetails] = useState<VesselMasterDataWithPictureUrl>()

  useEffect(() => {
    setDetails(undefined)

    const subscription = from(
      !isShowCase && hasDetailedAccess ? fetchVesselDetails(`MMSI${mmsi}`) : fetchPublicVesselDetails(`MMSI${mmsi}`)
    ).subscribe(setDetails)

    return subscription.unsubscribe.bind(subscription)
  }, [mmsi, hasDetailedAccess])

  return details
}
