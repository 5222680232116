import React, { useCallback, useState } from 'react'
import { MenuWrapper, LeftMenu, RightMenu } from './Menu'
import styles from './Header.module.scss'
import { MenuNavigation } from './MenuNavigation/MenuNavigation'
import { useHistory } from 'react-router-dom'
import { paramsFromShipIdentifiers } from '../Domain/Ship'
import { VesselSearchResult } from '../Domain/VesselSearchResult'
import { useMixpanel } from '../lib/mixpanel/MixpanelContext'
import { fetchVesselStatus } from '../Api/Vessel/fetchStatus'
import { trackError } from '../lib/trackError'
import { toError } from 'fp-ts/lib/Either'
import { trafficVesselFromJson, vesselDestinationFromProperties } from '../Domain/Vessel'
import { pipe, constant } from 'fp-ts/es6/function'
import { getOrElse } from 'fp-ts/es6/Option'
import { Pages } from '../constants'

type HeaderProps = {
  isSearchHidden?: boolean
  handleSearchResult?: (result: VesselSearchResult, query: string) => void
}

const Header = ({ handleSearchResult }: HeaderProps) => {
  const { track, incrementUserProperty } = useMixpanel()
  const [isMenuNavigationOpen, setMenuNavigationOpen] = useState(false)

  const handleClose = useCallback(() => setMenuNavigationOpen(false), [])

  const history = useHistory()

  const handleSearchResultCallback = useCallback(
    async (result, searchTerm) => {
      if (handleSearchResult) {
        return handleSearchResult(result, searchTerm)
      }

      try {
        const { properties } = await fetchVesselStatus(result.ship.mmsi).then(trafficVesselFromJson)
        track('Search', {
          searchTerm,
          destination: pipe(vesselDestinationFromProperties(properties), getOrElse(constant('n/a'))),
        })
        incrementUserProperty('totalNumberOfSearches', 1)
      } catch (e) {
        trackError(toError(e))
      }

      return history.push(`${Pages.MAIN}?${paramsFromShipIdentifiers(result.ship)}`)
    },
    [handleSearchResult, history, incrementUserProperty, track]
  )

  return (
    <header className={styles.header}>
      <MenuWrapper>
        <LeftMenu isMenuNavigationOpen={isMenuNavigationOpen} setMenuNavigationOpen={setMenuNavigationOpen} />
        <RightMenu handleSearchResult={handleSearchResultCallback} isMenuNavigationOpen={isMenuNavigationOpen} />
      </MenuWrapper>
      <MenuNavigation isOpen={isMenuNavigationOpen} onClose={handleClose} />
    </header>
  )
}

export default Header
