import React, { PropsWithChildren } from 'react'
import { ApiPlan } from '../../Domain/Subscription/ApiPlan'
import {
  PlanOption,
  PlanOptionHeader,
  PlanOptionPrice,
  PlanOptionFeatures,
  PlanOptionFeaturesList,
  FeatureItem,
} from '../../UI/Plan/Plan'

import { formattedPrice, formattedCreditPrice } from '../helpers'
import { pipe } from 'fp-ts/es6/function'
import { fold } from 'fp-ts/es6/Option'
import { useTranslation } from '../../lib/i18n'
import { SubmitButton } from '../AccountPage'
import { Currency, findPriceByCurrency } from '../../Domain/Subscription/Plan'

type CreditsBundleProps = PropsWithChildren<{
  plan: ApiPlan
  currency: Currency
  handleSubmit: () => void
  isDimmed?: boolean
}>

export function BuyCreditsBundle({ currency, plan, isDimmed, handleSubmit }: CreditsBundleProps) {
  const { t } = useTranslation()

  return (
    <CreditsBundle plan={plan} currency={currency} isDimmed={isDimmed}>
      <SubmitButton handleSubmit={handleSubmit}>{t('ShipTracker.Account.CreditBundle.Buy')}</SubmitButton>
    </CreditsBundle>
  )
}

export function CreditsBundle({
  currency: selectedCurrency,
  plan: { name, credits, prices },
  isDimmed,
  children,
}: Omit<CreditsBundleProps, 'handleSubmit'>) {
  const { t } = useTranslation()
  const price = findPriceByCurrency(prices, selectedCurrency)

  return (
    <PlanOption isDimmed={isDimmed}>
      <PlanOptionHeader
        title={name || t('ShipTracker.Common.NotAvailable')}
        caption={`
buy ${credits} credits bundle
`}
      />
      <PlanOptionPrice
        price={pipe(
          price,
          fold(() => t('ShipTracker.Common.NotAvailable'), formattedPrice)
        )}
      />
      <PlanOptionFeatures>
        <PlanOptionFeaturesList>
          <FeatureItem>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'ShipTracker.Account.CreditBundle.Price',
                  pipe(
                    price,
                    fold(
                      () => t('ShipTracker.Common.NotAvailable'),
                      price => formattedCreditPrice(price, credits)
                    )
                  )
                ),
              }}
            />
          </FeatureItem>
          <FeatureItem>{t('ShipTracker.Account.CreditBundle.ETAPerVessel')}</FeatureItem>
          <FeatureItem>{t('ShipTracker.Account.CreditBundle.ETAsPerPort')}</FeatureItem>
          <FeatureItem>{t('ShipTracker.Account.CreditBundle.ATAPort')}</FeatureItem>
          <FeatureItem>{t('ShipTracker.Account.CreditBundle.OnlineCustomerSupport')}</FeatureItem>
          <FeatureItem>{t('ShipTracker.Account.CreditBundle.AutoTopUp')}</FeatureItem>
        </PlanOptionFeaturesList>
        {children}
      </PlanOptionFeatures>
    </PlanOption>
  )
}
