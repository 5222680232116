import React, { useState, useEffect, useContext, createContext, PropsWithChildren, useCallback } from 'react'
import { WebAuth } from 'auth0-js'
import {
  VerifyEmailCache,
  getVerifyEmailCache,
  saveVerifyEmailCache,
  VerificationFlow,
  clearVerifyEmailCache,
} from '../lib/verifyEmailStorage'
import { getAuth0ErrorDescription, webAuthConfig } from '../Account/AuthenticationForm/AuthenticationForm'
import { KnownError, isPayload } from './useHandleAuthError'
import { useAuth0 } from '@auth0/auth0-react'
import { trackError } from '../lib/trackError'

export enum VerificationStatus {
  None,
  Unverified,
  Verified,
}

type State = {
  isLoadingToken: boolean
  status: VerificationStatus
  setStatus: (status: VerificationStatus) => void
  cache: VerifyEmailCache | null
  setCache: (cache: VerifyEmailCache) => void
  clearCache: () => void
}

const Context = createContext<State>({} as State)

export const useAuthFlowContext = () => useContext<State>(Context)

const legacyWebAuth0Client = new WebAuth(webAuthConfig)

export function AuthFlowProvider({
  children,
  initialStatus = VerificationStatus.None,
  verificationFlow,
}: PropsWithChildren<{ initialStatus?: VerificationStatus; verificationFlow: VerificationFlow }>) {
  const { getAccessTokenSilently } = useAuth0()
  const [isLoadingToken, setIsLoadingToken] = useState(false)
  const [cache, setCache] = useState<VerifyEmailCache | null>(getVerifyEmailCache())
  const [status, setStatus] = useState(
    cache === null
      ? initialStatus
      : cache.isVerified === false
      ? VerificationStatus.Unverified
      : VerificationStatus.Verified
  )

  const updateCache = useCallback((cache: VerifyEmailCache) => {
    saveVerifyEmailCache(cache)
    setCache(cache)
    setStatus(cache.isVerified === false ? VerificationStatus.Unverified : VerificationStatus.Verified)
  }, [])

  useEffect(() => {
    if (cache !== null && cache.isVerified && isLoadingToken) {
      clearVerifyEmailCache()
    }
  }, [cache, isLoadingToken])

  useEffect(() => {
    legacyWebAuth0Client.parseHash((error, data) => {
      if (error && error.errorDescription) {
        const payload = getAuth0ErrorDescription(error)

        if (isPayload(payload)) {
          switch (KnownError[payload.type]) {
            case KnownError.EMAIL_VERIFICATION_ERROR: {
              const cache: VerifyEmailCache = {
                isVerified: false,
                email: payload.email,
                verificationFlow,
              }

              updateCache(cache)
            }
          }
        }
      }

      if (data && 'accessToken' in data) {
        setIsLoadingToken(true)
        getAccessTokenSilently().catch(error => {
          setIsLoadingToken(false)
          trackError(error)
        })
      }
    })
  }, [updateCache, getAccessTokenSilently, verificationFlow])

  return (
    <Context.Provider
      value={{
        status,
        isLoadingToken,
        setStatus,
        cache,
        setCache: updateCache,
        clearCache: () => {
          setCache(null)
          clearVerifyEmailCache()
          setStatus(VerificationStatus.None)
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
