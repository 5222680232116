import { GenericStepProps } from './../../Flow/GenericStepElements.d'
import { Currency } from '../../Domain/Subscription/Plan'
import { ApiPlanType, SelectedApiPlan } from './../../Domain/Subscription/ApiPlan'

export enum StepName {
  AUTHENTICATION = 'authentication',
  EMAIL_VERIFICATION = 'email-verification',
  LEGAL_TERMS = 'legal-terms',
  PROFILE = 'profile',
  PLAN_SELECTION = 'plan-selection',
  PAYMENT_DETAILS = 'payment-details',
  GENERATE_API = 'generate-api',
}

type InitialPlanSelection = { type: ApiPlanType; currency: Currency }

type SelectedPlanProps = {
  selectedPlan?: SelectedApiPlan
  setSelectedPlan: (plan: SelectedApiPlan) => void
  initialPlanSelection: InitialPlanSelection
}

export type ConnectToApiStepProps = GenericStepProps<StepName> & SelectedPlanProps
