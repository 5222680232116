import { useMixpanel } from '../lib/mixpanel/MixpanelContext'
import { useCallback } from 'react'
import { AppState } from '@auth0/auth0-react'

export enum Action {
  LOGIN = 'login',
}

type HandleRedirectCallbackProps = { children: (callback: (state: AppState) => void) => JSX.Element }

export function HandleRedirectCallback({ children }: HandleRedirectCallbackProps) {
  const { track } = useMixpanel()

  const handleRedirectCallback = useCallback(
    (appState: AppState) => {
      if ('action' in appState && appState.action === Action.LOGIN) {
        track('User Login')
      }
    },
    [track]
  )

  return children(handleRedirectCallback)
}
