import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from '../../lib/i18n'
import { PlanSummary } from '../SubscriptionPlan/SubscriptionPlan'
import { useHistory, Link } from 'react-router-dom'
import {
  isPaidPlan,
  isPricePeriod,
  PaidPlan,
  Price,
  findPaidPlanPrice,
  Period,
  Currency,
} from '../../Domain/Subscription/Plan'
import { pipe, constNull } from 'fp-ts/es6/function'
import { fold, Option, none, some, isSome } from 'fp-ts/es6/Option'
import { Pages, SECOND } from '../../constants'
import { UpdatedSubscription, CreateSubscriptionError, SubscriptionTokenError, DURATION } from '../../UI/Toast'
import { StripeCreditCardForm } from '../CreditCardForm'
import styles from './UpdateSubscription.module.scss'
import { trackError } from '../../lib/trackError'
import { isRight, toError } from 'fp-ts/es6/Either'
import { useAccountState } from '../AccountState'
import { CreatePaymentMethod } from '../CreditCardForm/useStripe'
import { useAuth0 } from '@auth0/auth0-react'
import { parseJwt } from '../../lib/parseJWT'
import { defer, timer } from 'rxjs'
import { retryWhen, map, delayWhen } from 'rxjs/operators'
import { toPromise } from '../../lib/utils'
import { InlineLoader, InlineLoaderTheme } from '../../UI/Loader/InlineLoader'
import { Feature } from '../../lib/hooks/useFeatureToggler'

// const LOGIN_REQUIRED_MESSAGE = 'Login required'

const inlineLoaderTheme: InlineLoaderTheme = {
  spinner: styles.lightBackgroundSpinner,
  loaderInline: styles.loaderInline,
}

type UpdateSubscriptionContentProps = { price: Price; type: PaidPlan }

class NotUpdatedError extends Error {}

class OAuthLoginError extends Error {}

export const waitForToken = (getToken: () => Promise<string>, hasScope: (scope: string) => boolean) => {
  let n = 0

  return toPromise(
    defer(getToken).pipe(
      map(token => {
        const { scope } = parseJwt(token)
        if (hasScope(scope)) {
          return token
        }

        throw new NotUpdatedError('not updated')
      }),
      retryWhen(errors => {
        return errors.pipe(
          map(error => {
            return error
          }),
          delayWhen(() => {
            n += 2
            return timer(n * SECOND)
          })
          // switchMap(error => {
          //   if (error instanceof NotUpdatedError) {
          //     return delayWhen(() => {
          //       n += 2
          //       return timer(n * SECOND)
          //     })
          //   }

          //   // Happens by default in incognito mode
          //   return 'message' in error && error.message === LOGIN_REQUIRED_MESSAGE
          //     ? throwError(new OAuthLoginError())
          //     : throwError(error)
          // })
        )
      })
    )
  )
}

function UpdateSubscriptionContent({ price, type }: UpdateSubscriptionContentProps) {
  const { getAccessTokenSilently } = useAuth0()
  const [, { createSubscription }] = useAccountState()
  const [processing, setProcessing] = useState<Option<string>>(none)
  const { t } = useTranslation()
  const history = useHistory()

  async function handleCreatePaymentMethod(createPaymentMethod: CreatePaymentMethod) {
    try {
      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.RetrievingPaymentDetails')))

      const paymentMethod = await createPaymentMethod()

      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.CreatingSubscription')))
      await createSubscription(price, paymentMethod)

      setProcessing(some(t('ShipTracker.Account.UpdateSubscription.UpdatingSystem')))

      // refresh token
      await waitForToken(
        () => getAccessTokenSilently({ ignoreCache: true }),
        scope => scope.includes(Feature.PORTCALLS)
      )

      toast.success(<UpdatedSubscription />, { autoClose: DURATION.MEDIUM })
      setProcessing(none)

      history.push(Pages.ACCOUNT)
    } catch (e) {
      setProcessing(none)

      if (e instanceof OAuthLoginError) {
        toast.error(<SubscriptionTokenError time={`${DURATION.LONG / 1000} seconds`} />, { autoClose: DURATION.LONG })

        return setTimeout(() => window.location.assign(`${window.location.origin}${Pages.MAIN}`), DURATION.LONG)
      }

      trackError(toError(e))
      toast.error(<CreateSubscriptionError />, { autoClose: DURATION.EXTRA_LONG })
    }
  }

  return (
    <>
      <div className={styles.updateSubscriptionPage}>
        <div className={styles.section}>
          <div className={styles.label}>{t('ShipTracker.Account.UpdateSubscription.IWantToChangeTo')}:</div>
          <PlanSummary price={price} />
        </div>
        <div className={styles.section}>
          <Link className={styles.chooseSubscription} to={Pages.ACCOUNT_SUBSCRIPTION}>
            {t('ShipTracker.Account.UpdateSubscription.ChooseOther')}
          </Link>
        </div>
        <div className={styles.section}>
          <h2 className={styles.title}>Payment Details</h2>
          <StripeCreditCardForm
            submitText={t('ShipTracker.Account.CreditCardForm.Submit')}
            isSubmitting={isSome(processing)}
            handleCreatePaymentMethod={handleCreatePaymentMethod}
          />
        </div>
      </div>
      {pipe(
        processing,
        fold(constNull, message => (
          <div className={styles.blocker}>
            <InlineLoader theme={inlineLoaderTheme} />
            <span>{message}</span>
          </div>
        ))
      )}
    </>
  )
}

type UpdateSubscriptionProps = { plan: PaidPlan; period: Period; currency: Currency }

const validParams = (plan: PaidPlan, period: Period) => isPaidPlan(plan) && isPricePeriod(period)

export function UpdateSubscription({ plan, period, currency }: UpdateSubscriptionProps) {
  const [{ plans }] = useAccountState()
  const history = useHistory()

  useEffect(() => {
    if (!validParams(plan, period)) {
      history.push(Pages.ACCOUNT_404)
    }
  }, [plan, period, history])

  return pipe(
    plans,
    findPaidPlanPrice(plan, p => p.period === period && isRight(p.currency) && p.currency.right === currency),
    fold(constNull, price => <UpdateSubscriptionContent type={plan} price={price} />)
  )
}
