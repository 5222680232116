import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import styles from './CopyPasteField.module.scss'
import { CtaButton } from '../CtaButton/CtaButton'
import classnames from 'classnames'

type CopyPasteFieldProps = {
  label: string
  value?: string
  placeholder?: string
  copyButtonName: string
  copyButtonClassName?: string
  isLoading?: boolean
}
export const CopyPasteField: React.FC<CopyPasteFieldProps> = ({
  label,
  value,
  placeholder,
  copyButtonName,
  copyButtonClassName,
  isLoading = false,
  children,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  const onCopyValue = useCallback(() => {
    const { current: textAreaEl } = ref
    if (value && textAreaEl) {
      navigator.clipboard.writeText(value).then(() => {
        textAreaEl.select()
      })
    }
  }, [ref, value])

  return (
    <div className={styles.copyPasteContainer}>
      <label className={styles.copyPasteLabel}>{label}</label>
      <div
        className={classNames(styles.copyPasteFieldWrapper, {
          [styles.copyPasteFieldWrapperEnabled]: !isLoading && !!value,
        })}
      >
        <textarea
          ref={ref}
          className={styles.copyPasteField}
          rows={4}
          readOnly
          value={!isLoading ? value ?? placeholder : ''}
        />
      </div>
      <div className={styles.copyPasteFooter}>
        {children}
        <CtaButton
          onClick={onCopyValue}
          className={classnames(styles.copyButton, copyButtonClassName)}
          isDisabled={isLoading || !value}
        >
          {copyButtonName}
        </CtaButton>
      </div>
    </div>
  )
}
