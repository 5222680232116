import React from 'react'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { mostlyPureYellow, navyBlue } from '../../styles/variables'

export const LockIcon = ({ color }: { color?: string }) => {
  const { isDarkMode } = useDarkMode()
  return (
    <svg width={12} height={17} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M6.516 0C7.886 0 8.996 1.108 9 2.478v2.73h1.8v11.544H0V5.208h1.8v-2.73A2.484 2.484 0 0 1 4.284 0ZM5.4 8.31a2.298 2.298 0 1 0 0 4.596 2.298 2.298 0 0 0 0-4.596Zm0 1.2a1.098 1.098 0 1 1 0 2.196 1.098 1.098 0 0 1 0-2.196ZM6.516 1.2H4.284c-.707 0-1.28.571-1.284 1.278v2.73h4.8v-2.73A1.284 1.284 0 0 0 6.516 1.2Z"
          id="a"
        />
      </defs>
      <use
        fill={color ?? isDarkMode ? mostlyPureYellow : navyBlue}
        xlinkHref="#a"
        transform="translate(.4)"
        fillRule="evenodd"
      />
    </svg>
  )
}
