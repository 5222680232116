import { BehaviorSubject } from 'rxjs'
import { none, Option, fromNullable } from 'fp-ts/es6/Option'
import { Country } from '../../Domain/Countries'
import { useState, useEffect } from 'react'

export const flagStateFilterSubject = new BehaviorSubject<Option<Country>>(none)

export function useFlagStateFilter() {
  const [state, setState] = useState<Option<Country>>(flagStateFilterSubject.value)

  useEffect(() => {
    const subscription = flagStateFilterSubject.subscribe(setState)

    return subscription.unsubscribe.bind(subscription)
  }, [])

  return {
    flagState: state,
    setFlagState: (country: Country | null) => flagStateFilterSubject.next(fromNullable(country)),
  }
}
