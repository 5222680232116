import React from 'react'
import classnames from 'classnames'
import {
  Pages,
  SHIPTRACKER_GLOBAL_REDIRECT_URL,
  SHIP_TRACKER_THEME_CSS,
  SHIP_TRACKER_THEME_DARK_CSS,
} from '../constants'
import Header from '../Header/Header'
import { useDarkMode } from '../lib/hooks/useDarkMode'
import styles from './GenericPage.module.scss'
import { useTranslation } from '../lib/i18n'
import { useMixpanel } from '../lib/mixpanel/MixpanelContext'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useMixpanelTrackExternalLink } from '../lib/hooks/useTracking'
import { isShowCase } from '../config'

export const AboutShipTrackerPage: React.FC = () => {
  const { isDarkMode } = useDarkMode()
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const linkRef = useMixpanelTrackExternalLink('ShipTracker Global')

  return (
    <main
      className={classnames(styles.genericPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <Header />
      <div className={classNames(styles.content, styles.formatTags)}>
        <h2>{t('ShipTracker.AboutShipTrackerPage.Title')}</h2>
        <p>
          PortXchange Shiptracker provides reliable ETA predictions accessible through web subscription or API. We
          combine historic voyage information, vessel characteristics, current position, heading, speed and weather in
          the Machine Learning model to give you access to the most accurate expected arrival times, approximately
          <strong>7000nm / 3 weeks in advance</strong>.
        </p>
        {isShowCase && (
          <p>
            The port of Rotterdam is the showcase port for Shiptracker with all functionalities unlocked. If you want to
            see vessel arrival times to other ports, go to the{' '}
            <a ref={linkRef} href={SHIPTRACKER_GLOBAL_REDIRECT_URL()} target="_blank" rel="noreferrer">
              global Shiptracker website
            </a>
            .
          </p>
        )}
        <p>
          You can find the list of ports where we can predict ETAs <Link to={Pages.AVAILABLE_PORTS}>here</Link>. Keep an
          eye on it, as we update it regularly.
        </p>
        <br />
        <h3>Get access to the ETA predictions via web subscription</h3>
        <p>Use our interactive web application to get ETA predictions and more.</p>
        <p>
          <strong>What will you get via web subscription?</strong>
          <ul>
            <li>ETA predictions</li>
            <li>Port call vessel list view</li>
            <li>Arrival notifications</li>
          </ul>
          <Link
            onClick={() => track('Click "Buy Web subscription"', { position: 'about' })}
            to={Pages.WEB_SUBSCRIPTIONS}
          >
            Start your free 14-day trial
          </Link>{' '}
          to unlock more functionalities.
        </p>
        <br />
        <h3>Consume ETA predictions via API</h3>
        <p>Integrate our ETA predictions into your existing infrastructure via the API, fast and easy.</p>
        <p>
          <strong>What will you get via API?</strong>
          <ul>
            <li>ETA predictions on a vessel level</li>
            <li>ETA predictions on a port level</li>
          </ul>
          Visit our Shiptracker{' '}
          <Link onClick={() => track('Click "Buy API credits"', { position: 'about' })} to={Pages.API_CREDITS_STORE}>
            ETA API creditstore
          </Link>
          , to learn more.
        </p>
      </div>
    </main>
  )
}
