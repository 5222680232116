import React, { useState, useEffect } from 'react'
import { PortsDropdown } from '../SelectPort/PortsDropdown'
import styles from './DefaultPort.module.scss'
import { useTranslation } from '../../lib/i18n'
import { createUnlocode } from '../../Domain/Port'
import { Formik, ErrorMessage } from 'formik'
import { object as YupObject, string as YupString } from 'yup'
import { InputError } from '../InputError'
import { useFromFetch } from '../../lib/useFromFetch'
import { InlineLoader } from '../../UI/Loader/InlineLoader'
import { PortcallVesselLegend, TrafficVesselLegend } from '../VesselLegend/VesselLegend'
import { SubmitButton } from '../AccountPage'
import { store } from '../../dataStore'
import { useHistory, Link } from 'react-router-dom'
import { Pages } from '../../constants'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'

type FormSchema = Readonly<{
  port: string
}>

const validationSchema = YupObject<FormSchema>().shape(
  {
    port: YupString().required('Required'),
  },
  []
)

export function DefaultPort() {
  const history = useHistory()
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const { data, isLoading } = useFromFetch(() => Promise.all([store.metadata.fetch(), store.ports.fetch()]))
  const [initialValues, setInitialValues] = useState<FormSchema>({
    port: '',
  })

  useEffect(() => {
    if (!isLoading && data) {
      const { defaultPort } = data[0]

      setInitialValues({ port: defaultPort || '' })
    }
  }, [isLoading, data])

  return (
    <>
      {isLoading && <InlineLoader />}
      {!isLoading && data && (
        <Formik
          enableReinitialize
          onSubmit={async ({ port }) => {
            await store.metadata.update({ defaultPort: createUnlocode(port) })
            track('Change Default Port', { port: createUnlocode(port) })
            history.push(Pages.MAIN)
          }}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, handleSubmit, errors, touched, dirty }) => {
            const handlePortChange = handleChange('port')

            const isDisabled = !dirty

            return (
              <>
                <div className={styles.defaultPortSection}>
                  <p className={styles.description}>
                    {t('ShipTracker.Account.DefaultPort.DefaultPortDescription', data[1].length)}
                  </p>
                  <div className={styles.portSection}>
                    <div className={styles.inputSection}>
                      <PortsDropdown
                        hasErrors={'port' in errors && 'port' in touched}
                        port={values.port}
                        handleSelect={port => {
                          handlePortChange(port || '')
                        }}
                      />

                      <ErrorMessage name="port" component={InputError} />
                    </div>
                    <Link className={styles.availablePortsLink} to={Pages.AVAILABLE_PORTS}>
                      {t('ShipTracker.Account.DefaultPort.ShowPortList')}
                    </Link>
                  </div>
                </div>

                <div className={styles.formActions}>
                  <SubmitButton disabled={isDisabled} handleSubmit={handleSubmit}>
                    {t('ShipTracker.Account.DefaultPort.ChangeDefaultPort')}
                  </SubmitButton>
                </div>
              </>
            )
          }}
        </Formik>
      )}
      <div className={styles.vesselLegend}>
        <div className={styles.vesselLegendEntry}>
          <PortcallVesselLegend />
        </div>
        <div className={styles.vesselLegendEntry}>
          <TrafficVesselLegend />
        </div>
      </div>
    </>
  )
}
