import classnames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'
import { Tab } from '../../Account/AccountTypeTabs/AccountTypeTabs'
import { Pages } from '../../constants'
import { useTranslation } from '../../lib/i18n'
import { getClickEventName } from '../../lib/mixpanel/eventNames'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { CloseButton, CloseButtonTheme } from '../../UI/CloseButton'
import { ControlStripButton, ControlStripEntry } from '../../UI/ControlStrip/ControlStrip'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import { BottomRightDropoutMenuDropper, DropoutMenuDropperTheme } from '../../UI/DropoutMenu/DropoutMenuDropper'
import MaterialIcon from '../../UI/MaterialIcon'
import styles from './Controls.module.scss'

const closeButtonTheme: CloseButtonTheme = { button: styles.closeButton, icon: styles.closeButtonIcon }

const menuTheme: DropoutMenuDropperTheme = {
  triangleBottom: styles.triangleBottom,
}

type MenuProps = { closeMenu: () => void }

export function AddDefaultportAndTrafficMenu({ closeMenu }: MenuProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const { track } = useMixpanel()

  return (
    <BottomRightDropoutMenuDropper hasTriangleSpace theme={menuTheme} className={styles.menu}>
      <CloseButton onClose={closeMenu} theme={closeButtonTheme} />
      <h3 className={styles.title}>{t('ShipTracker.Controls.AddDefaultPortAndTrafficTitle')}</h3>
      <p className={classnames(styles.text, styles.signupText)}>
        {t('ShipTracker.Controls.AddDefaultPortAndTrafficText')}
      </p>
      <button
        onClick={() => {
          track(getClickEventName('Map bar - Vessel list', 'Sign up'))
          history.push(Pages.CREATE_ACCOUNT)
        }}
        className={styles.signupButton}
      >
        {t('ShipTracker.Controls.AddDefaultPortAndTrafficCta')}
      </button>
      <p
        onClick={e => {
          e.preventDefault()
          track(getClickEventName('Map bar - Vessel list', 'Log in'))
          history.push(Pages.LOGIN)
        }}
        dangerouslySetInnerHTML={{ __html: t('ShipTracker.Controls.LoginText') }}
        className={classnames(styles.text, styles.loginText)}
      />
    </BottomRightDropoutMenuDropper>
  )
}

export const AddDefaultPortAndTrafficControl = () => {
  const { t } = useTranslation()
  const { track } = useMixpanel()

  return (
    <ControlStripEntry>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <ControlStripButton
              onClick={
                isOpen
                  ? closeMenu
                  : () => {
                      openMenu()
                      track(getClickEventName('Map bar', 'Vessel list'))
                    }
              }
            >
              <MaterialIcon className={styles.icon} type="gps_fixed" />
              {t('ShipTracker.Controls.AddDefaultPortAndTraffic')}
              <span className={classnames(styles.label, styles.upgrade)}>{t('ShipTracker.Controls.Signup')}</span>
            </ControlStripButton>
            {isOpen && <AddDefaultportAndTrafficMenu closeMenu={closeMenu} />}
          </>
        )}
      </DropoutMenu>
    </ControlStripEntry>
  )
}

export function UnlockPortAndTrafficMenu({ closeMenu }: MenuProps) {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <BottomRightDropoutMenuDropper hasTriangleSpace theme={menuTheme} className={styles.menu}>
      <CloseButton onClose={closeMenu} theme={closeButtonTheme} />
      <h3 className={styles.title}>{t('ShipTracker.Controls.UnlockPortAndTrafficTitle')}</h3>
      <p className={classnames(styles.text, styles.signupText)}>{t('ShipTracker.Controls.UnlockPortAndTrafficText')}</p>
      <button onClick={() => history.push(`${Pages.ACCOUNT_SUBSCRIPTION}/${Tab.WEB}`)} className={styles.signupButton}>
        {t('ShipTracker.Controls.UpgradeCta')}
      </button>
    </BottomRightDropoutMenuDropper>
  )
}

export const UnlockPortAndTrafficControl = () => {
  const { t } = useTranslation()
  const { track } = useMixpanel()

  return (
    <ControlStripEntry>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <ControlStripButton
              onClick={
                isOpen
                  ? closeMenu
                  : () => {
                      openMenu()
                      track(getClickEventName('Map bar', 'Vessel list'))
                    }
              }
            >
              <MaterialIcon className={styles.icon} type="gps_fixed" />
              {t('ShipTracker.Controls.AddDefaultPortAndTraffic')}
              <span className={classnames(styles.label, styles.upgrade)}>{t('ShipTracker.Controls.Upgrade')}</span>
            </ControlStripButton>
            {isOpen && <UnlockPortAndTrafficMenu closeMenu={closeMenu} />}
          </>
        )}
      </DropoutMenu>
    </ControlStripEntry>
  )
}
