import React, { PropsWithChildren, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import { Header, AccountMenuWrapper } from './AccountLayout'
import styles from './AccountPage.module.scss'
import { CloseButton, CloseButtonTheme } from '../UI/CloseButton'
import { useHistory } from 'react-router-dom'
import { Pages } from '../constants'
import { Nav } from './Nav/Nav'
import { useTranslation } from '../lib/i18n'
import { useAccountState } from './AccountState'
import { isOpen } from './helpers'

const closeButtonTheme: CloseButtonTheme = {
  button: styles.closeButton,
  icon: styles.closeButtonIcon,
}

type AccountPageLayoutProps = PropsWithChildren<{
  hasHiddenNav?: boolean
  hasPaidAccount: boolean
  hasDefaultPort?: boolean
}>

export const AccountPageLayout = ({
  children,
  hasPaidAccount,
  hasDefaultPort,
  hasHiddenNav = false,
}: AccountPageLayoutProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [{ isSideMenuOpen }] = useAccountState()
  const isMenuOpen = useMemo(() => isOpen(isSideMenuOpen), [isSideMenuOpen])

  useEffect(() => {
    if (hasDefaultPort === false) {
      history.push(Pages.CREATE_ACCOUNT)
    }
  }, [hasDefaultPort, history])

  return (
    <main className={styles.accountPage}>
      {isMenuOpen && <div className={styles.overlay} />}
      <AccountMenuWrapper>
        <Header />
        <h1 className={classnames(styles.accountFormTitle, styles.accountMenuTitle)}>
          {t('ShipTracker.Account.MyAccount')}
        </h1>
        {!hasHiddenNav && <Nav hasPaidAccount={hasPaidAccount} />}
      </AccountMenuWrapper>
      {children}
      {!hasHiddenNav && !isMenuOpen && (
        <CloseButton theme={closeButtonTheme} onClose={() => history.push(Pages.MAIN)} />
      )}
    </main>
  )
}

export const AccountPageTitle: React.FC<{ className?: string }> = ({ children, className }) => (
  <h2 className={classnames(styles.accountFormTitle, className)}>{children}</h2>
)

export const AccountPage = ({ children, sectionTitle }: PropsWithChildren<{ sectionTitle?: string }>) => (
  <div className={classnames(styles.accountMainContent, styles.accountForm)}>
    {sectionTitle && <AccountPageTitle className={styles.isPageTitle}>{sectionTitle}</AccountPageTitle>}
    {children}
  </div>
)

export const AccountForm = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={classnames(styles.accountForm, className)}>{children}</div>
)

export const AccountFormLabel = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={classnames(styles.label, className)}>{children}</div>
)

type SubmitButtonProps = PropsWithChildren<{ disabled?: boolean; handleSubmit?: () => void; className?: string }>

export const SubmitButton = ({ children, handleSubmit, disabled = false, className }: SubmitButtonProps) => (
  <button
    onClick={handleSubmit}
    disabled={disabled}
    className={classnames(styles.submitButton, className)}
    type="submit"
  >
    {children}
  </button>
)
