import classNames from 'classnames'
import React from 'react'
import styles from './GenericStepElements.module.scss'
import MaterialIcon from '../UI/MaterialIcon'
import { Link, LinkProps } from '../UI/Link/Link'
import classnames from 'classnames'
import { useTranslation } from '../lib/i18n'
import { CloseButton } from '../UI/CloseButton'

type StepHeaderProps = { onBackButton?: () => void; onClose: () => void }
export const StepHeader = ({ onBackButton, onClose }: StepHeaderProps) => (
  <div className={styles.stepHeader}>
    <div className={styles.stepHeaderButtonGroup}>
      <StepBackButton handleClick={onBackButton} />
      <StepCloseButton onClose={onClose} />
    </div>
  </div>
)

export const StepTitle: React.FC = ({ children }) => <h2 className={styles.stepTitle}>{children}</h2>

type StepBackButtonProps = { handleClick?: () => void }
export function StepBackButton({ handleClick }: StepBackButtonProps) {
  const { t } = useTranslation()
  return (
    <button
      className={classNames(styles.stepHeaderBackButton, {
        [styles.stepHeaderBackButtonDisabled]: handleClick === undefined,
      })}
      onClick={handleClick}
    >
      <MaterialIcon type="chevron_left" className={styles.backIcon} />
      {t('ShipTracker.Common.Back')}
    </button>
  )
}

//     onClose={() => history.push(Pages.MAIN)}
type StepCloseButtonProps = { onClose: () => void }
const StepCloseButton: React.FC<StepCloseButtonProps> = ({ onClose }) => (
  <CloseButton
    theme={{ button: styles.stepHeaderCloseButton, icon: styles.stepHeaderCloseButtonIcon }}
    onClose={onClose}
  />
)

export const StepSubHeader: React.FC<{ className?: string }> = ({ className, children }) => (
  <h3 className={classnames(styles.stepSubHeader, className)}>{children}</h3>
)

export const StepText: React.FC<React.HTMLProps<HTMLParagraphElement>> = ({ children, className, ...props }) => (
  <p className={classnames(styles.text, className)} {...props}>
    {children}
  </p>
)

export const StepContent: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, className, ...props }) => (
  <div className={classnames(styles.text, className)} {...props}>
    {children}
  </div>
)

export const StepFooter: React.FC = ({ children }) => <div className={styles.stepFooter}>{children}</div>

export const StepLink: React.FC<Omit<LinkProps, 'className'>> = ({ children, ...linkProps }) => (
  <Link className={styles.link} {...linkProps}>
    {children}
  </Link>
)
