import { fetchCurrentSubscription } from './Api/Subscription/fetchCurrentSubscription'
import { createSubscription } from './Api/Subscription/createSubscription'
import { createCachedRequest } from './lib/cachedRequest'
import { fetchMetadata } from './Api/User/fetchMetadata'
import { updateUserMetadata } from './Api/User/updateMetadata'
import { deleteSubscription } from './Api/Subscription/deleteSubscription'
import { updateSubscription } from './Api/Subscription/updateSubscription'
import { fetchPorts } from './Api/Port/fetchPorts'
import { fetchPlans } from './Api/Subscription/Plan/fetchPlans'

function createEntry<A, B>(mutation: (...args: A[]) => Promise<B>, clearCache: () => void) {
  return function (...args: A[]) {
    const p = mutation.apply(null, args)

    p.then(() => clearCache())

    return p
  }
}

const cachedFetchCurrentSubscription = createCachedRequest(fetchCurrentSubscription)
const cachedFetchMetaData = createCachedRequest(fetchMetadata)
const cachedFetchPorts = createCachedRequest(fetchPorts)
const cachedFetchPlans = createCachedRequest(fetchPlans)

export const store = {
  currentSubscription: {
    ...cachedFetchCurrentSubscription,
    create: createEntry(createSubscription, cachedFetchCurrentSubscription.clearCache),
    delete: createEntry(deleteSubscription, cachedFetchCurrentSubscription.clearCache),

    update: createEntry(updateSubscription, cachedFetchCurrentSubscription.clearCache),
  },
  metadata: {
    ...cachedFetchMetaData,
    update: createEntry(updateUserMetadata, cachedFetchMetaData.clearCache),
  },

  plans: cachedFetchPlans,

  ports: cachedFetchPorts,
}
