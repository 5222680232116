import React, { PropsWithChildren } from 'react'
import { DisplayTrafficControl } from './DisplayTrafficControl'
import styles from './Controls.module.scss'
import { ControlStrip } from '../../UI/ControlStrip/ControlStrip'
import { DefaultPortControl, DefaultPortControlProps } from './DefaultPortControl'
import { Feature, useFeatureToggler } from '../../lib/hooks/useFeatureToggler'
import { ETASourceSignupControl, ETASourceUpgradeControl } from './ETASourceControl'
import { AddDefaultPortAndTrafficControl, UnlockPortAndTrafficControl } from './AddDefaultPortAndTrafficControl'
import { ChatControl } from './ChatControl'
import { useAuth0 } from '@auth0/auth0-react'

type PortcallControlsProps = PropsWithChildren<{
  port: DefaultPortControlProps
}>

export const PortcallControls = ({ port, children }: PortcallControlsProps) => (
  <div className={styles.mapControlStrip}>
    <ControlStrip>
      <DefaultPortControl {...port} />
      <DisplayTrafficControl />
      {children}
    </ControlStrip>
  </div>
)

export function TrafficAndPortcallsControls({ port }: PortcallControlsProps) {
  const { isEnabled: isCalculatedEta } = useFeatureToggler(Feature.PORTCALLS)

  return (
    <PortcallControls port={port}>
      {!isCalculatedEta && <ETASourceUpgradeControl />}
      <ChatControl />
    </PortcallControls>
  )
}

export const TrafficControls = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <div className={styles.mapControlStrip}>
      <ControlStrip>
        {isAuthenticated ? <UnlockPortAndTrafficControl /> : <AddDefaultPortAndTrafficControl />}
        {isAuthenticated ? <ETASourceUpgradeControl /> : <ETASourceSignupControl />}
        <ChatControl />
      </ControlStrip>
    </div>
  )
}

export const ShowCaseControls = PortcallControls
