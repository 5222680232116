import { BACKEND_URL } from '../../../config'
import { authRequest } from '../../auth'
import { Invoice, InvoiceJson } from '../../../Domain/Subscription/Invoice'
import { isKnownCurrency } from '../../../Domain/Subscription/Plan'
import { right, left } from 'fp-ts/es6/Either'

export const fetchUpcomingInvoice = () =>
  authRequest
    .get(`${BACKEND_URL}/api/invoices/upcoming`)
    .json<InvoiceJson>()
    .then(
      (invoice): Invoice => ({
        ...invoice,
        currency: isKnownCurrency(invoice.currency) ? right(invoice.currency) : left(invoice.currency),
      })
    )
