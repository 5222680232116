import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, BACKEND_URL, MIXPANEL_API_HOST, MIXPANEL_ID, isTestEnvironment } from './config'
import { ShipTrackerRouter } from './Router'
import { Meta } from './Meta'
import './styles/base.module.scss'
import './styles/theme.module.scss'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { ResponsivenessProvider } from './lib/hooks/useResponsiveness'
import { RollbarProvider } from './lib/hooks/useRollbar'
import { PortalProvider } from './lib/hooks/usePortal'
import { ToastContainer } from 'react-toastify'
import { WithAuth0Loaded } from './Auth/WithAuth0Loaded'
import { MixpanelProvider } from './lib/mixpanel/MixpanelContext'
import { HandleRedirectCallback } from './Auth/HandleRedirect'

ReactDOM.render(
  <RollbarProvider>
    <BrowserRouter>
      <Meta />
    </BrowserRouter>
    <MixpanelProvider
      mixpanelId={MIXPANEL_ID}
      config={{
        debug: isTestEnvironment,
        api_host: MIXPANEL_API_HOST,
        cross_subdomain_cookie: !isTestEnvironment,
      }}
    >
      <HandleRedirectCallback>
        {handleRedirectCallback => (
          <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            audience={`${BACKEND_URL}/api/`}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            redirectUri={window.location.origin}
            onRedirectCallback={handleRedirectCallback}
          >
            <WithAuth0Loaded>
              <ResponsivenessProvider>
                <PortalProvider id="portals">
                  <ShipTrackerRouter />
                </PortalProvider>
              </ResponsivenessProvider>
            </WithAuth0Loaded>
          </Auth0Provider>
        )}
      </HandleRedirectCallback>
      <ToastContainer position="bottom-center" />
    </MixpanelProvider>
  </RollbarProvider>,
  document.getElementById('root')
)
