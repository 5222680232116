import React from 'react'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { alto, navyBlue } from '../../styles/variables'

export const IconApi = ({ color, className }: { color?: string; className?: string }) => {
  const { isDarkMode } = useDarkMode()
  return (
    <svg
      width={25}
      height={19}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={color ?? isDarkMode ? alto : navyBlue}
    >
      <path
        d="M1.693 0h21.614C24.24 0 25 .76 25 1.693v15.181c0 .93-.76 1.693-1.693 1.693H1.693A1.698 1.698 0 0 1 0 16.874V1.693C0 .76.76 0 1.693 0ZM8.89 13.171H6.673l-.32 1.046h-2l2.387-6.34h2.145l2.376 6.34H9.208l-.317-1.046Zm-.413-1.37-.692-2.28-.696 2.28h1.388Zm3.556-3.923h3.26c.71 0 1.24.168 1.594.506.352.338.53.82.53 1.443 0 .64-.194 1.141-.578 1.501-.385.362-.975.541-1.766.541h-1.075v2.346h-1.965V7.878ZM14 10.586h.483c.38 0 .647-.068.801-.198.153-.132.23-.299.23-.504a.7.7 0 0 0-.201-.507c-.133-.138-.385-.207-.753-.207h-.562v1.416H14Zm4.682-2.708h1.965v6.34h-1.965v-6.34ZM24 4.738H1.076V17.04a.468.468 0 0 0 .468.468h21.981a.468.468 0 0 0 .468-.468v-12.3H24Zm-2.305-2.836a.836.836 0 1 1 0 1.673.836.836 0 0 1 0-1.673Zm-5.664 0a.836.836 0 1 1 0 1.673.836.836 0 0 1 0-1.673Zm2.832 0a.836.836 0 1 1 0 1.673.836.836 0 0 1 0-1.673Z"
        fillRule="nonzero"
      />
    </svg>
  )
}
