import React, { PropsWithChildren, useEffect, useRef, useMemo } from 'react'
import { Logo } from '../UI/Logo'

import styles from './AccountLayout.module.scss'
import { useAccountState } from './AccountState'
import { useHistory } from 'react-router-dom'
import { fromEvent } from 'rxjs'
import breakpoints from '../styles/breakpoints.scss'
import { filter } from 'rxjs/operators'
import { HamburgerMenu } from '../UI/HamburgerMenu/HamburgerMenu'
import { isOpen } from './helpers'

export const Header = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory()
  const [{ isSideMenuOpen }, { openSideMenu, closeSideMenu, resetSideMenu }] = useAccountState()
  const { current: mediaMatcher } = useRef(window.matchMedia(`(min-width: ${breakpoints['screen-lg']})`))

  const isMenuOpen = useMemo(() => isOpen(isSideMenuOpen), [isSideMenuOpen])

  useEffect(() => history.listen(closeSideMenu), [history, closeSideMenu])

  useEffect(() => {
    const subscription = fromEvent<MediaQueryListEvent>(mediaMatcher, 'change')
      .pipe(filter(({ matches }) => matches))
      .subscribe(resetSideMenu)

    return subscription.unsubscribe.bind(subscription)
  }, [mediaMatcher, resetSideMenu])

  return (
    <header className={styles.accountHeader}>
      <div className={styles.accountHeaderItem}>
        <HamburgerMenu isOpen={isMenuOpen} handleClick={isMenuOpen ? closeSideMenu : openSideMenu} />
      </div>
      <div className={styles.accountHeaderItem}>
        <Logo className={styles.accountLogo} />
      </div>
      {children}
    </header>
  )
}

export const BasicHeader = () => (
  <header className={styles.accountHeader}>
    <Logo className={styles.accountLogo} />
  </header>
)

export const AccountMenuWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.accountMenuWrapper}>{children}</div>
)
