import React from 'react'
import classnames from 'classnames'
import { Search, MobileSearch } from '../../QuickShipFinder/Search'
import { Logo } from '../../UI/Logo'
import styles from './Menu.module.scss'
import { AccountButton } from './AccountButton'
import { useResponsiveness } from '../../lib/hooks/useResponsiveness'
import { HamburgerMenu } from '../../UI/HamburgerMenu/HamburgerMenu'
import { Pages } from '../../constants'
import { Link, useHistory } from 'react-router-dom'
import { VesselSearchResult } from '../../Domain/VesselSearchResult'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { IconApi } from '../../UI/Icons/IconApi'

export const MenuWrapper: React.FC = ({ children }) => <div className={styles.menu}>{children}</div>

type RightMenuProps = {
  isMenuNavigationOpen: boolean
  handleSearchResult: (result: VesselSearchResult, term: string) => void
}

export function RightMenu({ isMenuNavigationOpen, handleSearchResult }: RightMenuProps) {
  const { isMobile } = useResponsiveness()

  return (
    <div className={styles.right}>
      {!isMobile && <Search handleSearchResult={handleSearchResult} className={styles.menuItem} />}
      {isMobile && <MobileSearch handleSearchResult={handleSearchResult} className={styles.menuItem} />}
      {!isMenuNavigationOpen && <AccountButton />}
    </div>
  )
}

type LeftMenuProps = {
  isMenuNavigationOpen: boolean
  setMenuNavigationOpen: (isOpen: boolean) => void
}

export function LeftMenu({ isMenuNavigationOpen, setMenuNavigationOpen }: LeftMenuProps) {
  const history = useHistory()
  const { track } = useMixpanel()
  const { isMobile } = useResponsiveness()

  return (
    <div className={styles.left}>
      <div className={styles.leftItems}>
        <div className={classnames(styles.menuItem, styles.hamburgerItem)}>
          <HamburgerMenu
            isOpen={isMenuNavigationOpen}
            handleClick={() => setMenuNavigationOpen(!isMenuNavigationOpen)}
            customStyle={styles.hamburgerStyle}
          />
        </div>
        <Link to={Pages.MAP} className={classnames(styles.menuItem, styles.logoItem)}>
          <Logo className={styles.logo} />
        </Link>
      </div>
      {!isMobile && (
        <div
          onClick={() => {
            track('Click "Buy API credits"', { position: 'topbar' })
            history.push(Pages.API_CREDITS_STORE)
          }}
          className={classnames(styles.buyApiCredits, styles.menuItem)}
        >
          <IconApi className={styles.icon} />
          <span className={classnames(styles.cta, styles.menuText)}>Get ETA predictions via API</span>
          <span className={styles.label}>New</span>
        </div>
      )}
    </div>
  )
}
