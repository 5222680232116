import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Pages } from '../constants'
import { ConnectToApiOnboarding } from './ConnectToApiOnboarding/ConnectToApiOnboarding'

export const DeveloperPortalApp = () => (
  <Switch>
    <Route path={Pages.DEV_PORTAL_CONNECT_API} redirect={Pages.MAIN}>
      <ConnectToApiOnboarding />
    </Route>
    <Redirect to={Pages.MAIN} />
  </Switch>
)
