import React, { useState, useEffect } from 'react'
import styles from './ConnectToApiOnboarding.module.scss'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { apiPlanFromId } from '../../Domain/Subscription/ApiPlan'
import { map, chain, some, none, fold } from 'fp-ts/es6/Option'
import { constant, pipe } from 'fp-ts/es6/function'
import { useFromFetch } from '../../lib/useFromFetch'
import { findFirstMap, findFirst } from 'fp-ts/es6/Array'
import { currencyFromString, findPriceByCurrency, Currency } from '../../Domain/Subscription/Plan'
import { DisplayCurrencyOptions } from '../../UI/SelectCurrency/SelectCurrency'
import { useTranslation } from '../../lib/i18n'
import { CreditsBundle } from '../../Account/CreditsBundle/CreditsBundle'
import { RadioButtonWithLabel } from '../../UI/RadioButton/RadioButton'
import { FootNotes } from '../../Account/CreditsBundle/Footnotes'
import { StepHeader, StepText, StepTitle } from '../../Flow/GenericStepElements'
import { ConnectToApiStepProps } from './ConnectToApiOnboarding.d'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../constants'
import { store } from '../../dataStore'
import { fetchPlansAndCurrency } from '../../Api/Subscription/fetchPlansAndCurrency'
import { fetchUsage } from '../../Api/ApiKey/fetchUsage'

export const StepPlanSelection: React.FC<ConnectToApiStepProps> = ({
  initialPlanSelection,
  selectedPlan,
  setSelectedPlan,
  onPrevStep,
  onNextStep,
}) => {
  const { t } = useTranslation()
  const { isLoading, data } = useFromFetch(() =>
    Promise.all([fetchPlansAndCurrency(store.currentSubscription.fetch, fetchUsage), store.metadata.fetch()]).then(
      ([
        {
          plans: { api: plans },
          currency,
        },
        metadata,
      ]) => ({
        plans,
        currency,
        metadata,
      })
    )
  )
  const history = useHistory()

  const [currency, setCurrency] = useState(initialPlanSelection.currency)

  useEffect(() => {
    if (!isLoading && data) {
      setCurrency(pipe(data.currency, fold(constant(Currency.USD), currencyFromString)))
    }
  }, [isLoading, data])

  useEffect(() => {
    if (!isLoading && data !== undefined) {
      const plan = pipe(
        data.plans,
        selectedPlan
          ? findFirst(plan => plan.id === selectedPlan.id)
          : findFirstMap(plan =>
              pipe(
                plan.id,
                apiPlanFromId,
                chain(planType => (planType === initialPlanSelection.type ? some(plan) : none))
              )
            )
      )

      pipe(
        plan,
        map(({ id, name, prices }) =>
          pipe(
            findPriceByCurrency(prices, currency),
            map(price => {
              if (selectedPlan?.id !== id || price.id !== selectedPlan?.price.id) {
                setSelectedPlan({
                  id,
                  name,
                  price,
                })
              }
            })
          )
        )
      )
    }
  }, [isLoading, data, initialPlanSelection.type, currency, selectedPlan, setSelectedPlan])

  const metadata = data?.metadata

  return (
    <>
      <StepHeader onBackButton={onPrevStep} onClose={() => history.push(Pages.MAIN)} />
      <StepTitle>
        {t(
          'ShipTracker.DeveloperPortal.ConnectToApi.PlanSelection.Title',
          [metadata?.givenName, metadata?.familyName].join(' ')
        )}
      </StepTitle>
      <StepText>{t('ShipTracker.DeveloperPortal.ConnectToApi.PlanSelection.Description')}</StepText>
      {!isLoading && data && (
        <>
          <DisplayCurrencyOptions
            className={styles.restrictedCurrencyInfo}
            restrictedCurrency={data.currency}
            currency={currency}
            handleSelectCurrency={setCurrency}
          />
          <div className={styles.plansList}>
            {data.plans.map(plan => {
              const isSelected = selectedPlan !== undefined && plan.id === selectedPlan.id

              return (
                <CreditsBundle isDimmed={!isSelected} key={plan.id} plan={plan} currency={currency}>
                  <RadioButtonWithLabel
                    label={t('ShipTracker.DeveloperPortal.ConnectToApi.PlanSelection.SelectBundle')}
                    checked={isSelected}
                    handleChange={() => {
                      const { id, name, prices } = plan

                      pipe(
                        findPriceByCurrency(prices, currency),
                        map(price =>
                          setSelectedPlan({
                            id,
                            name,
                            price,
                          })
                        )
                      )
                    }}
                  />
                </CreditsBundle>
              )
            })}
          </div>
        </>
      )}
      <FootNotes className={styles.footNotes} />
      <div className={styles.form}>
        <CtaButton className={styles.submitButton} onClick={() => onNextStep()} isDisabled={selectedPlan === undefined}>
          {t('ShipTracker.DeveloperPortal.ConnectToApi.PlanSelection.GoToPayment')}
        </CtaButton>
      </div>
    </>
  )
}
