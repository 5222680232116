import { GenericStepProps } from '../../Flow/GenericStepElements'

export enum CreateAccountStep {
  AUTHENTICATION = 'authentication',
  EMAIL_VERIFICATION = 'email-verification',
  LEGAL_TERMS = 'legal-terms',
  PROFILE = 'profile',
}

export type CreateAccountStepProps = GenericStepProps<CreateAccountStep>
