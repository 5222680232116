import React, { PropsWithChildren } from 'react'
import { Formik, Field, ErrorMessage, getIn } from 'formik'
import classnames from 'classnames'
import { object as YupObject, string as YupString } from 'yup'

import { Metadata, SystemOfMeasurement } from '../../Domain/User'
import { useTranslation } from '../../lib/i18n'
import { InputError } from '../InputError'
import { RadioButtonWithLabel } from '../../UI/RadioButton/RadioButton'
import styles from './UserProfile.module.scss'
import { FormikCompanyTypeDropDown } from '../SelectCompanyType/CompanyTypeDropDown'
import { Checkbox } from '../../UI/Checkbox/Checkbox'
import { CompanyType, companyTypes } from '../../Domain/Company'

const validationSchema = YupObject<Metadata>().shape(
  {
    givenName: YupString().required('Required'),
    familyName: YupString().required('Required'),
    accountType: YupString<Metadata['accountType']>().oneOf(['private', 'business']),
    company: YupObject().when('accountType', {
      is: type => type === 'business',
      then: YupObject().shape({
        name: YupString().required('Required'),
        companyType: YupString<CompanyType>().oneOf(companyTypes).required('Required'),
      }),
    }),
    measurementUnit: YupString().oneOf([SystemOfMeasurement.Imperial, SystemOfMeasurement.Metric]).required('Required'),
  },
  [['accountType', 'companyName']]
)

type Values = Partial<Omit<Metadata, 'company'>> & {
  company?: { name: string; companyType: CompanyType | '' }
}

type UserProfileFormProps = PropsWithChildren<{
  metadata: Metadata
  submitText: string
  handleSubmit: (values: Partial<Metadata>) => void
  isNewProfile?: boolean
}>

export function UserProfileForm({
  children,
  metadata,
  handleSubmit,
  submitText,
  isNewProfile = false,
}: UserProfileFormProps) {
  const { t } = useTranslation()
  const initialValues: Values = {
    givenName: metadata?.givenName ?? '',
    familyName: metadata?.familyName ?? '',
    accountType: metadata?.accountType ?? 'private',
    measurementUnit: metadata?.measurementUnit ?? SystemOfMeasurement.Metric,
    company: metadata?.company ?? { name: '', companyType: '' },
    consentEmailMarketing: metadata?.consentEmailMarketing ?? false,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(
          values.accountType === 'private'
            ? { ...values, company: undefined }
            : {
                ...values,
                company: values.company as Metadata['company'], // fields in company are required by yup
              }
        )
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        dirty,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => (
        <div className={styles.accountForm}>
          <label
            className={classnames(styles.inputField, {
              [styles.formError]: 'givenName' in errors && 'givenName' in touched,
            })}
          >
            <div className={styles.label}>{t('ShipTracker.Account.UserProfile.FirstName')}</div>
            <Field type="text" name="givenName" placeholder={t('ShipTracker.Account.UserProfile.FirstName')} />
            <ErrorMessage name="givenName" component={InputError} />
          </label>
          <label
            className={classnames(styles.inputField, {
              [styles.formError]: 'familyName' in errors && 'familyName' in touched,
            })}
          >
            <div className={styles.label}>{t('ShipTracker.Account.UserProfile.LastName')}</div>
            <Field type="text" name="familyName" placeholder={t('ShipTracker.Account.UserProfile.LastName')} />
            <ErrorMessage name="familyName" component={InputError} />
          </label>
          {children}

          <div className={styles.section}>
            <h4 className={styles.header}>Account Type</h4>
            <RadioButtonWithLabel
              checked={values.accountType === 'private'}
              handleChange={e => {
                setFieldValue('company.name', '', false)
                setFieldTouched('company.name', false, false)
                handleChange(e)
              }}
              label={t('ShipTracker.Account.UserProfile.PrivateUser')}
              name="accountType"
              value="private"
            />
            <RadioButtonWithLabel
              checked={values.accountType === 'business'}
              handleChange={handleChange}
              label={t('ShipTracker.Account.UserProfile.BusinessUser')}
              name="accountType"
              value="business"
            />
            {values.accountType === 'business' && (
              <>
                <label
                  className={classnames(styles.inputField, {
                    [styles.formError]: getIn(errors, 'company.name') && getIn(touched, 'company.name'),
                  })}
                >
                  <div className={styles.label}>{t('ShipTracker.Account.UserProfile.CompanyName')}</div>
                  <Field
                    type="text"
                    name="company.name"
                    placeholder={t('ShipTracker.Account.UserProfile.CompanyName')}
                  />
                  <ErrorMessage name="company.name" component={InputError} />
                </label>
                <FormikCompanyTypeDropDown
                  name="company.companyType"
                  placeHolder={t('ShipTracker.Account.UserProfile.CompanyTypePlaceholder')}
                >
                  <div className={styles.label}>{t('ShipTracker.Account.UserProfile.CompanyType')}</div>
                </FormikCompanyTypeDropDown>
              </>
            )}
            {isNewProfile && (
              <div className={styles.section}>
                <Checkbox
                  className={styles.checkbox}
                  onChange={(value: boolean) => setFieldValue('consentEmailMarketing', value)}
                  checked={values.consentEmailMarketing}
                >
                  {t('ShipTracker.Account.UseDataForMarketing')}
                </Checkbox>
              </div>
            )}
            <div className={styles.section}>
              <h4 className={styles.header}>Measurement Unit</h4>
              <RadioButtonWithLabel
                checked={values.measurementUnit === SystemOfMeasurement.Metric}
                handleChange={handleChange}
                label={SystemOfMeasurement.Metric}
                name="measurementUnit"
                value={SystemOfMeasurement.Metric}
              />
              <RadioButtonWithLabel
                checked={values.measurementUnit === SystemOfMeasurement.Imperial}
                handleChange={handleChange}
                label={SystemOfMeasurement.Imperial}
                name="measurementUnit"
                value={SystemOfMeasurement.Imperial}
              />
            </div>
            <button
              disabled={!isValid || (!isNewProfile && !dirty) || isSubmitting}
              type="submit"
              onClick={() => handleSubmit()}
              className={styles.submitButton}
            >
              {submitText}
            </button>
          </div>
        </div>
      )}
    </Formik>
  )
}
