import { store } from '../dataStore'
import { useMixpanel } from './../lib/mixpanel/MixpanelContext'

type PropName = 'onboardingGuiStatus' | 'onboardingApiStatus'

const startEventName: { [key in PropName]: string } = {
  onboardingApiStatus: 'Onboarding starts - API',
  onboardingGuiStatus: 'Onboarding starts - GUI',
}

export const useHandleFlowStart = (propName: PropName): (() => void) => {
  const { track, setUserProperties, register, getSuperProperty } = useMixpanel()

  return () => {
    store.metadata.fetch().then(metadata => {
      if (metadata && (!metadata[propName] || metadata[propName] === 'not_started')) {
        store.metadata.update({ [propName]: 'in_progress' })
      }
    })

    const superProperty = getSuperProperty(propName)
    if (!superProperty || superProperty === 'not_started') {
      setUserProperties({ [propName]: 'in_progress' })
      register({ [propName]: 'in_progress' })
      track(startEventName[propName])
    }
  }
}
