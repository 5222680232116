import { Either, left } from 'fp-ts/es6/Either'
import { UnexpectedResponseError, ErrorNotFound } from './RequestError'
import { right } from 'fp-ts/lib/Either'

export const handleJsonResponse = (response: Response) => response.json()

type ResponseConfig<A> = { [key: number]: (r: Response) => Promise<Either<Error, A>> }

export const handleNotFound = <A>(config: ResponseConfig<A>): ResponseConfig<A> => ({
  ...config,
  404: async response => left(new ErrorNotFound(`${response.status}`)),
})

export async function handleResponse<T>(config: ResponseConfig<T>, response: Response): Promise<Either<Error, T>> {
  if (response.status in config) {
    return config[response.status](response)
  }

  return left(new UnexpectedResponseError(`${response.status}`))
}

export const responseJsonResult = async <A>(response: Response): Promise<Either<Error, A>> => {
  try {
    const json = await response.json()
    return right(json)
  } catch (e) {
    return left(e as Error)
  }
}
