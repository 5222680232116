import React, { useEffect } from 'react'

const moveChildren = (parent: Element, portal: Element) => {
  while (portal.firstChild) {
    parent.appendChild(portal.firstChild)
  }
}

export const LivechatComponent: React.FC<{ className: string }> = ({ className }) => {
  const portalRef = React.useRef(document.querySelector('#livechat-portal'))
  const parentRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current: portalElem } = portalRef
    const { current: parentElem } = parentRef

    if (portalElem && parentElem) {
      moveChildren(parentElem, portalElem)

      return () => {
        moveChildren(portalElem, parentElem)
      }
    }
  }, [portalRef, parentRef])

  return <div className={className} ref={parentRef} />
}
