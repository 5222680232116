import classnames from 'classnames'
import { getYear } from 'date-fns'
import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { isShowCase } from '../../config'
import { Pages, PXP_HOMEPAGE_URL } from '../../constants'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { useTranslation } from '../../lib/i18n'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { ShoppingCart } from '../../UI/Icons/ShoppinCart'
import { Toggle } from '../../UI/Toggle/Toggle'
import styles from './MenuNavigation.module.scss'

type MenuNavigationProps = { isOpen: boolean; onClose: () => void }
export const MenuNavigation: React.FC<MenuNavigationProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const { isDarkMode, setDarkMode } = useDarkMode()
  const apiCreditsRef = useRef<HTMLAnchorElement>(null)

  const currentYear = useMemo(() => getYear(new Date()), [])

  return (
    <>
      {isOpen && <div className={styles.menuNavigationOverlay} onClick={onClose}></div>}
      <nav className={classnames(styles.menuNavigation, { [styles.menuNavigationOpen]: isOpen })}>
        <ul className={classnames(styles.section, styles.sectionTop)}>
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.MAP} className={styles.inner}>
              <label className={styles.text}>{t('ShipTracker.Menu.ShowMap')}</label>
            </Link>
          </li>
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.ABOUT} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.AboutShipTracker')}</span>
            </Link>
          </li>
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.AVAILABLE_PORTS} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.AvailablePorts')}</span>
            </Link>
          </li>
          <li className={styles.entry}>
            <Link
              innerRef={apiCreditsRef}
              id="shiptracker-api-credits-link-menu"
              onClick={() => {
                onClose()
                track('Click "Buy API credits"', { position: 'menu' })
              }}
              to={Pages.API_CREDITS_STORE}
              className={styles.inner}
            >
              <span className={styles.text}>{t('ShipTracker.Menu.ApiCreditsStore')}</span>
              <ShoppingCart className={styles.cartIcon} />
              <span className={styles.label}>NEW</span>
            </Link>
          </li>
          {!isShowCase && (
            <li className={styles.entry}>
              <Link
                onClick={() => {
                  onClose()
                  track('Click "Buy Web Subscription"', { position: 'menu' })
                }}
                to={Pages.WEB_SUBSCRIPTIONS}
                className={styles.inner}
              >
                <span className={styles.text}>{t('ShipTracker.Menu.WebSubscriptions')}</span>
                <ShoppingCart className={styles.cartIcon} />
                <span className={classnames(styles.label, styles.hidden)}>NEW</span>
              </Link>
            </li>
          )}
          {isShowCase && (
            <li className={styles.entry}>
              <Link onClick={onClose} to={Pages.SHIP_TRACKER_GLOBAL} className={styles.inner}>
                <span className={styles.text}>{t('ShipTracker.Menu.GlobalETAs')}</span>
              </Link>
            </li>
          )}
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.SUPPORT} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.Support')}</span>
            </Link>
          </li>
        </ul>
        <ul className={classnames(styles.section, styles.sectionBottom)}>
          <li className={classnames(styles.entry, styles.inner)}>
            <label className={styles.text}>{t('ShipTracker.Menu.DarkMode')}</label>
            <Toggle isChecked={isDarkMode} handleToggle={setDarkMode} />
          </li>
          <li className={styles.entry}>
            <Link to={Pages.LEGAL_TERMS} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.LegalTerms')}</span>
            </Link>
          </li>
          <li className={classnames(styles.entry, styles.entrySmall, styles.entryInactive, styles.inner)}>
            <span className={styles.text}>
              &copy; {currentYear} Shiptracker by{' '}
              <a href={PXP_HOMEPAGE_URL} target="_blank" rel="noopener noreferrer">
                PortXchange
              </a>
            </span>
          </li>
        </ul>
      </nav>
    </>
  )
}
