const getStore = (key: string, storage: Storage) => ({
  getItem: storage.getItem.bind(storage, key),
  setItem: storage.setItem.bind(storage, key),
  removeItem: storage.removeItem.bind(storage, key),
})

export function createStore(key: string) {
  return getStore(key, window.localStorage)
}

export function createSessionStore(key: string) {
  return getStore(key, window.sessionStorage)
}
