import { Currency, PaidPlan, Period } from '../Domain/Subscription/Plan'
import { Pages } from '../constants'

export const updateSubscriptionRoute = (plan: PaidPlan, period: Period, currency: Currency) =>
  `${Pages.ACCOUNT_UPDATE_SUBSCRIPTION}/${plan}/${period}/${currency}`

export enum ApiOnlyStep {
  LegalTerms = 'legal-terms',
  Payment = 'payment',
}

export const apiOnlySubscriptionRoute = (step: ApiOnlyStep) => `${Pages.ACCOUNT_API_ONLY}/${step}`
