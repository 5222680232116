import React, { useContext, useMemo, useRef, useState } from 'react'
import mixpanel, { Config as MixpanelConfig, Dict, Mixpanel } from 'mixpanel-browser'
import { isShowCase, isTestEnvironment } from '../../config'

const noop = () => {}
const noopStubbed = <T,>(stubbedReturnValue?: T) => {
  if (isTestEnvironment) {
    console.debug('Mixpanel is disabled on SHOW_CASE envs')
  }

  return stubbedReturnValue
}

// Exposed Mixpanel API
// See https://developer.mixpanel.com/docs/javascript-full-api-reference for full API reference,
// although we don't expose the entire API
const initMixpanel = (id: string, config: Partial<MixpanelConfig>) => {
  mixpanel.init(id, config)

  return mixpanel
}

// Declare the Mixpanel Context propeties
interface IMixpanelContext {
  alias: Mixpanel['alias']
  identify: Mixpanel['identify']
  track: Mixpanel['track']
  register: Mixpanel['register']
  getSuperProperty: Mixpanel['get_property']
  trackLink: Mixpanel['track_links']
  reset: Mixpanel['reset']
  registerOnce: Mixpanel['register_once']
  setUserProperties: (props: string | Dict, value?: any) => void
  getDistinctId: Mixpanel['get_distinct_id']
  getUserId: () => string | undefined
  incrementUserProperty: (key: string, value: number) => void
  incrementSuperProperty: (key: string, value: number) => void
  setupUser: (userId: string, properties: {}, callback: (context: IMixpanelContext) => void) => void
  clearQueue: () => void
}

function mixpanelContext(mixpanel: Mixpanel): IMixpanelContext {
  const registerOnce = mixpanel.register_once.bind(mixpanel)

  return {
    alias: mixpanel.alias.bind(mixpanel),
    identify: mixpanel.identify.bind(mixpanel),
    reset: mixpanel.reset.bind(mixpanel),
    register: mixpanel.register.bind(mixpanel),
    getSuperProperty: mixpanel.get_property.bind(mixpanel),
    track: mixpanel.track.bind(mixpanel),
    registerOnce,
    trackLink: mixpanel.track_links.bind(mixpanel),
    getDistinctId: mixpanel.get_distinct_id.bind(mixpanel),
    getUserId: () => mixpanel.get_property('$user_id'),
    setUserProperties: (props: string | Dict, value?: any) => {
      if (typeof props === 'string') {
        mixpanel.people.set(props, value)
      } else {
        mixpanel.people.set(props)
      }
    },
    incrementUserProperty: (key: string, value: number) => {
      mixpanel.people.increment({ [key]: value })
    },

    incrementSuperProperty: (key: string, value: number) => {
      registerOnce({ [key]: 0 })

      const incrementedProp = mixpanel.get_property(key) + value
      mixpanel.register({ [key]: incrementedProp })
    },
    clearQueue: noop,
    setupUser: noop,
  }
}

const stubbedMixpanelContext: IMixpanelContext = {
  alias: noopStubbed,
  identify: noopStubbed,
  reset: noopStubbed,
  register: noopStubbed,
  getSuperProperty: noopStubbed,
  track: noopStubbed,
  registerOnce: noopStubbed,
  trackLink: noopStubbed,
  getDistinctId: noopStubbed,
  getUserId: noopStubbed,
  setUserProperties: noopStubbed,
  incrementUserProperty: noopStubbed,
  incrementSuperProperty: noopStubbed,
  clearQueue: noopStubbed,
  setupUser: noopStubbed,
}

const MixpanelContext: React.Context<IMixpanelContext> = React.createContext({} as IMixpanelContext)

type TrackEvent = (context: IMixpanelContext) => void

export class EventQueue {
  private queue: Array<TrackEvent> = []

  addEvent(fn: TrackEvent) {
    this.queue.push(fn)
  }

  flush(context: IMixpanelContext) {
    this.queue.forEach(fn => fn(context))
    this.queue = []
  }
}

export const useMixpanel = () => useContext(MixpanelContext)

type MixpanelProviderProps = Readonly<{
  mixpanelId: string
  config: Partial<MixpanelConfig>
}>

export const StubbedMixpanelProvider: React.FC = ({ children }) => {
  return <MixpanelContext.Provider value={stubbedMixpanelContext}>{children}</MixpanelContext.Provider>
}

export const GlobalMixpanelProvider: React.FC<MixpanelProviderProps> = ({ children, mixpanelId, config }) => {
  const [isCleared, setIsCleared] = useState(false)
  const [isUserSetup, setIsUserSetup] = useState(false)
  const queue = useRef(new EventQueue()).current
  const mixpanel = useRef(initMixpanel(mixpanelId, config)).current

  const context = useMemo<IMixpanelContext>(() => {
    const initialContext = mixpanelContext(mixpanel)

    const setupUser: IMixpanelContext['setupUser'] = (userId, properties, done) => {
      setIsUserSetup(true)
      if (initialContext.getUserId() !== userId) {
        initialContext.alias(userId, initialContext.getDistinctId())
      }
      initialContext.identify(userId)
      initialContext.register(properties)
      initialContext.setUserProperties(properties)
      done(initialContext)
    }

    if (isCleared) {
      queue.flush(initialContext)

      return {
        ...initialContext,
        ...(!isUserSetup ? { setupUser } : {}),
      }
    }

    return {
      ...initialContext,
      track: (...args) => queue.addEvent(({ track }) => track(...args)),
      trackLink: (...args) => queue.addEvent(({ trackLink }) => trackLink(...args)),
      incrementUserProperty: (...args) => queue.addEvent(({ incrementUserProperty }) => incrementUserProperty(...args)),
      setUserProperties: (...args) => queue.addEvent(({ setUserProperties }) => setUserProperties(...args)),
      clearQueue: () => setIsCleared(true),
      setupUser,
    }
  }, [isCleared, queue, mixpanel, isUserSetup])

  return <MixpanelContext.Provider value={context}>{children}</MixpanelContext.Provider>
}

export const MixpanelProvider: React.FC<MixpanelProviderProps> = ({ children, mixpanelId, config }) => {
  if (isShowCase) {
    // We've decided to disable Mixpanel tracking for all SHOW_CASE envs, as we don't analyse data coming from them at this time
    return <StubbedMixpanelProvider>{children}</StubbedMixpanelProvider>
  }

  return (
    <GlobalMixpanelProvider mixpanelId={mixpanelId} config={config}>
      {children}
    </GlobalMixpanelProvider>
  )
}
