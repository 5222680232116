import { BACKEND_URL } from '../../config'
import { authRequest } from '../auth'

type CreateApiCheckoutResponse = {
  id: string
  clientSecret: string
  status: string
}
export const createApiCheckout = (priceId: string, paymentMethodId: string): Promise<CreateApiCheckoutResponse> =>
  authRequest
    .post(`${BACKEND_URL}/api/checkout`, { json: { priceId, paymentMethodId } })
    .json<CreateApiCheckoutResponse>()
