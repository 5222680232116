import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import classnames from 'classnames'
import { pipe } from 'fp-ts/es6/function'
import { fold } from 'fp-ts/es6/Option'
import React, { PropsWithChildren } from 'react'
import { WithStripeElements } from './WithStripeElements'
import { useTranslation } from '../../lib/i18n'
import { CreditCardBrandIcon } from '../../UI/Icons/CreditCardBrand'
import MaterialIcon from '../../UI/MaterialIcon'
import { HandleCreatePaymentMethod, useStripeForm, useStripeHooks } from '../CreditCardForm/useStripe'
import { InputError } from '../InputError'
import styles from './CreditCardForm.module.scss'
import '../../styles/stripe.scss'
import { SubmitButton } from '../AccountPage'
import { useConfirmationModal } from '../../UI/Modal/ConfirmationModal'

type FormContentProps = PropsWithChildren<{ className?: string }>
export const FormContent = ({ children, className }: FormContentProps) => (
  <div className={classnames(styles.creditCardForm, className)}>{children}</div>
)

type CardIconProps = { brand: string }
export const CardIcon = ({ brand }: CardIconProps) => (
  <div className={classnames(styles.brandIcon, styles.disabled)}>
    <CreditCardBrandIcon type={brand} />
  </div>
)

export function CardNumber({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()

  return (
    <div>
      <label>
        <span className={styles.label}>{t('ShipTracker.Account.CreditCardForm.YourDefaultCreditCard')}</span>
        <CardNumberWrapper>{children}</CardNumberWrapper>
      </label>
    </div>
  )
}

export const CardNumberWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.creditCardNumber}>{children}</div>
)

export function CardExpiry({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()
  return (
    <div className={styles.creditCardExpiration}>
      <label>
        <span className={styles.label}>{t('ShipTracker.Account.CreditCardForm.ExpirationDate')}</span>
        {children}
      </label>
    </div>
  )
}

export function CardCvc({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()

  return (
    <div className={styles.creditCardCVC}>
      <label>
        <span className={styles.label}>{t('ShipTracker.Account.CreditCardForm.CVC')}</span>
        {children}
      </label>
    </div>
  )
}

export function CvcContent({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()
  const [CvcModal, displayCvcModal] = useConfirmationModal({
    title: t('ShipTracker.Account.CreditCardForm.CvcModal.Title'),
    text: t('ShipTracker.Account.CreditCardForm.CvcModal.Text'),
    excludeCancel: true,
    confirmText: 'I understand',
  })

  return (
    <>
      <div className={styles.content}>
        <div className={styles.leftColum}>{children}</div>
        <a href="#what-is-cvc" onClick={() => displayCvcModal()}>
          {t('ShipTracker.Account.CreditCardForm.WhatIsCVC')}?
        </a>
      </div>
      <CvcModal />
    </>
  )
}

export function EncryptionInfo() {
  const { t } = useTranslation()

  return (
    <div className={styles.encryptionInfo}>
      <MaterialIcon className={styles.lockIcon} type="lock" />
      {t('ShipTracker.Account.CreditCardForm.EncryptionInfo')}
    </div>
  )
}

type StripeCreditCardFormContentProps = {
  stripe: Stripe
  elements: StripeElements
  isSubmitting?: boolean
  handleCreatePaymentMethod: HandleCreatePaymentMethod
  submitText: string
}

export function StripeCreditCardFormContent({
  stripe,
  elements,
  isSubmitting = false,
  handleCreatePaymentMethod,
  submitText,
}: StripeCreditCardFormContentProps) {
  const { isValid, handleChange, handleSubmit, errors } = useStripeForm({
    stripe,
    elements,
    handleCreatePaymentMethod,
  })

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <FormContent>
        <CardNumber>
          <CardNumberElement onChange={handleChange} options={{ showIcon: true }} />
          {errors.cardNumber && <InputError>{errors.cardNumber.message}</InputError>}
        </CardNumber>
        <CardExpiry>
          <CardExpiryElement onChange={handleChange} />
          {errors.cardExpiry && <InputError>{errors.cardExpiry.message}</InputError>}
        </CardExpiry>
        <CardCvc>
          <>
            <CvcContent>
              <CardCvcElement onChange={handleChange} />
            </CvcContent>
            {errors.cardCvc && <InputError>{errors.cardCvc.message}</InputError>}
          </>
        </CardCvc>
        <div className={styles.submitSection}>
          <SubmitButton disabled={isSubmitting || !isValid}>{submitText}</SubmitButton>
        </div>
        <EncryptionInfo />
      </FormContent>
    </form>
  )
}

type StripeCreditCardFormProps = Pick<
  StripeCreditCardFormContentProps,
  'handleCreatePaymentMethod' | 'isSubmitting' | 'submitText'
>

function StripeCreditCardFormWithHooks(props: StripeCreditCardFormProps) {
  const stripeHooksOption = useStripeHooks()

  return pipe(
    stripeHooksOption,
    fold(
      () => <div data-testid="no-hooks" />,
      ([stripe, elements]) => <StripeCreditCardFormContent {...props} stripe={stripe} elements={elements} />
    )
  )
}

export const StripeCreditCardForm = (props: StripeCreditCardFormProps) => (
  <WithStripeElements>
    <StripeCreditCardFormWithHooks {...props} />
  </WithStripeElements>
)
