import React from 'react'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import { useSelectPort } from '../../lib/hooks/useSelectPort'
import { Unlocode, createUnlocode, Port } from '../../Domain/Port'
import { SearchResult } from '../../Account/SelectPort/fuzzyPortSearch'
import { SearchState } from '../../lib/hooks/useSearchState'

type Item = SearchResult['item']

type RenderProps = ControllerStateAndHelpers<Item> & {
  searchState: SearchState<SearchResult[]>
  onQueryChange: (value: string) => void
}

type SelectPortProps = {
  port?: string
  ports: Port[]
  minimumQueryLength: number
  handleSelect: (port: Unlocode | null) => void
  children: (props: RenderProps) => JSX.Element
}

const itemToString = (item: Item | null | undefined) => (item ? `${item.name} (${item.port})` : '')

export function SelectPort({ ports, port, handleSelect, minimumQueryLength, children }: SelectPortProps) {
  const { selectedItem, searchState, onQueryChange } = useSelectPort(port, ports, minimumQueryLength)

  return (
    <Downshift<Item>
      initialInputValue={itemToString(selectedItem)}
      itemToString={itemToString}
      onChange={item => handleSelect((item && createUnlocode(item.port)) || null)}
      selectedItem={selectedItem}
    >
      {renderProps => children({ ...renderProps, searchState, onQueryChange })}
    </Downshift>
  )
}
