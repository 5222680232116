import React from 'react'
import classnames from 'classnames'
import styles from './Plan.module.scss'
import MaterialIcon from '../MaterialIcon'

export enum Theme {
  PURPLE = 'purle',
}

type PlanOptionProps = { disabled?: boolean; isDimmed?: boolean }

export const PlanOption: React.FC<PlanOptionProps> = ({ children, disabled, isDimmed = false }) => (
  <div
    className={classnames(styles.planOption, {
      [styles.disabled]: disabled,
      [styles.isDimmed]: isDimmed,
    })}
  >
    {children}
  </div>
)

type PlanOptionHeaderProps = { title: string; caption?: string }

export const PlanOptionHeader = ({ title, caption }: PlanOptionHeaderProps) => (
  <div className={styles.header}>
    <h3>{title}</h3>
    {caption && <span>{caption}</span>}
  </div>
)

type PlanOptionPriceProps = { price: string }
export const PlanOptionPrice = ({ price }: PlanOptionPriceProps) => (
  <div className={styles.price}>
    <h3>{price}</h3>
  </div>
)

export const Item: React.FC = ({ children }) => <li className={styles.item}>{children}</li>

export const FeatureItem: React.FC = ({ children }) => (
  <li className={styles.item}>
    <span className={styles.icon}>
      <MaterialIcon className={styles.muiIcon} type="check_circle" title="•" />
    </span>
    <span className={styles.description}>{children}</span>
  </li>
)

export const PlanOptionFeatures: React.FC = ({ children }) => <div className={styles.features}> {children}</div>

export const PlanOptionFeaturesList: React.FC = ({ children }) => <ul className={styles.listing}>{children}</ul>
