import { SHIPTRACKER_URL, SHOW_CASE } from './config'
import { createUnlocode, Port } from './Domain/Port'

export const SECOND = 1000

export const MINUTE = 60 * SECOND

export const HOUR = 60 * MINUTE

export const Pages = {
  MAIN: '/',
  MAP: '/map',
  LOGIN: '/login',
  LEGAL_TERMS: '/legal-terms',
  WEB_SUBSCRIPTIONS: '/web-subscriptions',
  SUPPORT: '/support',
  AVAILABLE_PORTS: '/available-ports',
  ABOUT: '/about',
  SHIP_TRACKER_API_PROMO: '/shiptracker-api-promo',
  SHIP_TRACKER_GLOBAL: '/shiptracker-global',
  SHOW_CASE_SIGNUP: '/show-case-signup',
  CREATE_ACCOUNT: '/create-account',
  ACCOUNT: '/account',
  ACCOUNT_404: '/account/404',
  ACCOUNT_DEFAULT_PORT: '/account/default-port',
  ACCOUNT_SUBSCRIPTION: '/account/subscription',
  ACCOUNT_UPDATE_SUBSCRIPTION: '/account/subscription-update',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_PAYMENT_DETAILS: '/account/payment-details',
  ACCOUNT_INVOICES: '/account/invoices',
  ACCOUNT_LEGAL_TERMS: '/account/legal-terms',
  ACCOUNT_API_ONLY: '/account/api-only',
  ACCOUNT_API_KEY: '/account/api-key',
  DEV_PORTAL: '/developer',
  DEV_PORTAL_CONNECT_API: '/developer/connect-api',
  API_CREDITS_STORE: '/api-credits-store',
} as const

export const PXP_HOMEPAGE_URL = 'https://port-xchange.com/'
export const COOKIE_POLICY_URL = 'https://port-xchange.com/cookie-policy/'
export const PRIVACY_POLICY_URL = 'https://port-xchange.com/privacy-policy/'
export const SHIPTRACKER_GLOBAL_REDIRECT_URL = (path: string = '') =>
  `${SHIPTRACKER_URL}${path || ''}?utm_campaign=Shiptracker&utm_source=Rotterdam`

export const SHIP_TRACKER_THEME_CSS = 'ship-tracker-theme'
export const SHIP_TRACKER_THEME_DARK_CSS = 'ship-tracker-theme--dark'

export const CURRENT_VERSION_LEGAL_TERMS = '2021-03-01'

export const AUTH0_CONSENT = 'offline_access openid profile email'

export const SHOW_CASES: Record<string, Port> = {
  NLRTM: {
    geo: {
      coordinates: [
        [
          [4.09365351828714, 51.9850902866555],
          [4.08719302478297, 52.0161596737504],
          [3.98968955023527, 52.0345035817851],
          [3.971774650828, 51.9908789866844],
          [3.95805466885168, 51.9598003213699],
          [3.98431491524648, 51.9166568035827],
          [4.46901215535474, 51.8200309258342],
          [4.42000841880848, 51.685281557589],
          [4.62900311805812, 51.6640010303137],
          [4.69874612574227, 51.8379683191971],
          [4.61004016113281, 51.9139434455446],
          [4.09365351828714, 51.9850902866555],
        ],
      ],
      type: 'Polygon',
    },
    aliasses: [],
    port: createUnlocode('NLRTM'),
    center: {
      coordinates: [4.063651, 51.986117],
      type: 'Point',
    },
    name: 'Port Of Rotterdam',
  },
}

export const getShowCasePort = (): Port | undefined =>
  SHOW_CASE !== undefined && SHOW_CASE in SHOW_CASES ? SHOW_CASES[SHOW_CASE] : undefined

export const SUPPORT_EMAIL_ADDRESS = 'support@port-xchange.com'
