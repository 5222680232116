import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

import MaterialIcon from '../MaterialIcon'
import styles from './Drawer.module.scss'

type DrawerProps = PropsWithChildren<{ className?: string | string[] }>

const Drawer = ({ children, className }: DrawerProps) => (
  <div className={classnames(styles.drawer, className)}>{children}</div>
)

export const BottomDrawer = ({ children }: PropsWithChildren<{}>) => (
  <Drawer className={[styles.drawerBottom, styles.active]}>{children}</Drawer>
)

export const RightDrawer = ({ children, className }: PropsWithChildren<DrawerProps>) => (
  <Drawer className={classnames(styles.drawerRight, styles.active, className)}>{children}</Drawer>
)

type DrawerHeaderProps = PropsWithChildren<Readonly<{ className?: string | string[] }>>

export const DrawerHeader = ({ children, className = [] }: DrawerHeaderProps) => (
  <div className={classnames([styles.drawerHeader].concat(className))}>{children}</div>
)

type DrawerHeaderButtonProps = PropsWithChildren<
  Readonly<{ label: string; isDisabled?: boolean; isActive?: boolean; className?: string; onClick?: () => void }>
>

export const DrawerHeaderButton = ({
  onClick,
  isDisabled,
  isActive,
  label,
  children,
  className,
}: DrawerHeaderButtonProps) => (
  <button
    onClick={!isDisabled ? onClick : undefined}
    className={classnames(
      styles.item,
      styles.button,
      { [styles.active]: isActive },
      { [styles.disabled]: isDisabled },
      className
    )}
  >
    <span className={styles.label}>{label}</span>
    {children}
  </button>
)

type DrawerHeaderItemProps = PropsWithChildren<Readonly<{ className?: string }>>

export const DrawerHeaderItem = ({ children, className }: DrawerHeaderItemProps) => (
  <div className={classnames(styles.item, className)}>{children}</div>
)

type DrawerHeaderActionsProps = PropsWithChildren<Readonly<{ onClose: () => void }>>

export const DrawerHeaderActions = ({ onClose, children }: DrawerHeaderActionsProps) => (
  <div className={styles.actions}>
    <div className={styles.content}>{children}</div>
    <DrawerHeaderButton label="Close" className={styles.actionItem} onClick={onClose}>
      <MaterialIcon type="close" className={styles.icon} />
    </DrawerHeaderButton>
  </div>
)
