import { createStore } from '../../lib/localStorage'
import { MMSI, createMMSI } from '../../Domain/Vessel'
import { fromNullable, fold } from 'fp-ts/es6/Option'
import { pipe, constant, flow } from 'fp-ts/es6/function'
import { BehaviorSubject } from 'rxjs'
import { scan, shareReplay } from 'rxjs/operators'
import { union } from 'fp-ts/es6/Array'
import { eqString } from 'fp-ts/lib/Eq'

export type State = {
  handPicked: MMSI[]
  favorites: MMSI[]
}

enum ActionType {
  INIT,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  ADD_HANDPICKED,
  REMOVE_HANDPICKED,
}

type Init = { type: ActionType.INIT; state: State }
type AddFavorite = { type: ActionType.ADD_FAVORITE; mmsi: MMSI }
type RemoveFavorite = { type: ActionType.REMOVE_FAVORITE; mmsi: MMSI }
type AddHandPicked = { type: ActionType.ADD_HANDPICKED; mmsi: number }
type RemoveHandPicked = { type: ActionType.REMOVE_HANDPICKED; mmsi: MMSI }

type Action = AddFavorite | RemoveFavorite | AddHandPicked | RemoveHandPicked | Init

const { getItem, setItem } = createStore('vesselStore')

const emptyState: State = { handPicked: [], favorites: [] }

const initialState = (): State => pipe(getItem(), fromNullable, fold(constant<State>(emptyState), JSON.parse))

export const vesselStateActionSubject = new BehaviorSubject<Action>({ type: ActionType.INIT, state: initialState() })

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.INIT: {
      return { ...action.state }
    }
    case ActionType.ADD_FAVORITE: {
      const favorites: MMSI[] = [action.mmsi]

      return { ...state, favorites: pipe(state.favorites, union<MMSI>(eqString)(favorites)) }
    }

    case ActionType.REMOVE_FAVORITE: {
      return { ...state, favorites: state.favorites.filter(mmsi => mmsi !== action.mmsi) }
    }

    case ActionType.ADD_HANDPICKED: {
      const mmsis: MMSI[] = [createMMSI(action.mmsi)]

      return {
        ...state,
        favorites: pipe(state.favorites, union<MMSI>(eqString)(mmsis)),
        handPicked: pipe(state.handPicked, union<MMSI>(eqString)(mmsis)),
      }
    }

    case ActionType.REMOVE_HANDPICKED: {
      return {
        ...state,
        favorites: state.favorites.filter(mmsi => mmsi !== action.mmsi),
        handPicked: state.handPicked.filter(mmsi => mmsi !== action.mmsi),
      }
    }
  }
}

const dispatch = vesselStateActionSubject.next.bind(vesselStateActionSubject)

export const vesselState = vesselStateActionSubject.pipe(scan<Action, State>(reducer, emptyState), shareReplay(1))

const addFavorite = (mmsi: MMSI): AddFavorite => ({ type: ActionType.ADD_FAVORITE, mmsi })
const removeFavorite = (mmsi: MMSI): RemoveFavorite => ({ type: ActionType.REMOVE_FAVORITE, mmsi })
const addHandPicked = (mmsi: number): AddHandPicked => ({
  type: ActionType.ADD_HANDPICKED,
  mmsi,
})
const removeHandPicked = (mmsi: MMSI): RemoveHandPicked => ({ type: ActionType.REMOVE_HANDPICKED, mmsi })

export const actions = {
  addFavorite: flow(addFavorite, dispatch),
  removeFavorite: flow(removeFavorite, dispatch),
  addHandPicked: flow(addHandPicked, dispatch),
  removeHandPicked: flow(removeHandPicked, dispatch),
}

export const isAddHandPickedAction = (action: Action): action is AddHandPicked =>
  action.type === ActionType.ADD_HANDPICKED

vesselState.subscribe(flow(JSON.stringify, setItem))
