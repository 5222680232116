import React from 'react'
import { createMap } from './Map/Mapbox'
import Header from './Header/Header'
import styles from './ShipTracker.module.scss'
import { useMapbox } from './Map/useMapbox'
import { Map as Mapbox } from 'mapbox-gl'
import { MapWithTraffic } from './Map/MapWithTraffic'
import { MapWithTrafficAndPortcalls } from './Map/MapWithTrafficAndPortcalls'
import { useAuth0 } from '@auth0/auth0-react'
import { matchPath, Redirect, useHistory, useLocation } from 'react-router-dom'
import { pipe, constUndefined } from 'fp-ts/es6/function'
import { fold, Option, fromNullable } from 'fp-ts/es6/Option'
import classnames from 'classnames'
import { useDarkMode } from './lib/hooks/useDarkMode'
import { getShowCasePort, Pages, SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from './constants'
import { PortalProvider } from './lib/hooks/usePortal'
import CookieBar from './UI/CookieBar/CookieBar'
import { useCookieSettings } from './UI/CookieBar/useCookieSettings'
import { ShowCase, AuthenticatedShowCase } from './Map/ShowCaseMap'
import { Port } from './Domain/Port'
import { VesselSearchResult } from './Domain/VesselSearchResult'
import { VesselDetailsOverlay } from './VesselDetailsOverlay/VesselDetailsOverlay'
import { Feature, useFeatureToggler } from './lib/hooks/useFeatureToggler'
import { isShowCase } from './config'
import { selectedVesselPath } from './Map/helpers/paths'
import { setSearched } from './Map/Traffic/vesselSearch'

enum MapKind {
  TRAFFIC,
  TRAFFIC_AND_PORTCALLS,
  SHOW_CASE,
}

type MapType =
  | { kind: MapKind.TRAFFIC }
  | { kind: MapKind.TRAFFIC_AND_PORTCALLS }
  | { kind: MapKind.SHOW_CASE; port: Port; isAuthenticated: boolean }

type ShipTrackerMapProps = {
  mapbox: Option<Mapbox>
  mapType: MapType
  selectedVessel: Option<string>
}

type TrafficMapProps = Omit<ShipTrackerMapProps, 'selectedVessel'>

function TrafficMap({ mapbox, mapType }: TrafficMapProps) {
  switch (mapType.kind) {
    case MapKind.TRAFFIC: {
      return <MapWithTraffic mapbox={mapbox} />
    }

    case MapKind.TRAFFIC_AND_PORTCALLS: {
      return <MapWithTrafficAndPortcalls mapbox={mapbox} />
    }

    case MapKind.SHOW_CASE: {
      return mapType.isAuthenticated ? (
        <AuthenticatedShowCase port={mapType.port} mapbox={mapbox} />
      ) : (
        <ShowCase port={mapType.port} mapbox={mapbox} />
      )
    }
  }
}

const ShipTrackerMap = ({ mapbox, mapType, selectedVessel }: ShipTrackerMapProps) => {
  const { isEnabled: hasPortcalls } = useFeatureToggler(Feature.PORTCALLS)
  if (isShowCase) {
    // Disable VesselDetailsOverlay for SHOW_CASE envs
    return <TrafficMap mapbox={mapbox} mapType={mapType} />
  }

  return pipe(
    selectedVessel,
    fold(
      () => <TrafficMap mapbox={mapbox} mapType={mapType} />,
      mmsi => (hasPortcalls ? <VesselDetailsOverlay mmsi={mmsi} /> : <Redirect to={selectedVesselPath(mmsi)} />)
    )
  )
}

const findMatch = (pathname: string) =>
  pipe(
    matchPath<{ mmsi?: string }>(pathname, { path: `${Pages.MAP}/vessels/:mmsi/details` })?.params.mmsi,
    fromNullable
  )

export default function Main() {
  const { isAuthenticated } = useAuth0()
  const { onMount, mapbox } = useMapbox(createMap)
  const { isDarkMode } = useDarkMode()
  const { areCookiesEnabled } = useCookieSettings()
  const { pathname } = useLocation()
  const selectedVessel = findMatch(pathname)
  const { isEnabled: hasPortcalls } = useFeatureToggler(Feature.PORTCALLS)
  const history = useHistory()

  return (
    <main className={classnames(styles.main, SHIP_TRACKER_THEME_CSS, { [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode })}>
      <PortalProvider id="shiptracker-portal">
        <Header
          handleSearchResult={pipe(
            selectedVessel,
            fold(
              () => ({ ship: { mmsi } }: VesselSearchResult, query: string) => {
                if (mmsi) {
                  setSearched({ mmsi, query })
                  history.push(selectedVesselPath(mmsi))
                }
              },
              constUndefined
            )
          )}
        />
        <div className={styles.map}>
          <div ref={onMount} className={styles.mapBox} />
          {pipe(
            getShowCasePort(),
            fromNullable,
            fold(
              () =>
                isAuthenticated && hasPortcalls ? (
                  <>
                    <ShipTrackerMap
                      selectedVessel={selectedVessel}
                      mapType={{ kind: MapKind.TRAFFIC_AND_PORTCALLS }}
                      mapbox={mapbox}
                    />
                    {!areCookiesEnabled && <CookieBar />}
                  </>
                ) : (
                  <>
                    <ShipTrackerMap
                      selectedVessel={selectedVessel}
                      mapType={{ kind: MapKind.TRAFFIC }}
                      mapbox={mapbox}
                    />
                    {!areCookiesEnabled && <CookieBar />}
                  </>
                ),
              port => (
                <>
                  <ShipTrackerMap
                    selectedVessel={selectedVessel}
                    mapType={{ kind: MapKind.SHOW_CASE, port, isAuthenticated }}
                    mapbox={mapbox}
                  />
                  {!areCookiesEnabled && <CookieBar />}
                </>
              )
            )
          )}
        </div>
      </PortalProvider>
    </main>
  )
}
