import React, { useEffect, useState, PropsWithChildren } from 'react'
import { Map as Mapbox } from 'mapbox-gl'
import { fold, Option, some, isSome, none } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { defer } from 'rxjs'
import { map } from 'rxjs/operators'
import { userLocation } from '../lib/userLocation'
import { InitializeMap } from './InitializeMap'
import { useTraffic, MapState } from './Traffic/useVesselTraffic'
import { MapboxProvider } from './Mapbox'
import { useMapDetailMarkers, MapMarkers } from './Marker/MapMarkers'
import { toast } from 'react-toastify'
import { FindingLocationToast, LocationTimeoutToast, DURATION } from '../UI/Toast'
import { constNull } from 'fp-ts/es6/function'
import { TrafficControls } from './Controls/Controls'
import { useResponsiveness } from '../lib/hooks/useResponsiveness'
import { DEFAULT_CENTER } from './constants'
import { NotificationCenter, SHIPTRACKER_API_NOTIFICATION } from './Controls/Notifications'
import { AccountDrawer } from '../Account/AccountDrawer/AccountDrawer'
import { SECOND } from '../constants'
import { LayersDropdown } from './Controls/LayersDropdown'
import { useMMSIMatcher } from './helpers/paths'
import { fetchVesselStatus } from '../Api/Vessel/fetchStatus'
import { createMMSI } from '../Domain/Vessel'

type Center = MapState['center']

type MapWithTrafficProps = { mapbox: Option<Mapbox> }

const TIMEOUT_PERIOD = 5000

function LocationTimeout({ children }: PropsWithChildren<{}>) {
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      toast.error(<LocationTimeoutToast />, { autoClose: DURATION.SHORT })

      setIsTimedOut(true)
    }, TIMEOUT_PERIOD)

    return () => clearTimeout(timeout)
  }, [])

  return isTimedOut ? <>{children}</> : null
}

let storedCenter: Center | undefined = undefined

export function MapWithTraffic({ mapbox }: MapWithTrafficProps) {
  const { mmsiMatch } = useMMSIMatcher()

  const [center, setCenter] = useState<Option<Center>>(none)

  useEffect(() => {
    if (isSome(center)) {
      return
    }

    if (isSome(mmsiMatch)) {
      // Do we have a specific vessel mmsi in our path,
      // let's try to load that and focus on it's location
      const subscription = defer(() => fetchVesselStatus(createMMSI(mmsiMatch.value))).subscribe(
        ({
          location: {
            coordinates: [x, y],
          },
        }) => {
          setCenter(some({ lng: x, lat: y }))
        }
      )

      return subscription.unsubscribe.bind(subscription)
    }

    // Did we get the user's location before,
    // than let's reuse that
    if (storedCenter) {
      return setCenter(some(storedCenter))
    }

    // Notify the user that we like to receive their location
    const toastId = toast.info(<FindingLocationToast />, { autoClose: false })

    // Use the location API to get the users' location
    const subscription = defer(userLocation)
      .pipe(
        map(({ coords: { latitude, longitude } }) => {
          // save location for later
          storedCenter = { lng: longitude, lat: latitude }

          return some(storedCenter)
        })
      )
      .subscribe(setCenter, () => {
        // Location is probably blocked
        const [lng, lat] = DEFAULT_CENTER

        setCenter(some({ lng, lat }))
      })

    return () => {
      toast.dismiss(toastId)
      subscription.unsubscribe()
    }
  }, [center, mmsiMatch])

  return (
    <>
      {pipe(
        mapbox,
        fold(constNull, mapboxValue => (
          <MapboxProvider mapbox={mapboxValue}>
            {pipe(
              center,
              fold(
                () => (
                  <LocationTimeout>
                    <InitializeMap center={DEFAULT_CENTER}>
                      <Content />
                    </InitializeMap>
                  </LocationTimeout>
                ),

                ({ lat, lng }) => (
                  <InitializeMap center={[lng, lat]}>
                    <Content />
                  </InitializeMap>
                )
              )
            )}
          </MapboxProvider>
        ))
      )}
    </>
  )
}

enum AccountDrawerState {
  PENDING,
  OPEN,
  CLOSED,
}

const ACCOUNT_DRAWER_DELAY = 20 * SECOND

function Content() {
  const { isMobile } = useResponsiveness()
  const { detailMarkerData } = useMapDetailMarkers()
  const { traffic, displayState } = useTraffic()
  const [drawerState, setDrawerState] = useState<AccountDrawerState>(AccountDrawerState.PENDING)

  useEffect(() => {
    const timeoutRef = setTimeout(() => setDrawerState(AccountDrawerState.OPEN), ACCOUNT_DRAWER_DELAY)

    return () => clearTimeout(timeoutRef)
  }, [])

  const closeDrawer = () => setDrawerState(AccountDrawerState.CLOSED)

  return (
    <>
      <MapMarkers
        traffic={traffic}
        portcalls={[]}
        handPicked={[]}
        detailMarkerData={detailMarkerData}
        displayState={displayState}
      />
      {!isMobile && (
        <>
          <LayersDropdown />
          {drawerState !== AccountDrawerState.OPEN && <TrafficControls />}
        </>
      )}
      {drawerState === AccountDrawerState.CLOSED && (
        <NotificationCenter notifications={[SHIPTRACKER_API_NOTIFICATION]} />
      )}
      {drawerState === AccountDrawerState.OPEN && <AccountDrawer onClose={closeDrawer} />}
    </>
  )
}
