import { constFalse, pipe, identity } from 'fp-ts/es6/function'
import { getOrElse } from 'fp-ts/es6/Option'
import { Price, currencySymbol } from '../Domain/Subscription/Plan'
import { fold } from 'fp-ts/es6/Either'

export const toSymbol = (currency: Price['currency']) =>
  pipe(
    currency,
    fold(identity, key => currencySymbol[key])
  )

export function formattedTotal(currency: Price['currency'], total?: number) {
  const symbol = toSymbol(currency)
  if (total === undefined) {
    return `${symbol} …,-`
  }

  if (total % 100 > 0) {
    return `${symbol} ${(total / 100.0).toString().replace('.', ',')}`
  }

  return `${symbol} ${total / 100.0},-`
}

export const formattedPrice = ({ currency, cents }: Price) => formattedTotal(currency, cents)

export const formattedCreditPrice = ({ currency, cents }: Price, credits: number) =>
  `${toSymbol(currency)} ${cents / (credits * 100)}`

export const creditCardValue = (last4: string) => `•••• •••••• ••${last4}`

export const isOpen = getOrElse(constFalse)
