const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined || value === '') {
    throw new Error(`Key ${name} is not defined in environment or defined as an empty string`)
  }

  return value
}

export const SHIPTRACKER_URL = getEnvironmentVariable('REACT_APP_SHIPTRACKER_URL')

export const DEPLOYMENT_ENVIRONMENT = getEnvironmentVariable('REACT_APP_DEPLOYMENT_ENVIRONMENT')

export const PRONTO_MAPBOX_TOKEN = getEnvironmentVariable('REACT_APP_PRONTO_MAPBOX_TOKEN')

export const BACKEND_URL = getEnvironmentVariable('REACT_APP_SHIPTRACKER_BACKEND_URL')

export const AIS_BACKEND_URL = getEnvironmentVariable('REACT_APP_AIS_BACKEND_URL')

export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_SESE_AUTH0_DOMAIN')

export const SHOW_CASE = process.env.REACT_APP_SHOW_CASE ? process.env.REACT_APP_SHOW_CASE : undefined

export const AUTH0_CLIENT_ID = getEnvironmentVariable(
  SHOW_CASE === undefined ? 'REACT_APP_SESE_AUTH0_CLIENT_ID' : 'REACT_APP_SHOW_CASE_AUTH0_CLIENT_ID'
)

export const STRIPE_PUBLIC_KEY = getEnvironmentVariable('REACT_APP_STRIPE_PUBLIC_KEY')

export const PREMIUM_PLAN_ID = getEnvironmentVariable('REACT_APP_PREMIUM_PLAN_ID')

export const API_100_PLAN_ID = getEnvironmentVariable('REACT_APP_API_100_PLAN_ID')

export const API_1000_PLAN_ID = getEnvironmentVariable('REACT_APP_API_1000_PLAN_ID')

export const API_5000_PLAN_ID = getEnvironmentVariable('REACT_APP_API_5000_PLAN_ID')

export const API_PLAN_ID = getEnvironmentVariable('REACT_APP_API_PLAN_ID')

export const ROLLBAR_TOKEN = getEnvironmentVariable('REACT_APP_ROLLBAR_TOKEN')

export const MIXPANEL_ID = SHOW_CASE === undefined ? getEnvironmentVariable('REACT_APP_MIXPANEL_TOKEN') : ''

export const MIXPANEL_API_HOST = getEnvironmentVariable('REACT_APP_MIXPANEL_API_HOST')

export const ADWORDS_ID = getEnvironmentVariable('REACT_APP_ADWORDS_ID')

export const isTestEnvironment = DEPLOYMENT_ENVIRONMENT === 'test'

export const isShowCase = SHOW_CASE !== undefined
