import ky from 'ky'
import { ApiPlan, isApiPlan } from '../../../Domain/Subscription/ApiPlan'
import { BACKEND_URL, PREMIUM_PLAN_ID } from '../../../config'
import { Plan, PlanJson, isKnownCurrency, PriceJson } from '../../../Domain/Subscription/Plan'
import { right, left } from 'fp-ts/es6/Either'
import { store } from '../../../dataStore'

export const fetchPlans = () => ky.get(`${BACKEND_URL}/api/plans`).json<PlanJson[]>()

const toKnownCurrency = (price: PriceJson) => ({
  ...price,
  currency: isKnownCurrency(price.currency) ? right(price.currency) : left(price.currency.toString()),
})

const toPlan = (plan: PlanJson): Plan => ({
  ...plan,
  prices: plan.prices.map(toKnownCurrency),
})

const toApiPlan = (plan: PlanJson): ApiPlan => ({
  ...plan,
  credits: plan.credits ?? 0,
  prices: plan.prices.map(toKnownCurrency),
})

const selectPaidPlans = (plans: PlanJson[]) =>
  plans
    .filter(p => !isApiPlan(p) && p.id === PREMIUM_PLAN_ID)
    .map(toPlan)
    .sort((a, b) => a.order - b.order)

export const fetchSubcriptionPlans = () => store.plans.fetch().then(selectPaidPlans)

export const fetchApiPlans = () => store.plans.fetch().then(plans => plans.filter(isApiPlan).map(toApiPlan))

export const fetchAllPlans = () =>
  store.plans.fetch().then(plans => ({
    web: selectPaidPlans(plans),
    api: plans.filter(isApiPlan).map(toApiPlan),
  }))
