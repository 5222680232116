import classnames from 'classnames'
import React, { useState, useCallback, useEffect } from 'react'
import MaterialIcon from '../../UI/MaterialIcon'
import styles from './Notifications.module.scss'
import { useTranslation } from '../../lib/i18n'
import { Pages, SECOND, SHIPTRACKER_GLOBAL_REDIRECT_URL } from '../../constants'
import { useResponsiveness } from '../../lib/hooks/useResponsiveness'
import { createSessionStore } from '../../lib/localStorage'
import { useMixpanelTrackExternalLink } from '../../lib/hooks/useTracking'

export const GLOBAL_SHIPTRACKER_NOTIFICATION = 'global-shiptracker'
export const SHIPTRACKER_API_NOTIFICATION = 'shiptracker-api'

const notificationsStore = createSessionStore('notifications-store')

type NotificationProps = {
  onClose: () => void
}
export const Notification: React.FC<NotificationProps> = ({ onClose, children }) => (
  <div className={styles.notification}>
    <MaterialIcon type="info" className={styles.icon} />
    <div className={styles.content}>{children}</div>
    <MaterialIcon type="close" className={classnames(styles.icon, styles.iconClose)} onClick={onClose} />
  </div>
)

export const NotificationsContainer: React.FC = ({ children }) => (
  <div className={styles.notificationsContainer}>{children}</div>
)

const NOTIFICATIONS = [GLOBAL_SHIPTRACKER_NOTIFICATION, SHIPTRACKER_API_NOTIFICATION] as const
type NotificationType = typeof NOTIFICATIONS[number]
type NotificationStore = { [key in NotificationType]?: boolean }

const initialState = (notifications: NotificationType[]) => {
  const state = JSON.parse(notificationsStore.getItem() || '{}')
  return NOTIFICATIONS.filter(n => notifications.includes(n)).reduce(
    (acc: NotificationStore, key: NotificationType) => {
      acc[key] = key in state ? state[key] : true
      return acc
    },
    {}
  )
}

type NotificationCenterProps = { notifications: NotificationType[] }

const NOTIFICATIONS_DELAY = 20 * SECOND
export function NotificationCenter({ notifications }: NotificationCenterProps) {
  const [state, setState] = useState(initialState(notifications))
  const [areNotificationsDisplayed, setAreNotificationsDisplayed] = useState(false)
  const { isMobile } = useResponsiveness()
  const hide = useCallback((key: NotificationType) => setState({ ...state, [key]: false }), [state])

  useEffect(() => {
    notificationsStore.setItem(JSON.stringify(state))
  }, [state])

  useEffect(() => {
    const timeoutRef = setTimeout(() => setAreNotificationsDisplayed(true), NOTIFICATIONS_DELAY)
    return () => clearTimeout(timeoutRef)
  }, [])

  if (isMobile || !areNotificationsDisplayed) {
    return null
  }

  return (
    <NotificationsContainer>
      {state[SHIPTRACKER_API_NOTIFICATION] && (
        <ShiptrackerApiNotification onClose={() => hide(SHIPTRACKER_API_NOTIFICATION)} />
      )}
      {state[GLOBAL_SHIPTRACKER_NOTIFICATION] && (
        <GlobalShipTrackerNotification onClose={() => hide(GLOBAL_SHIPTRACKER_NOTIFICATION)} />
      )}
    </NotificationsContainer>
  )
}

type SpecificNotificationProps = { onClose: () => void }

function GlobalShipTrackerNotification({ onClose }: SpecificNotificationProps) {
  const { t } = useTranslation()
  const linkRef = useMixpanelTrackExternalLink('ShipTracker Global')

  return (
    <Notification onClose={onClose}>
      <h5 className={styles.title}>{t('ShipTracker.Controls.GlobalShipTrackerTitle')}</h5>
      <p className={styles.text}>{t('ShipTracker.Controls.GlobalShipTrackerText')}</p>
      <a ref={linkRef} className={styles.cta} href={SHIPTRACKER_GLOBAL_REDIRECT_URL()}>
        {t('ShipTracker.Controls.GlobalShipTrackerCTA')}
      </a>
    </Notification>
  )
}

function ShiptrackerApiNotification({ onClose }: SpecificNotificationProps) {
  const { t } = useTranslation()
  const linkRef = useMixpanelTrackExternalLink('Click "Buy API credits"', { position: 'notification' })

  return (
    <Notification onClose={onClose}>
      <h5 className={styles.title}>{t('ShipTracker.Controls.ShiptrackerApiTitle')}</h5>
      <p className={styles.text}>{t('ShipTracker.Controls.ShiptrackerApiText')}</p>
      <a ref={linkRef} className={styles.cta} href={Pages.API_CREDITS_STORE}>
        {t('ShipTracker.Controls.ShiptrackerApiCTA')}
      </a>
    </Notification>
  )
}
