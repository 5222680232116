import React from 'react'

type AccountIconProps = { className?: string }
export const AccountIcon = ({ className }: AccountIconProps) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="00-login" transform="translate(-1326.000000, -15.000000)" fillRule="nonzero">
      <g id="01_Header" transform="translate(-23.000000, -13.000000)">
        <g id="Group-14" transform="translate(44.000000, 0.000000)">
          <g id="right" transform="translate(970.000000, 0.157585)">
            <g id="date02" transform="translate(233.000000, 0.000000)">
              <g id="iconfinder_8_3898372" transform="translate(102.000000, 26.842415)">
                <path
                  d="M13.4090909,10.6991145 C15.2147078,9.25029677 15.9090812,6.81951947 15.1412,4.63555894 C14.3733189,2.45159841 12.3104762,0.990259088 9.99545455,0.990259088 C7.68043293,0.990259088 5.61759023,2.45159841 4.84970907,4.63555894 C4.08182792,6.81951947 4.77620129,9.25029677 6.58181818,10.6991145 C2.63264089,12.1355353 0.0028377193,15.8877268 0,20.0900236 L0,20.9991145 L20,20.9991145 L20,20.0900236 C19.9957721,15.8853928 17.3618514,12.1325099 13.4090909,10.6991145 Z M6.36363636,6.45365992 C6.36363636,4.44535174 7.99169182,2.81729628 10,2.81729628 C12.0083082,2.81729628 13.6363636,4.44535174 13.6363636,6.45365992 C13.6363636,8.4619681 12.0083082,10.0900236 10,10.0900236 C7.99169182,10.0900236 6.36363636,8.4619681 6.36363636,6.45365992 Z M1.87272727,19.1809326 C2.33294329,15.037226 5.83536049,11.9022655 10.0045455,11.9022655 C14.1737304,11.9022655 17.6761476,15.037226 18.1363636,19.1809326 L1.87272727,19.1809326 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountHomeIconProps = { className?: string }
export const AccountHomeIcon = ({ className }: AccountHomeIconProps) => (
  <svg
    width="20px"
    height="17px"
    viewBox="0 0 20 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-40.000000, -151.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item01" transform="translate(0.000000, 130.000000)">
          <g id="home-24px" transform="translate(38.000000, 18.000000)">
            <polygon id="Path" points="10 20 10 14 14 14 14 20 19 20 19 12 22 12 12 3 2 12 5 12 5 20"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountDefaultPortIconProps = { className?: string }
export const AccountDefaultPortIcon = ({ className }: AccountDefaultPortIconProps) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-39.000000, -210.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item02-copy" transform="translate(0.000000, 191.000000)">
          <g id="my_location-24px-copy" transform="translate(38.000000, 18.000000)">
            <path
              d="M12,8 C9.79,8 8,9.79 8,12 C8,14.21 9.79,16 12,16 C14.21,16 16,14.21 16,12 C16,9.79 14.21,8 12,8 Z M20.94,11 C20.48,6.83 17.17,3.52 13,3.06 L13,1 L11,1 L11,3.06 C6.83,3.52 3.52,6.83 3.06,11 L1,11 L1,13 L3.06,13 C3.52,17.17 6.83,20.48 11,20.94 L11,23 L13,23 L13,20.94 C17.17,20.48 20.48,17.17 20.94,13 L23,13 L23,11 L20.94,11 Z M12,19 C8.13,19 5,15.87 5,12 C5,8.13 8.13,5 12,5 C15.87,5 19,8.13 19,12 C19,15.87 15.87,19 12,19 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountSubscriptionIconProps = { className?: string }
export const AccountSubscriptionIcon = ({ className }: AccountSubscriptionIconProps) => (
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-39.000000, -273.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item02" transform="translate(0.000000, 253.000000)">
          <g id="shopping_basket-24px" transform="translate(38.000000, 18.000000)">
            <path
              d="M17.21,9 L12.83,2.44 C12.64,2.16 12.32,2.02 12,2.02 C11.68,2.02 11.36,2.16 11.17,2.45 L6.79,9 L2,9 C1.45,9 1,9.45 1,10 C1,10.09 1.01,10.18 1.04,10.27 L3.58,19.54 C3.81,20.38 4.58,21 5.5,21 L18.5,21 C19.42,21 20.19,20.38 20.43,19.54 L22.97,10.27 L23,10 C23,9.45 22.55,9 22,9 L17.21,9 Z M9,9 L12,4.6 L15,9 L9,9 Z M12,17 C10.9,17 10,16.1 10,15 C10,13.9 10.9,13 12,13 C13.1,13 14,13.9 14,15 C14,16.1 13.1,17 12,17 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
type AccountUserProfileIconProps = { className?: string }
export const AccountUserProfileIcon = ({ className }: AccountUserProfileIconProps) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-41.000000, -335.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item03" transform="translate(0.000000, 314.000000)">
          <g id="create-24px" transform="translate(38.000000, 18.000000)">
            <path
              d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountApiKeyIconProps = { className?: string }

export const AccountApiKeyIcon = ({ className }: AccountApiKeyIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    className={className}
  >
    <path d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
  </svg>
)

type AccountPaymentDetailsIconProps = { className?: string }
export const AccountPaymentDetailsIcon = ({ className }: AccountPaymentDetailsIconProps) => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-40.000000, -397.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item04" transform="translate(0.000000, 375.000000)">
          <g id="credit_card-24px" transform="translate(38.000000, 18.000000)">
            <path
              d="M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M20,18 L4,18 L4,12 L20,12 L20,18 Z M20,8 L4,8 L4,6 L20,6 L20,8 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
type AccountInvoicesIconProps = { className?: string }
export const AccountInvoicesIcon = ({ className }: AccountInvoicesIconProps) => (
  <svg
    width="19px"
    height="22px"
    viewBox="0 0 19 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-40.000000, -455.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="item05" transform="translate(0.000000, 436.000000)">
          <g id="file_copy-24px" transform="translate(38.000000, 18.000000)">
            <path
              d="M16,1 L4,1 C2.9,1 2,1.9 2,3 L2,17 L4,17 L4,3 L16,3 L16,1 Z M15,5 L21,11 L21,21 C21,22.1 20.1,23 19,23 L7.99,23 C6.89,23 6,22.1 6,21 L6.01,7 C6.01,5.9 6.9,5 8,5 L15,5 Z M14,12 L19.5,12 L14,6.5 L14,12 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountStatusProps = { className?: string }
export const AccountStatus = ({ className }: AccountStatusProps) => (
  <svg
    width="29px"
    height="29px"
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <path
        d="M8.94171429,0.342857143 C8.75587918,0.211098368 8.52523824,0.158735983 8.30072707,0.197333891 C8.07621591,0.235931799 7.87630479,0.362314595 7.74514286,0.548571429 L4.05942857,5.75314286 L1.77942857,3.31885714 C1.45563191,2.97328469 0.913001029,2.95563191 0.567428571,3.27942857 C0.221856114,3.60322523 0.204203341,4.14585611 0.528,4.49142857 L4.24457143,8.46171429 L9.14742857,1.52228571 C9.40837497,1.13804752 9.31733181,0.61606675 8.94171429,0.342857143 L8.94171429,0.342857143 Z"
        id="path-1"
      ></path>
    </defs>
    <rect id="Mask-Copy" fill="#46BE41" x="15" y="0" width="14" height="14" rx="7"></rect>
    <g id="Group" transform="translate(16.000000, 1.000000)">
      <g id="DBS-Icons-Bold-21" transform="translate(1.371429, 1.714286)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Path" fill="#002E5C" xlinkHref="#path-1"></use>
        <g id="Group" mask="url(#mask-2)">
          <g transform="translate(-1.371429, -1.714286)" id="Kleur/cyaan">
            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="12" height="12"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

type AccountLogoutIconProps = { className?: string }
export const AccountLogoutIcon = ({ className }: AccountLogoutIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
      <path
        d="M10.09,15.59 L11.5,17 L16.5,12 L11.5,7 L10.09,8.41 L12.67,11 L3,11 L3,13 L12.67,13 L10.09,15.59 Z M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,9 L5,9 L5,5 L19,5 L19,19 L5,19 L5,15 L3,15 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z"
        id="Shape"
        fill="#0693CA"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

type AccountMapIconProps = { className?: string }
export const AccountMapIcon = ({ className }: AccountMapIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>map-24px</title>
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02a-Default-Porft" transform="translate(-38.000000, -902.000000)">
        <g id="item06" transform="translate(0.000000, 884.000000)">
          <g id="map-24px" transform="translate(38.000000, 18.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M20.5,3 L20.34,3.03 L15,5.1 L9,3 L3.36,4.9 C3.15,4.97 3,5.15 3,5.38 L3,20.5 C3,20.78 3.22,21 3.5,21 L3.66,20.97 L9,18.9 L15,21 L20.64,19.1 C20.85,19.03 21,18.85 21,18.62 L21,3.5 C21,3.22 20.78,3 20.5,3 Z M15,19 L9,16.89 L9,5 L15,7.11 L15,19 Z"
              id="Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
