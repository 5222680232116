import React from 'react'
import { PRIVACY_POLICY_URL } from '../constants'
import styles from './LegalTermsPage.module.scss'

export const ApiLegalTerms = () => (
  <div className={styles.terms}>
    <h2>1. API provider</h2>
    <p>
      This API is provided by PortXChange Products B.V., with its registered office at Stationsplein 45, 3013 AK
      Rotterdam and registered under KVK number 72868007 (“PXP”).
    </p>
    <h2>2. Contract</h2>
    <p>
      By using or interacting in any way with the PXP application programming interface or any other associated
      materials or mechanisms provided by PXP (including but not limited to submitting your electronic acceptance of
      these terms of use by checking the box prompting you to accept these terms of use on the relevant page) to enable
      you to access PXP data (collectively, the “API”), including, but not limited to requesting Credentials (as defined
      below) or making an API Request (as defined below), you acknowledge that you have read and understood this
      document and that you agree to be legally bound by the terms of use (“ API Terms”). If you agree to these API
      Terms on behalf of a company or other entity, you represent that you have the authority to bind that entity and
      its affiliates to these API Terms, in which case “you” or “your” shall refer to such entity and its affiliates. If
      you do not have authority or you do not agree with the Terms, you must not access, use or benefit from the API in
      any way. PXP reserves the right to at PXP’s full discretion to make changes to the terms of the API Terms from
      time to time. You understand and agree that if you use the API after the date on which the API Terms have changed,
      your continued use of the API is deemed acceptance of the updated API Terms. If a modification is unacceptable to
      you, you may terminate the API Terms by immediately ceasing use of the API..
    </p>
    <h2>3. The Agreement</h2>
    <p>
      These API Terms governs the interaction by you in any way with the API including, but not limited to making an API
      Request (as defined below) and any such interaction or other use of the API shall be subject to the API Terms.
    </p>
    <h2>4. Grant of Licence</h2>
    <p>
      Subject to your full compliance with all of the API Terms and any applicable policies made available or
      incorporated by reference, PXP grants you a non-exclusive, revocable, non-sublicensable, non-transferable licence
      to use and access the API to get access to ETA/ ATA data and vessel information (“PXP Data”), provided that PXP
      Data can be used for internal purposes only. You may not install or use the API for any other purpose without
      PXP’s prior written consent. You may not use the API for immoral or illegal purpose, nor for any other purpose
      which may be determined threatening, abusive or harmful. You may not: (A) access or use our API in violation of
      any applicable law or regulation; (B) access our API in any manner that (i) compromises, breaks or circumvents any
      of our technical processes or security measures including but not limited to the creation or transmission of any
      virus, worms, trojan horse, cancel-bot or any other destructive or contaminating program, (ii) poses a security
      vulnerability to customers or users of the API, (iii) tests the vulnerability of our systems or networks, (iv)
      reverse engineer or attempt to reverse engineer, (v) interfere with the API or try to interfere, (vi) try to
      access anything not authorized by PXP, (vii) extract source code or try to extract or (viii) create derivative
      works. Except as expressly authorised under the API Terms, you may not (i) copy, rent, lease, sell, transfer,
      assign, sublicense, disassemble, reverse engineer or decompile (except to the limited extent expressly authorised
      by applicable law), modify or alter any part of the API and PXP Data. If you use the API and PXP Data for any of
      the purposes set out here, PXP confirms that such use is outside of the scope of any licence granted here. For the
      purposes of the API Terms, an “API Request” means any communication from any application, website, service , or
      any server (collectively “Products”) used to route data to or from the Product, to the API. Your use of the API
      and the licence granted herein is subject always to any: limits on the number of permitted API Requests;
      additional restrictions on the use of the API; and/or any restriction or limit on the frequency and/or method of
      API Requests in a given period which may be set out, from time-to-time on the PXP website or separately notified
      to you in writing. You shall not use the API for any Product that replicates or attempts to replace the essential
      user experience of the API to create a Product which functions substantially the same as the API or utilise any
      PXP Data in any way that means you are effectively circumventing making an API Request or a third party making an
      API Request (including any potential API Requests).
    </p>
    <h2>5. Intellectual property rights</h2>
    <p>
      In consideration for the API, you shall pay the applicable fees, provided on the relevant section of the website
      where you can subscribe to the API the, as they become due including all applicable taxes. Your obligation to pay
      fees continues through the end of the subscription period during which you cancel your subscription. You may
      cancel your subscription to the API following the instructions in the relevant section of the website.
    </p>
    <h2>6. Intellectual Property</h2>
    <p>
      The API, any and all content, documentation, code, data and related materials made available through the API, any
      and all data and information collected and/or derived from the API and all intellectual property rights in, to,
      derived from and connected with all of the foregoing, including comments and suggestions received, are and shall
      at all times remain the sole and exclusive property of PXP and use thereof PXP will be at the full discretion of
      PXP. Except as expressly stated herein, the API Terms do not grant you any rights to, or in, patents, copyrights,
      database rights, trade names, trademarks (whether registered or unregistered and whether future or existing), or
      any other rights or licences in respect of the Intellectual Property, our website, the API and the material
      published on it. We are not aware of any reason why we cannot grant the rights we purport to grant under, or in
      accordance with the API Terms. You grant us a worldwide, non-exclusive, non-assignable and non-transferable
      licence to display your trade names, trade marks, service marks, logos and domain names to promote or advertise
      your use of the API. We may, without your consent, publicly refer to you, orally or in writing, as a licensee of
      the API. We may also publish your name and logo on PXP’s websites, in press releases, and in promotional materials
      without additional consent or notice to you.
    </p>
    <h2>7. Credentials</h2>
    <p>
      You agree to keep any username, password, API key or other credentials (the “Credentials”) utilised to access the
      API secret and confidential at all times and you shall only create one set of Credentials and ensure that no third
      party shall access the API utilising your Credentials. In the event that you suspect that any third party may be
      making use of your Credentials, you shall immediately inform PXP of this fact and provide all reasonable
      assistance to PXP in relation to any subsequent investigation or other activities undertaken by PXP as a result.
      In addition you must immediately redact and replace the API key in case this is compromised.
    </p>
    <h2>8. Data Privacy</h2>
    <p>
      When a call is made to our API via your application credentials, we will receive the following information:
      content of the API call, timestamp and the IP address from which the call was made. We shall process any Personal
      Data we receive through the API in line with the General Data Protection Regulation (EU) 2016/679 and later
      amendments and our Privacy Policy <a href={PRIVACY_POLICY_URL}>{PRIVACY_POLICY_URL}</a>
    </p>
    <h2>9. Exclusion of Warranties</h2>
    <p>
      The API is provided “as-is” without warranty of any kind. Except to the extent required by applicable law, PXP
      disclaims all warranties, whether express or implied, regarding the API, including without limitation any and all
      implied warranties of merchantability, accuracy, results of use, reliability, fitness for a particular purpose,
      title, interference with quiet enjoyment, and non-infringement of third-party rights. In addition, PXP disclaims
      any warranty that your use of the API will be uninterrupted or error free.
    </p>
    <h2>10. Limitation of Liability</h2>
    <p>
      Nothing within the API Terms shall act to limit or exclude PXP’ liability arising from fraud or fraudulent
      misrepresentation or death or personal injury caused by PXP’s gross negligence. PXP’s maximum liability (whether
      under tort or contract) to you or any third party for any loss or damage suffered by you as a result of or in
      connection with your use of (or inability to use or access) the API including loss of profits, loss of or
      corruption to data, computer failure or malfunction, interruption to business or any other special, indirect,
      incidental or consequential damages even if PXP has been advised of the possibility of such loss or damages and
      whether or not such loss or damages are foreseeable shall be the greater of Euro 1000 or the amount of API fees
      paid you in the twelve (12) month period immediately preceding the event giving rise to the claim (or the first
      claim where there is a series of claims attributable to the same cause).
    </p>
    <h2>11. Indemnity</h2>
    <p>
      You agree that your use of the API is solely under your control and at your direction, subject the compliance with
      the terms of the API Terms). You agree to hereby indemnify and hold harmless PXP and its employees, directors,
      officers and consultants from any and all claims, damages, liabilities, costs and fees (including reasonable legal
      fees) arising from: (i) your use of the API or from the use of the API by any third party making use of your
      Credentials; or (ii) the use of any of your Products by any third party or end user.
    </p>
    <h2>12. Term and Termination</h2>
    <p>
      The API Terms shall continue until terminated as set forth in this section. PXP may terminate the API Terms at any
      time. Any termination of the API Terms shall also terminate the licence granted hereunder. Upon termination of the
      API Terms for any reason, you shall immediately cease using or accessing the API and immediately delete any PXP
      Data from your Products and systems. Sections 5, 6, 7, 8 and 9 together with any other provisions required for the
      interpretation or enforcement of such sections shall survive termination of this Agreement. Should we choose to
      pause or restrict your use of the API at any time, this shall not affect the validity of these API Terms, which
      shall continue to apply until the API Terms are expressly terminated by us in accordance with this Clause 12.
    </p>
    <h2>13. General</h2>
    <p>
      This Agreement, and any disputes arising from or relating to the interpretation thereof (including non-contractual
      disputes), shall be governed by Dutch law and shall be subject to the exclusive jurisdiction of the competent
      court in Rotterdam. If any provision of the API Terms shall be found by any court or administrative body of
      competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability shall not affect the
      other provisions of the API Terms which shall remain in full force and effect. In no event will any delay, failure
      or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or
      remedy conferred by or arising under the API Terms or by law, be deemed to be or construed as a waiver of that or
      any other right, power, privilege, claim or remedy in respect of the circumstances in question, or operate so as
      to bar the enforcement of that, or any other right, power, privilege, claim or remedy, in any other instance at
      any time or times subsequently. You shall not, without the prior written consent of PXP, assign at law or in
      equity (including by way of a charge or declaration of trust), sub-license or deal in any other manner with the
      API Terms or any rights hereunder, or sub-contract any or all of its obligations under the API Terms, or purport
      to do any of the same. Any purported assignment in breach of this section shall confer no rights on the purported
      assignee.
    </p>
  </div>
)
