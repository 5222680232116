import { Switch, Route } from 'react-router-dom'
import { Pages } from './constants'
import { Helmet } from 'react-helmet'

const MetaHelmet = ({ title, content }: { title: string; content: string }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={content} />
  </Helmet>
)

export const Meta = () => (
  <Switch>
    <Route exact path={Pages.ABOUT}>
      <MetaHelmet
        title="Shiptracker | Global Ship Tracking | ETA predictions"
        content="Shiptracker provides reliable ETA predictions easily accessible through web subscription or API. Get a 14-day free trial for the web subscription or connect to the API for €4,95."
      />
    </Route>
    <Route exact path={Pages.AVAILABLE_PORTS}>
      <MetaHelmet
        title="Estimated Arrival Time Predictions To The Port | Shiptracker"
        content="Shiptracker provides you with reliable ETA predictions to the next destination port. Create your free account to get the list of vessels heading to the port."
      />
    </Route>
    <Route exact path={Pages.API_CREDITS_STORE}>
      <MetaHelmet
        title="ETA predictions Ship To The Destination Port API | Shiptracker"
        content="Get ETA predictions via API right into your own system. Generate the API key totally online within 5 min. Start for just €4,95."
      />
    </Route>
    <Route exact path={Pages.WEB_SUBSCRIPTIONS}>
      <MetaHelmet
        title="Interactive Map And Vessel Port Call List | Shiptracker"
        content="Global satellite and terrestrial AIS coverage. Real-time ship position. Extensive vessel information. Reported and ETA predictions. Start for free!"
      />
    </Route>
    <Route>
      <MetaHelmet
        title="Live Vessel Position And ETA Predictions | Shiptracker"
        content="Global live AIS vessel tracker with reliable Estimated Times of Arrival (ETA) predictions to the destination port. Get a 14-day free trial."
      />
    </Route>
  </Switch>
)
