import Fuse from 'fuse.js'
import { Port } from '../../Domain/Port'

export type SearchResult = Fuse.FuseResult<Port>

const MAXIMUM_RESULTS = 20

const INDEX_OPTIONS = { keys: ['port', 'name', 'aliases'] }

export function fuzzyPortSearch(ports: Port[]) {
  const newIndex = Fuse.createIndex(INDEX_OPTIONS.keys, ports)

  const index = new Fuse<Port>(ports, INDEX_OPTIONS, newIndex)

  return (term: string) =>
    new Promise<SearchResult[]>(resolve => {
      requestAnimationFrame(() => {
        const result = index.search(term)

        resolve(result.slice(0, MAXIMUM_RESULTS))
      })
    })
}
