import React, { useState } from 'react'
import { Auth0Error, AuthOptions, WebAuth } from 'auth0-js'
import createPolicy from 'password-sheriff'
import { Form, Formik, FormikHelpers } from 'formik'
import { object as YupObject, string as YupString } from 'yup'
import { useCallback } from 'react'
import { InputEmail } from './InputEmail'
import { InputPassword } from './InputPassword'
import styles from './AuthenticationForm.module.scss'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { Auth0ErrorComponent } from './Auth0ErrorComponent'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, BACKEND_URL } from '../../config'
import { Auth0ParseHashError } from 'auth0-js'
import { AUTH0_CONSENT } from '../../constants'
import { SuccessComponent } from './SuccessComponent'
import { useTranslation } from '../../lib/i18n'
import { WarningComponent } from './WarningComponent'
import { Browser, detect } from 'detect-browser'

const AUTH_SUPPORTED_BROWSERS: Browser[] = ['chrome', 'firefox', 'edge']

const isBrowserAuthSupported = () => {
  const browser = detect()
  return browser !== null && browser.type === 'browser' && AUTH_SUPPORTED_BROWSERS.includes(browser.name)
}

export enum AuthenticationFormState {
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
}

const PASSWORD_POLICY = createPolicy('good')

enum ConnectionType {
  USERNAME_PASSWORD = 'Username-Password-Authentication',
  GOOGLE_OAUTH = 'google-oauth2',
}

type Credentials = {
  email: string
  password: string
}

type FormTheme = { inputField?: string }

const signUpFormValidationSchema = YupObject<Credentials>().shape(
  {
    email: YupString().required('Required').email(),
    password: YupString()
      .required('Required')
      .test('password', "Password doesn't match criteria", password => !!password && PASSWORD_POLICY.check(password)),
  },
  []
)

const logInValidationSchema = YupObject<Credentials>().shape(
  {
    email: YupString().required('Required').email(),
    password: YupString().required('Required'),
  },
  []
)

const forgotPasswordValidationSchema = YupObject<Pick<Credentials, 'email'>>().shape(
  {
    email: YupString().required('Required').email(),
  },
  []
)

export const webAuthConfig: AuthOptions = {
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  responseType: 'token',
  audience: `${BACKEND_URL}/api/`,
  scope: AUTH0_CONSENT,
  maxAge: 0,
}

const legacyWebAuth0Client = new WebAuth(webAuthConfig)

const signUpWithCredentials = ({ email, password }: Credentials) =>
  new Promise<string>((resolve, reject) =>
    legacyWebAuth0Client.signup(
      {
        connection: ConnectionType.USERNAME_PASSWORD,
        email,
        password,
        scope: AUTH0_CONSENT,
      },
      error => {
        if (error) {
          return reject(error)
        }

        return resolve(email)
      }
    )
  )

const logInWithCredentials = ({
  email,
  password,
  redirectPage,
}: Credentials & { redirectPage: string; redirectState?: { [key: string]: unknown } }) =>
  new Promise<void>((resolve, reject) =>
    legacyWebAuth0Client.crossOriginAuthentication.login(
      {
        username: email,
        password,
        realm: ConnectionType.USERNAME_PASSWORD,
        audience: webAuthConfig.audience,
        redirectUri: `${window.location.origin}${redirectPage}`,
        scope: AUTH0_CONSENT,
      },
      error => {
        if (error) {
          return reject(error)
        }

        return resolve()
      }
    )
  )

const remindPasswordWithEmail = ({ email }: Pick<Credentials, 'email'>) =>
  new Promise<void>((resolve, reject) =>
    legacyWebAuth0Client.changePassword({ connection: ConnectionType.USERNAME_PASSWORD, email }, err => {
      if (err) {
        reject(err)
      }

      return resolve()
    })
  )

const authorizeWithGoogle = ({ redirectPage }: { redirectPage: string }) =>
  legacyWebAuth0Client.authorize({
    connection: ConnectionType.GOOGLE_OAUTH,
    scope: AUTH0_CONSENT,
    redirectUri: `${window.location.origin}${redirectPage}`,
    audience: webAuthConfig.audience,
  })

type SignUpFormProps = { onSignUp: (email: string) => void; theme?: FormTheme }
export const SignUpForm: React.FC<SignUpFormProps> = ({ onSignUp, theme }) => {
  const { t } = useTranslation()
  const [{ auth0Error, formValues }, setState] = useState<{
    formValues: Credentials
    auth0Error: Auth0Error | null
  }>({
    formValues: { email: '', password: '' },
    auth0Error: null,
  })

  const handleOnSubmit = useCallback(
    ({ email, password }: Credentials, { setSubmitting }: FormikHelpers<Credentials>) => {
      signUpWithCredentials({ email, password })
        .then(onSignUp)
        .catch((err: Auth0Error) => {
          setState(state => ({
            ...state,
            auth0Error: err,
          }))
          setSubmitting(false)
        })
    },
    [onSignUp]
  )

  return (
    <Formik initialValues={formValues} validationSchema={signUpFormValidationSchema} onSubmit={handleOnSubmit}>
      {({ dirty, isValid, isSubmitting, submitForm }) => (
        <Form className={styles.form}>
          {!isBrowserAuthSupported() && (
            <WarningComponent message={t('ShipTracker.CommonStep.Authentication.UnsupportedBrowserWarning')} />
          )}
          {auth0Error && (
            <Auth0ErrorComponent
              auth0Error={auth0Error}
              handleClose={() => setState({ formValues, auth0Error: null })}
            />
          )}
          <InputEmail name="email" label="E-mail" className={theme?.inputField} />
          <InputPassword
            name="password"
            label="Password"
            passwordPolicy={PASSWORD_POLICY}
            className={theme?.inputField}
          />
          <CtaButton
            className={styles.submitButton}
            isDisabled={!dirty || !isValid || isSubmitting}
            onClick={submitForm}
          >
            Sign up
          </CtaButton>
        </Form>
      )}
    </Formik>
  )
}

type SignInFormProps = {
  email?: string
  discardPasswordChange?: () => void
  redirectPage: string
  theme?: FormTheme
}

export const SignInForm: React.FC<SignInFormProps> = ({ email = '', redirectPage, discardPasswordChange, theme }) => {
  const { t } = useTranslation()
  const [{ auth0Error, formValues }, setState] = useState<{
    formValues: Credentials
    auth0Error: Auth0Error | null
  }>({
    formValues: { email, password: '' },
    auth0Error: null,
  })

  const handleOnSubmit = useCallback(
    ({ email, password }: Credentials, { setSubmitting }: FormikHelpers<Credentials>) => {
      logInWithCredentials({ email, password, redirectPage }).catch((err: Auth0Error) => {
        setState(state => ({
          ...state,
          auth0Error: err,
        }))
        setSubmitting(false)
      })
    },
    [redirectPage]
  )

  return (
    <Formik
      initialValues={formValues}
      initialErrors={auth0Error ? { password: auth0Error.description } : {}}
      initialTouched={auth0Error ? { password: true } : {}}
      validationSchema={logInValidationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ dirty, isValid, isSubmitting, submitForm }) => (
        <Form className={styles.form}>
          {!isBrowserAuthSupported() && (
            <WarningComponent message={t('ShipTracker.CommonStep.Authentication.UnsupportedBrowserWarning')} />
          )}
          {discardPasswordChange && (
            <SuccessComponent
              message={t('ShipTracker.CommonStep.Authentication.ResetPasswordConfirmation')}
              handleClose={discardPasswordChange}
            />
          )}
          {auth0Error && (
            <Auth0ErrorComponent
              auth0Error={auth0Error}
              handleClose={() => setState(state => ({ ...state, formValues, auth0Error: null }))}
            />
          )}
          <InputEmail name="email" label="E-mail" className={theme?.inputField} />
          <InputPassword name="password" label="Password" className={theme?.inputField} />
          <CtaButton
            className={styles.submitButton}
            isDisabled={!dirty || !isValid || isSubmitting}
            onClick={submitForm}
          >
            Log in
          </CtaButton>
        </Form>
      )}
    </Formik>
  )
}

type ForgotPasswordFormProps = { onPasswordChangeRequested: () => void; theme?: FormTheme }
export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onPasswordChangeRequested, theme }) => {
  const [{ auth0Error, formValues }, setState] = useState<{
    formValues: Pick<Credentials, 'email'>
    auth0Error: Auth0Error | null
  }>({
    formValues: { email: '' },
    auth0Error: null,
  })

  const handleOnSubmit = useCallback(
    ({ email }, { setSubmitting }: FormikHelpers<Pick<Credentials, 'email'>>) => {
      remindPasswordWithEmail({ email })
        .then(onPasswordChangeRequested)
        .catch((err: Auth0Error) => {
          setState(state => ({ ...state, auth0Error: err }))
          setSubmitting(false)
        })
    },
    [onPasswordChangeRequested]
  )

  return (
    <Formik initialValues={formValues} validationSchema={forgotPasswordValidationSchema} onSubmit={handleOnSubmit}>
      {({ dirty, isValid, isSubmitting, submitForm }) => (
        <Form className={styles.form}>
          {auth0Error && (
            <Auth0ErrorComponent
              auth0Error={auth0Error}
              handleClose={() => setState({ formValues, auth0Error: null })}
            />
          )}
          <InputEmail name="email" label="E-mail" className={theme?.inputField} />
          <CtaButton
            className={styles.submitButton}
            isDisabled={!dirty || !isValid || isSubmitting}
            onClick={submitForm}
          >
            Reset password
          </CtaButton>
        </Form>
      )}
    </Formik>
  )
}

type GoogleAuthorizationFormProps = { redirectPage: string }
export const GoogleAuthorizationForm = ({ redirectPage }: GoogleAuthorizationFormProps) => (
  <div className={styles.section}>
    <CtaButton className={styles.ssoButton} onClick={() => authorizeWithGoogle({ redirectPage })}>
      <span className={styles.icon} />
      Continue with Google
    </CtaButton>
  </div>
)

export const getAuth0ErrorDescription = (
  parsedError: Auth0ParseHashError | Auth0Error
): { type: string; email: string } | string => {
  const { errorDescription, error_description, description, error } = parsedError
  const foundDescription = errorDescription ?? error_description ?? description ?? error

  try {
    return JSON.parse(foundDescription)
  } catch (err) {
    return foundDescription
  }
}
