import {
  FreePlan,
  PaidPlan,
  Plan,
  Price,
  PlanType,
  Period,
  planFromId,
  isPaidPlan,
  planToID,
  TRIAL_PLAN_TYPE,
  defaultCurrency,
  Currency,
} from './Plan'
import { pipe, constant } from 'fp-ts/es6/function'
import { head } from 'fp-ts/es6/Array'
import { findFirstMap } from 'fp-ts/es6/Array'
import { none, some, Option, getOrElse, chain } from 'fp-ts/es6/Option'
import { getOrElse as eitherGetOrElse } from 'fp-ts/es6/Either'

type Status = 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid'

export type Subscription = {
  created: string
  currentPeriodEnd: string
  currentPeriodStart: string
  id: string
  priceId: string
  productId: string
  status: Status
}

enum ExpiryKind {
  Trail,
  Canceled,
}

type TrialExpiry = {
  kind: ExpiryKind.Trail
  date: Date
}

type CanceledExpiry = {
  kind: ExpiryKind.Canceled
  date: Date
}

export type Expiry = TrialExpiry | CanceledExpiry

const trialExpiry = (date: Date): TrialExpiry => ({ kind: ExpiryKind.Trail, date })

const canceledExpiry = (date: Date): CanceledExpiry => ({ kind: ExpiryKind.Canceled, date })

export const isTrialExpiry = (expiry: Expiry): expiry is TrialExpiry => expiry.kind === ExpiryKind.Trail

export type FreeSubscriptionPlan = { kind: FreePlan }

export type TrialSubscriptionPlan = { kind: PaidPlan; plan: Plan; price: Price; expiryDate: Date }

export type PaidSubscriptionPlan = { kind: PaidPlan; plan: Plan; price: Price; subscription: Subscription }

export type SubscriptionPlan = FreeSubscriptionPlan | TrialSubscriptionPlan | PaidSubscriptionPlan

// do we have at least one subscription
export const currentSubscription = pipe(head)

export const isPaidSubscriptionPlan = (plan: SubscriptionPlan): plan is PaidSubscriptionPlan =>
  'subscription' in plan && [PlanType.PREMIUM, PlanType.API_ONLY].includes(plan.kind)

export const isTrialSubscriptionPlan = (plan: SubscriptionPlan): plan is TrialSubscriptionPlan =>
  !('subscription' in plan) && [TRIAL_PLAN_TYPE].includes(plan.kind)

export const isCanceledSubscription = (subscription: Subscription) => 'canceledAt' in subscription

export const freeSubscriptionPlan: SubscriptionPlan = { kind: PlanType.FREE }

export function findTrialPlan(plans: Plan[], expiryDate: Date, period: Period = 'month') {
  const planId = planToID[PlanType.PREMIUM]

  return pipe(
    plans,
    findFirstMap((plan: Plan) =>
      plan.id === planId
        ? pipe(
            plan.prices,
            findFirstMap(
              (price): Option<SubscriptionPlan> =>
                price.period === period
                  ? some({
                      kind: PlanType.PREMIUM,
                      plan,
                      price,
                      expiryDate,
                    })
                  : none
            )
          )
        : none
    )
  )
}

export const findCurrentSubscriptionPlan = (plans: Plan[], subscription: Subscription) =>
  pipe(
    plans,
    findFirstMap((plan: Plan) =>
      pipe(
        plan.prices,
        findFirstMap(
          (price): Option<SubscriptionPlan> =>
            price.id !== subscription.priceId
              ? none
              : pipe(
                  plan.id,
                  planFromId,
                  chain(kind => (isPaidPlan(kind) ? some({ kind, subscription, plan, price }) : none))
                )
        )
      )
    )
  )

export const findCurrentSubscriptionPlanWithDefault = (
  plans: Plan[],
  subscription: Subscription,
  defaultSubscription: SubscriptionPlan
) => pipe(findCurrentSubscriptionPlan(plans, subscription), getOrElse(constant(defaultSubscription)))

export function expiryDetails(subscriptionPlan: SubscriptionPlan): Option<Expiry> {
  if (isTrialSubscriptionPlan(subscriptionPlan)) {
    return some(trialExpiry(new Date(subscriptionPlan.expiryDate)))
  }

  if (isPaidSubscriptionPlan(subscriptionPlan) && isCanceledSubscription(subscriptionPlan.subscription)) {
    return some(canceledExpiry(new Date(subscriptionPlan.subscription.currentPeriodEnd)))
  }

  return none
}

export const currentSubscriptionPlanCurrency = (subscriptionPlan: SubscriptionPlan) =>
  pipe(
    isPaidSubscriptionPlan(subscriptionPlan) ? subscriptionPlan.price.currency : defaultCurrency,
    eitherGetOrElse(() => Currency.USD)
  )
