import classnames from 'classnames'
import { RadioButtonWithLabel } from '../../UI/RadioButton/RadioButton'
import { Currency, currencySymbol } from '../../Domain/Subscription/Plan'
import styles from './SelectCurrency.module.scss'
import { useTranslation } from '../../lib/i18n'
import { pipe } from 'fp-ts/es6/function'
import { fold, Option } from 'fp-ts/es6/Option'

type CurrencyProps = {
  className?: string
  currency: Currency
}

type SelectCurrencyProps = CurrencyProps & {
  handleSelectCurrency: (c: Currency) => void
}

export function SelectCurrency({ currency, handleSelectCurrency }: SelectCurrencyProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.selectCurrency}>
      <RadioButtonWithLabel
        handleChange={() => handleSelectCurrency(Currency.USD)}
        checked={currency === Currency.USD}
        label={t('ShipTracker.Account.Subscription.Currency.Dollar', currencySymbol[Currency.USD])}
      />
      <RadioButtonWithLabel
        handleChange={() => handleSelectCurrency(Currency.EUR)}
        checked={currency === Currency.EUR}
        label={t('ShipTracker.Account.Subscription.Currency.Euro', currencySymbol[Currency.EUR])}
      />
    </div>
  )
}

export function RestrictedCurrency({ className, currency }: CurrencyProps) {
  const { t } = useTranslation()

  return (
    <div className={classnames(styles.selectCurrency, className)}>
      <RadioButtonWithLabel
        checked
        label={t(
          `ShipTracker.Account.Subscription.Currency.${currency === 'usd' ? 'Dollar' : 'Euro'}`,
          currencySymbol[currency]
        )}
      />
    </div>
  )
}

export function RestrictedCurrencyMessage({ currency }: { currency: Currency }) {
  const { t } = useTranslation()

  return <>{t(`ShipTracker.Account.Subscription.RestrictedCurrency.${currency.toUpperCase()}`)}</>
}

export const DisplayCurrencyOptions: React.FC<SelectCurrencyProps & { restrictedCurrency: Option<Currency> }> = ({
  restrictedCurrency,
  currency,
  handleSelectCurrency,
  className,
}) =>
  pipe(
    restrictedCurrency,
    fold(
      () => <SelectCurrency currency={currency} handleSelectCurrency={handleSelectCurrency} />,
      currency => (
        <>
          <div className={className}>
            <RestrictedCurrencyMessage currency={currency} />
          </div>
          <RestrictedCurrency currency={currency} />
        </>
      )
    )
  )
